import { Stack, SxProps, useTheme } from '@mui/material';
import { FC, MouseEventHandler, ReactNode } from 'react';
import { DropzoneState } from 'react-dropzone';

interface UploadFileProps {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  getInputProps: DropzoneState['getInputProps'];
  isDragActive: DropzoneState['isDragActive'];
  sx?: SxProps;
}

const UploadFiles: FC<UploadFileProps> = ({ children, onClick, getInputProps, isDragActive, sx }) => {
  const { shape, palette } = useTheme();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        cursor: 'pointer',
        backgroundColor: palette.background.default,
        border: `1px dashed ${isDragActive ? palette.darkPurple.dark : palette.darkPurple.light}`,
        boxShadow: `0 24px 24px 0 ${palette.text.secondary}0c`,
        zIndex: 1000,
        mb: 3.75,
        borderRadius: shape.borderRadius,
        ...sx,
      }}
      onClick={onClick}
    >
      <input {...getInputProps()} />
      {children}
    </Stack>
  );
};

export default UploadFiles;

import Header from '@/components/Header/Header';
import { Outlet } from 'react-router-dom';
import { FC, ReactNode, Suspense } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { usePageTitle } from '@/hooks/usePageTitle';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import useHeaderHeight from '@/hooks/useHeaderHeight';
import Loader from '@/components/Loader/Loader';
import OpenKnowledge from '@/views/Knowledge/OpenKnowledge';
import { KnowledgeProvider } from '@/views/Knowledge/useKnowledge';
import { ProjectFormDialogProvider } from '@/providers/ProjectFormDialogProvider';
import EmptyDocumentProjectProvider from '@/views/Project/providers/EmptyDocumentProjectProvider';

interface RootProps {
  routeHeader?: ReactNode;
}

const Root: FC<RootProps> = ({ routeHeader }) => {
  const { isLoading } = useAuth();
  const { pageTitle } = usePageTitle();
  const headerHeight = useHeaderHeight();

  return (
    <EmptyDocumentProjectProvider>
      <ProjectFormDialogProvider>
        <Box sx={{ minHeight: '100vh' }}>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <Header routeHeader={routeHeader} />
          <Box component="main" sx={{ height: `calc(100vh - ${headerHeight}px)`, zIndex: 100, position: 'relative' }}>
            <Suspense fallback={<Loader id="root-Suspense" />}>{isLoading ? <Loader id="root" /> : <Outlet />}</Suspense>
          </Box>
          <KnowledgeProvider>
            <OpenKnowledge />
          </KnowledgeProvider>
        </Box>
      </ProjectFormDialogProvider>
    </EmptyDocumentProjectProvider>
  );
};

export default Root;

import { AnnotationBackendJSON } from 'pspdfkit';
import { AnnotationsAndEquipmentResponse, EquipmentPiece } from '@/api/generated';
import { createContext } from 'react';

interface AnnotationsContextValue {
  annotations: AnnotationBackendJSON[];
  documentAnnotations: AnnotationBackendJSON[];
  equipmentList: EquipmentPiece[];
  isLoading: boolean;
  isAnnotationsFailedToLoad: boolean;
  refetchDocumentData: () => void;
  onUpdateDocumentData: (newDocumentData: Partial<AnnotationsAndEquipmentResponse>) => void;
  hideAnnotations: (annotationIdOrIds: string | Array<string>) => void;
  showAnnotations: (annotationIdOrIds: string | Array<string>) => void;
  hiddenAnnotationsIds: string[];
  highlightAnnotations: (ids: string[]) => void;
  highlightedAnnotationsIds: string[];
}

export const AnnotationsContext = createContext<AnnotationsContextValue | null>(null);

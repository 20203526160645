import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import { ProjectFormValue } from '@/views/Projects/components/ProjectFormDialog/types';
import { validationSchema } from '@/views/Projects/components/ProjectFormDialog/validationScheme';
import {
  getGetProjectsIdQueryKey,
  ProjectFull,
  useEditProjectHook,
  useGetProjectsIdHook,
  usePostProjectsHook,
} from '@/api/generated';
import { toProjectDetails } from '@/services/linker';
import { useEmptyDocumentProject } from '@/views/Project/hooks/useEmptyDocumentProject';

type Params = {
  initialProject?: ProjectFull;
  onAfterSave: (project: ProjectFull) => void;
};

export const useProjectForm = ({ initialProject, onAfterSave }: Params) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setForceDropZoneView } = useEmptyDocumentProject();

  const createProject = usePostProjectsHook();
  const updateProject = useEditProjectHook();
  const getProject = useGetProjectsIdHook();

  const [isSaving, setIsSaving] = useState(false);

  const form = useForm<ProjectFormValue>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: { stakeholders: [{ name: '', role: '', company: '', email: '', phone: '' }] },
  });
  const { reset, handleSubmit, getValues } = form;

  const resetFormValues = (project?: ProjectFull) => {
    reset({
      slug: project?.slug,
      name: project?.name ?? '',
      address: project?.address ?? '',
      location: project?.location ?? undefined,
      delivery_method: project?.delivery_method,
      project_size: project?.project_size,
      owner: project?.owner ?? '',
      type: project?.type ?? '',
      stakeholders: project?.stakeholders ?? [],
    });
  };

  const onSave = async (formData: ProjectFormValue) => {
    setIsSaving(true);
    try {
      const slug = getValues('slug');
      const nextProject = slug
        ? await updateProject(slug, {
            ...formData,
            name: formData.name === initialProject?.name ? undefined : formData.name,
          })
        : await createProject(formData);

      await queryClient.prefetchQuery({
        queryKey: getGetProjectsIdQueryKey(nextProject.slug),
        queryFn: () => getProject(nextProject.slug),
      });

      setForceDropZoneView(false);
      onAfterSave(nextProject);

      if (initialProject?.slug !== nextProject.slug) {
        navigate(toProjectDetails({ projectSlug: nextProject.slug }));
      }
    } catch (error) {
      console.error('Error while saving project', error);
    }
    setIsSaving(false);
  };

  return {
    form,
    isSaving,
    resetFormValues,
    onSubmit: handleSubmit(onSave),
  };
};

import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { User } from '@/api/generated';
import UserAvatar from '@/components/Avatar/UserAvatar';
import { ListDeleteButton } from '@/components/ListDeleteButton';

interface UserItemProps {
  user: User;
  onUnshare: () => void;
}

export const UserItem: FC<UserItemProps> = ({ user, onUnshare }) => {
  const { t } = useTranslation('share');

  return (
    <Stack direction="row" gap={2} sx={{ mt: 2 }} key={user._id} justifyContent="space-between" alignItems="center">
      <Stack direction="row" gap={2}>
        <UserAvatar user={user} />
        <Stack>
          <Typography variant="body1">{t('userFullName', { firstName: user.firstName, lastName: user.lastName })}</Typography>
          <Typography variant="body1">{user.email}</Typography>
        </Stack>
      </Stack>
      <ListDeleteButton onClick={onUnshare} />
    </Stack>
  );
};

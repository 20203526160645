import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { useAuth } from '@/hooks/useAuth';
import {
  ChatWithKnowledgeBaseNewQuestionParams,
  DocumentMetadata,
  KnowledgeBase,
  Thread,
  ThreadMetadata,
  useChatWithKnowledgeBaseNewQuestion,
  useDeleteDocumentFromKnowledgeBase,
  useDeleteThread,
  useGetAllThreads,
  useGetOrganizationKnowledgeBase,
  useGetThreadById,
  User,
  UserOrganizationAnyOfItem,
  useUploadDocToKnowledgeBase,
} from '@/api/generated';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

interface KnowledgeContext {
  orgId: UserOrganizationAnyOfItem;
  createQuestion: (
    question: ChatWithKnowledgeBaseNewQuestionParams['question'],
    thread_id?: ChatWithKnowledgeBaseNewQuestionParams['thread_id'],
    use_web?: ChatWithKnowledgeBaseNewQuestionParams['use_web'],
  ) => Promise<Thread>;
  threads?: ThreadMetadata[];
  currentUser?: User;
  uploadFile: (file: File) => Promise<DocumentMetadata>;
  isUploadFilesFetching: boolean;
  threadsRefetch: () => Promise<unknown>;
  setCurrentThreadId: (currentThreadId: string) => void;
  currentThread?: Thread;
  currentThreadId?: string;
  isThreadLoading: boolean;
  isQuestionPending: boolean;
  isLoadingUpload: boolean;
  deleteThread: (threadId: string) => Promise<ThreadMetadata[]>;
  isDeleteThreadPending: boolean;
  uploadedFiles?: KnowledgeBase;
  deleteDocument: (documentId: string) => Promise<unknown>;
  isModified: boolean;
  setIsModified: (value: boolean) => void;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
}
interface KnowledgeProviderProps {
  children: ReactNode;
}

const Context = createContext<KnowledgeContext | null>(null);

const useKnowledge = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useKnowledge must be used within a KnowledgeProvider');
  }
  return context;
};

export const KnowledgeProvider: FC<KnowledgeProviderProps> = ({ children }) => {
  const { currentUser } = useAuth();
  const { t } = useTranslation('common');
  const orgId = currentUser?.organization?.[0] || '';
  const [currentThreadId, setCurrentThreadId] = useState('');
  const [isLoadingUpload, setLoadingUpload] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const {
    data: uploadedFiles,
    isFetching: isUploadFilesFetching,
    refetch: uploadedFilesRefetch,
  } = useGetOrganizationKnowledgeBase(orgId as string, {
    query: { enabled: isActive },
  });
  const { data: threads, refetch: threadsRefetch } = useGetAllThreads(orgId as string, {
    query: { enabled: isActive },
  });

  const {
    data: currentThread,
    isLoading: isThreadLoading,
    refetch: currentThreadRefetch,
  } = useGetThreadById(orgId as string, currentThreadId);
  const { mutateAsync: createQuestionMutation, isPending: isQuestionPending } = useChatWithKnowledgeBaseNewQuestion();
  const { mutateAsync: uploadFileMutation } = useUploadDocToKnowledgeBase({ mutation: { retry: true, retryDelay: 500 } });
  const { mutateAsync: deleteDocumentMutation } = useDeleteDocumentFromKnowledgeBase();
  const { mutateAsync: deleteThreadMutation, isPending: isDeleteThreadPending } = useDeleteThread();

  const createQuestion = (
    question: ChatWithKnowledgeBaseNewQuestionParams['question'],
    thread_id?: ChatWithKnowledgeBaseNewQuestionParams['thread_id'],
    use_web?: ChatWithKnowledgeBaseNewQuestionParams['use_web'],
  ): Promise<Thread> =>
    createQuestionMutation(
      { orgId: orgId as string, params: { question, thread_id, use_web } },
      {
        onSuccess: async () => {
          await currentThreadRefetch();
          await threadsRefetch();
        },
      },
    );

  const deleteThread = (threadId: string) =>
    deleteThreadMutation(
      { orgId: orgId as string, threadId },
      { onSuccess: () => threadsRefetch(), onError: err => console.error(err) },
    );

  const deleteDocument = (documentId: string) =>
    deleteDocumentMutation(
      { orgId: orgId as string, documentId },
      {
        onSuccess: () => {
          uploadedFilesRefetch();
          enqueueSnackbar(t('knowledge.deleteSucceed'));
        },
        onError: () => {
          enqueueSnackbar(t('knowledge.deleteFailed'), { variant: 'error' });
        },
      },
    );

  const uploadFile = (file: File) => {
    setLoadingUpload(true);
    return uploadFileMutation(
      {
        orgId: orgId as string,
        data: {
          file,
        },
      },
      {
        onSuccess: () => {
          setLoadingUpload(false);
          uploadedFilesRefetch();
          enqueueSnackbar(t('knowledge.uploadSucceed'));
        },
        onError: () => {
          setLoadingUpload(false);
          enqueueSnackbar(t('knowledge.uploadFailed'), { variant: 'error' });
        },
      },
    );
  };

  return (
    <Context.Provider
      value={{
        orgId,
        currentUser,
        createQuestion,
        threads,
        uploadFile,
        threadsRefetch,
        setCurrentThreadId,
        currentThread,
        currentThreadId,
        isThreadLoading,
        isQuestionPending,
        isLoadingUpload,
        deleteThread,
        isDeleteThreadPending,
        uploadedFiles,
        isUploadFilesFetching,
        deleteDocument,
        isModified,
        setIsModified,
        setIsActive,
        isActive,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default useKnowledge;

import { QueryClient } from '@tanstack/react-query';

import { getGetProjectsQueryKey, ProjectFull } from '@/api/generated';

type ProjectsData = { projects: ProjectFull[] };
type ProjectsWithPagesData = { pages: ProjectsData[] };

export function updateProjectInProjectsQuery(queryClient: QueryClient, projectToUpdate: ProjectFull) {
  const caches = queryClient.getQueryCache().findAll({ queryKey: getGetProjectsQueryKey() });

  caches.forEach(cache => {
    cache.setData((cacheData: ProjectsWithPagesData) => {
      const indexes = cacheData.pages.reduce<[number, number][]>((acc, page, pageIndex) => {
        const projectIndex = page.projects.findIndex(project => project.slug === projectToUpdate.slug);
        return projectIndex !== -1 ? [...acc, [pageIndex, projectIndex]] : acc;
      }, []);

      if (indexes.length === 0) {
        return cacheData;
      }

      const updatedProjectsPages = [...cacheData.pages];
      indexes.forEach(([pageIndex, projectIndex]) => {
        updatedProjectsPages[pageIndex].projects = [...updatedProjectsPages[pageIndex].projects];
        updatedProjectsPages[pageIndex].projects[projectIndex] = projectToUpdate;
      });

      return { ...cacheData, pages: updatedProjectsPages };
    });
  });
}

import { Outlet } from 'react-router-dom';
import Header from '@/views/Project/views/Header/Header';
import { Box } from '@mui/material';
import useHeaderHeight from '@/hooks/useHeaderHeight';

const Project = () => {
  const headerHeight = useHeaderHeight();

  return (
    <Box
      sx={{
        position: 'sticky',
        top: `${headerHeight}px`,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Outlet context={{ sx: { flex: '1 1 auto' } }} />
    </Box>
  );
};

export default Project;

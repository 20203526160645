import DOMPurify from 'dompurify';
import { marked } from 'marked';

marked.use({
  renderer: {
    listitem({ text, task, checked }) {
      return task
        ? `<li style="list-style-type: none; margin-left: -20px;"><input type="checkbox" disabled ${
            checked ? 'checked' : ''
          }/> ${text}</li>\n`
        : false;
    },
  },
});

export const convertMarkdownToHtmlSafely = (markdown: string) =>
  DOMPurify.sanitize(marked(markdown) as string, { USE_PROFILES: { html: true } });

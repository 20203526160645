import { FC } from 'react';
import { SvgIcon, SvgIconOwnProps } from '@mui/material';
import AiIcon from '@/components/Icon/svgs/ai.svg?react';
import ArrowDownIcon from '@/components/Icon/svgs/arrow-down.svg?react';
import BulletListIcon from '@/components/Icon/svgs/bullet-list.svg?react';
import CodeIcon from '@/components/Icon/svgs/code.svg?react';
import SmallChecklistIcon from '@/components/Icon/svgs/small-checklist.svg?react';
import H1Icon from '@/components/Icon/svgs/H1.svg?react';
import H2Icon from '@/components/Icon/svgs/H2.svg?react';
import H3Icon from '@/components/Icon/svgs/H3.svg?react';
import NumberedListIcon from '@/components/Icon/svgs/numbered-list.svg?react';
import QuoteIcon from '@/components/Icon/svgs/quote.svg?react';
import TextIcon from '@/components/Icon/svgs/text.svg?react';
import BinIcon from '@/components/Icon/svgs/bin.svg?react';
import CheckmarkIcon from '@/components/Icon/svgs/checkmark.svg?react';
import CheckboxVIcon from '@/components/Icon/svgs/checkboxV.svg?react';
import ShowIcon from '@/components/Icon/svgs/show.svg?react';
import HideIcon from '@/components/Icon/svgs/hide.svg?react';
import PlusIcon from '@/components/Icon/svgs/plus.svg?react';
import CardsIcon from '@/components/Icon/svgs/cards.svg?react';
import DownloadIcon from '@/components/Icon/svgs/download.svg?react';
import EmailIcon from '@/components/Icon/svgs/email.svg?react';
import PrivateIcon from '@/components/Icon/svgs/private.svg?react';
import PublicIcon from '@/components/Icon/svgs/public.svg?react';
import AfterParagraphIcon from '@/components/Icon/svgs/after-paragraph.svg?react';
import TextJustifyIcon from '@/components/Icon/svgs/justify.svg?react';
import TextLeftIcon from '@/components/Icon/svgs/text-left.svg?react';
import TextRightIcon from '@/components/Icon/svgs/text-right.svg?react';
import TextCenterIcon from '@/components/Icon/svgs/text-center.svg?react';
import CollapseIcon from '@/components/Icon/svgs/collapse.svg?react';
import TableIcon from '@/components/Icon/svgs/table.svg?react';
import DividerIcon from '@/components/Icon/svgs/divider.svg?react';
import PageBreakIcon from '@/components/Icon/svgs/page-break.svg?react';
import ExcalidrawIcon from '@/components/Icon/svgs/excalidraw.svg?react';
import ColumnsLayoutIcon from '@/components/Icon/svgs/column-layout.svg?react';
import YoutubeIcon from '@/components/Icon/svgs/youtube.svg?react';
import TwitterIcon from '@/components/Icon/svgs/tweet.svg?react';
import ChatIcon from '@/components/Icon/svgs/chat.svg?react';
import HistoryIcon from '@/components/Icon/svgs/history.svg?react';
import FileIcon from '@/components/Icon/svgs/file.svg?react';
import GlobeIcon from '@/components/Icon/svgs/globe.svg?react';
import CheckIcon from '@/components/Icon/svgs/check.svg?react';
import XIcon from '@/components/Icon/svgs/x-thin.svg?react';
import SendIcon from '@/components/Icon/svgs/send.svg?react';
import SearchIcon from '@/components/Icon/svgs/search-icon.svg?react';
import AttachmentIcon from '@/components/Icon/svgs/attachment.svg?react';
import TagIcon from '@/components/Icon/svgs/tag.svg?react';
import PolygonIcon from '@/components/Icon/svgs/polygon.svg?react';
import LineIcon from '@/components/Icon/svgs/line.svg?react';
import UploadIcon from '@/components/Icon/svgs/upload-icon.svg?react';
import NewThreadIcon from '@/components/Icon/svgs/newThread.svg?react';
import SaveIcon from '@/components/Icon/svgs/save.svg?react';
import ExportToPageIcon from '@/components/Icon/svgs/export-to-page.svg?react';
import ExpandIcon from '@/components/Icon/svgs/expand.svg?react';
import ArrowsInIcon from '@/components/Icon/svgs/arrows-in.svg?react';
import MinimizeIcon from '@/components/Icon/svgs/minimize.svg?react';
import AddToFavoriteIcon from '@/components/Icon/svgs/add-to-favorites.svg?react';
import UnstarIcon from '@/components/Icon/svgs/unstar.svg?react';
import EditIcon from '@/components/Icon/svgs/edit.svg?react';
import SortAscendingIcon from '@/components/Icon/svgs/sort-ascending.svg?react';
import SortDescendingIcon from '@/components/Icon/svgs/sort-descending.svg?react';
import SortNoneIcon from '@/components/Icon/svgs/sort-none.svg?react';
import NewFolderIcon from '@/components/Icon/svgs/new-folder.svg?react';
import FolderIcon from '@/components/Icon/svgs/folder.svg?react';
import FolderOpenIcon from '@/components/Icon/svgs/folder-open.svg?react';

export interface IconProps extends Omit<SvgIconOwnProps, 'component'> {
  name: IconNames;
}

export type IconNames = keyof typeof icons;

const icons = {
  ai: AiIcon,
  arrowDown: ArrowDownIcon,
  bulletList: BulletListIcon,
  code: CodeIcon,
  smallChecklist: SmallChecklistIcon,
  h1: H1Icon,
  h2: H2Icon,
  h3: H3Icon,
  numberedList: NumberedListIcon,
  quote: QuoteIcon,
  text: TextIcon,
  bin: BinIcon,
  checkmark: CheckmarkIcon,
  show: ShowIcon,
  hide: HideIcon,
  plus: PlusIcon,
  cards: CardsIcon,
  download: DownloadIcon,
  email: EmailIcon,
  private: PrivateIcon,
  public: PublicIcon,
  afterParagraph: AfterParagraphIcon,
  justify: TextJustifyIcon,
  left: TextLeftIcon,
  right: TextRightIcon,
  center: TextCenterIcon,
  collapse: CollapseIcon,
  table: TableIcon,
  divider: DividerIcon,
  pageBreak: PageBreakIcon,
  excalidraw: ExcalidrawIcon,
  columnsLayout: ColumnsLayoutIcon,
  youtube: YoutubeIcon,
  twitter: TwitterIcon,
  chat: ChatIcon,
  history: HistoryIcon,
  file: FileIcon,
  globe: GlobeIcon,
  check: CheckIcon,
  x: XIcon,
  send: SendIcon,
  search: SearchIcon,
  attachment: AttachmentIcon,
  tag: TagIcon,
  polygon: PolygonIcon,
  line: LineIcon,
  uploadFile: UploadIcon,
  checked: CheckboxVIcon,
  newThread: NewThreadIcon,
  exportToPage: ExportToPageIcon,
  save: SaveIcon,
  expand: ExpandIcon,
  arrowsIn: ArrowsInIcon,
  sortAscending: SortAscendingIcon,
  sortDescending: SortDescendingIcon,
  sortNone: SortNoneIcon,
  minimize: MinimizeIcon,
  addToFavorite: AddToFavoriteIcon,
  unstar: UnstarIcon,
  edit: EditIcon,
  newFolder: NewFolderIcon,
  folder: FolderIcon,
  folderOpen: FolderOpenIcon,
};

const Icon: FC<IconProps> = ({ name, sx, ...props }) => (
  <SvgIcon component={icons[name]} inheritViewBox {...props} sx={{ ...sx, fill: 'none' }} />
);

export default Icon;

import { Match, PostProjectsIdQueriesNewQuestionParams, Query } from '@/api/generated';
import { createContext } from 'react';

export type QueryQuestion = PostProjectsIdQueriesNewQuestionParams['question'];
export type QueryId = NonNullable<Query['_id']>;
export type SelectedQueryIds = { queryId: string; matchId?: string };

type QueriesContextValue = {
  isLoading: boolean;
  isLoadingChecklist: boolean;
  queries: Query[];
  selectedQuery: Query | undefined;
  selectedMatch: Match | undefined;
  isMatchesLoading: boolean;
  selectedQueryIds?: SelectedQueryIds | null;
  setSelectedQueryIds: (ids: SelectedQueryIds | undefined) => void;
  createQuery: (question: QueryQuestion) => Promise<Query>;
  deleteQueries: (queryIds: QueryId[]) => Promise<unknown>;
  fetchChecklists: (ids?: string[]) => Promise<void>;
};

export const QueriesContext = createContext<QueriesContextValue | null>(null);

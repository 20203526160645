import { Stack, InputLabel, useTheme, TextField } from '@mui/material';
import { FC, KeyboardEvent } from 'react';
import { ReactGoogleAutocompleteProps, usePlacesWidget } from 'react-google-autocomplete';
import { Control, FieldPath, useController } from 'react-hook-form';
import { ProjectFormValue } from '@/views/Projects/components/ProjectFormDialog/types';

interface GoogleAutoCompleteProps extends ReactGoogleAutocompleteProps {
  id: string;
  label: string;
  placeholder: string;
  control: Control<ProjectFormValue>;
  name: FieldPath<ProjectFormValue>;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
}

const GoogleAutoComplete: FC<GoogleAutoCompleteProps> = ({
  label,
  control,
  name,
  onPlaceSelected,
  onKeyDown,
  ...googleAutoCompleteProps
}) => {
  const { palette } = useTheme();
  const { field } = useController({ name, control });
  const { ref: inputRef } = usePlacesWidget<HTMLInputElement | null>({
    apiKey: import.meta.env.VITE_GOOGLE_MAP_KEY,
    onPlaceSelected: (...args) => {
      const [place] = args;
      field.onChange(place.formatted_address);
      onPlaceSelected?.(...args);
    },
  });
  const { ref: fieldRef, ...fieldProps } = field;

  const handleLabelClick = () => inputRef.current && inputRef.current.focus();

  return (
    <Stack onClick={handleLabelClick}>
      <InputLabel sx={{ color: palette.controls.text, fontWeight: 'fontWeightMedium' }}>{label}</InputLabel>
      <TextField
        {...fieldProps}
        {...googleAutoCompleteProps}
        onKeyDown={onKeyDown}
        inputRef={e => {
          fieldRef(e);
          /* @ts-expect-error */
          inputRef.current = e;
        }}
      />
    </Stack>
  );
};

export default GoogleAutoComplete;

import { Navigate, RouteObject } from 'react-router-dom';
import { lazy } from 'react';
import Loadable from '@/components/Loadable/Loadable';
import Root from '@/containers/Root/Root';
import { ROUTER_IDS, ROUTER_PATHS, toProjects } from '@/services/linker';
import ProjectHeader from '@/views/Project/components/ProjectHeader';
import Projects from '@/views/Projects/Projects';
import ProjectContainer from '@/containers/Project/ProjectContainer';

const ProjectSideBySide = Loadable(
  lazy(() => import('@/views/Project/views/projectRoutes').then(module => ({ default: module.SideBySide }))),
);

export default [
  {
    Component: () => <Root routeHeader={<ProjectHeader />} />,
    path: ROUTER_PATHS.HOME,
    children: [
      {
        id: ROUTER_IDS.HOME,
        path: ROUTER_PATHS.HOME,
        element: <Navigate to={toProjects()} />,
      },
      {
        id: ROUTER_IDS.PROJECTS,
        path: ROUTER_PATHS.PROJECTS,
        element: <Projects />,
      },
      {
        path: ROUTER_PATHS.PROJECT,
        element: <ProjectContainer />,
        children: [
          {
            id: ROUTER_IDS.PROJECT,
            path: ROUTER_PATHS.PROJECT,
            element: <ProjectSideBySide />,
            children: [
              {
                id: ROUTER_IDS.PROJECT_DETAILS,
                path: ROUTER_PATHS.PROJECT_DETAILS,
                lazy: async () => {
                  const { Details } = await import('@/views/Project/views/projectRoutes');
                  return { element: <Details /> };
                },
              },
              {
                id: ROUTER_IDS.PROJECT_TAKEOFF,
                path: ROUTER_PATHS.PROJECT_TAKEOFF,
                lazy: async () => {
                  const { Takeoff } = await import('@/views/Project/views/projectRoutes');
                  return { element: <Takeoff /> };
                },
              },
              {
                id: ROUTER_IDS.PROJECT_QUERIES,
                path: ROUTER_PATHS.PROJECT_QUERIES,
                lazy: async () => {
                  const { Queries } = await import('@/views/Project/views/projectRoutes');
                  return { element: <Queries /> };
                },
              },
              {
                id: ROUTER_IDS.PROJECT_PAGES,
                path: ROUTER_PATHS.PROJECT_PAGES,
                lazy: async () => {
                  const { AutomatePages } = await import('@/views/Project/views/projectRoutes');
                  return { element: <AutomatePages /> };
                },
              },
            ],
          },
        ],
      },
    ],
  },
] satisfies RouteObject[];

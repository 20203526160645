import {
  ClickAwayListener,
  Grow,
  Icon,
  IconButton,
  MenuItem,
  MenuItemProps as MuiMenuItemProps,
  MenuList,
  Paper,
  Popper,
  SxProps,
  useTheme,
} from '@mui/material';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { type TransitionProps } from '@mui/material/transitions';
import { FC, MouseEvent as ReactMouseEvent, ReactNode } from 'react';
import { PopupState, bindPopper, bindTrigger } from 'material-ui-popup-state/hooks';

interface ActionsProps {
  className?: string;
  actionsState: PopupState;
  menuItems: MuiMenuItemProps[];
  stopPropagation?: boolean;
  sx?: SxProps;
  icon?: ReactNode;
}

const Actions: FC<ActionsProps> = ({ className = '', stopPropagation, actionsState, menuItems, sx = {}, icon }) => {
  const { palette } = useTheme();

  const blockLinkAction = (event: ReactMouseEvent) => {
    if (!stopPropagation) return;

    event.preventDefault();
    event.stopPropagation();
    document.body.click();
  };

  const onToggle = (event: ReactMouseEvent) => {
    blockLinkAction(event);
    actionsState.setAnchorEl(event.currentTarget);
    actionsState.toggle();
  };

  const renderMenu = ({ TransitionProps }: { TransitionProps?: TransitionProps }) => (
    <Grow {...TransitionProps}>
      <Paper
        sx={{
          width: '150px',
          overflow: 'hidden',
          boxShadow: 2,
        }}
      >
        <MenuList
          sx={{
            p: 0,
            '.MuiMenuItem-root': {
              py: 2,
              '&:not(:last-child)': { borderBottom: `1px solid ${palette.grey[200]}` },
            },
          }}
        >
          {menuItems.map(({ id, onClick, ...rest }) => (
            <MenuItem
              key={id}
              onClick={(...args) => {
                onClick?.(...args);
                actionsState.close();
              }}
              {...rest}
            />
          ))}
        </MenuList>
      </Paper>
    </Grow>
  );

  return (
    <>
      <ClickAwayListener onClickAway={() => actionsState.close()}>
        <IconButton
          color="primary"
          className={className}
          {...bindTrigger(actionsState)}
          onClick={onToggle}
          sx={{
            p: 0.8,
            mx: -0.4,
            color: palette.text.secondary,
            backgroundColor: 'transparent',
            '&:hover': {
              color: palette.text.secondary,
              backgroundColor: palette.grey[300],
              boxShadow: 'none',
            },
            ...sx,
          }}
        >
          {icon ?? <Icon component={BsThreeDotsVertical} fontSize="small" sx={{ color: palette.grey[700] }} />}
        </IconButton>
      </ClickAwayListener>

      <Popper {...bindPopper(actionsState)} sx={{ zIndex: 2000 }} placement="bottom-end" transition onClick={blockLinkAction}>
        {renderMenu}
      </Popper>
    </>
  );
};

export default Actions;

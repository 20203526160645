import { Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProjectsId } from '@/api/generated';

const ProjectHeader: FC = () => {
  const { projectSlug } = useParams();
  const { typography } = useTheme();

  const { data: project, isLoading } = useGetProjectsId(projectSlug!, {
    query: { enabled: !!projectSlug },
  });

  if (!project || isLoading) return;

  return (
    <Typography
      variant="body1"
      component="h3"
      fontWeight={typography.fontWeightMedium}
      sx={{ borderLeft: 'solid 1px #777572', pl: 3, lineHeight: 1.65 }}
    >
      {project.name}
    </Typography>
  );
};

export default ProjectHeader;

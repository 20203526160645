import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { updateProjectCache } from '@/utils/updateProjectCache';
import { useBatchDeleteDocumentsHook } from '@/api/generated';

type Params = {
  documentIds: string[];
  projectSlug: string;
  updateUrl?: boolean;
};

export const useDeleteDocuments = (queryClient: QueryClient) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const batchDelete = useBatchDeleteDocumentsHook();
  const deletedIds = useRef(new Set<string>());

  return async ({ documentIds: documentIdsToDelete, projectSlug, updateUrl = true }: Params) => {
    documentIdsToDelete.forEach(documentIdToDelete => deletedIds.current.add(documentIdToDelete));
    await batchDelete(projectSlug!, documentIdsToDelete);

    updateProjectCache({ queryClient, projectSlug: projectSlug! }, prevProject => {
      const updatedDocuments = prevProject!.documents?.filter(({ _id }) => !deletedIds.current.has(_id!)) ?? [];
      const isDeletedDocumentOpened = deletedIds.current.has(searchParams.get('documentId')!);

      setSearchParams(prevParams => {
        const nextParams = new URLSearchParams(prevParams);

        const nextDocumentToLoad = updatedDocuments.find(document => !deletedIds.current.has(document._id!));
        if (nextDocumentToLoad && updateUrl) {
          nextParams.set('documentId', nextDocumentToLoad._id!);
        }
        if (!nextDocumentToLoad || isDeletedDocumentOpened) {
          nextParams.delete('documentId');
        }

        return nextParams;
      });

      return {
        ...prevProject!,
        defaultDocumentId:
          prevProject!.defaultDocumentId && deletedIds.current.has(prevProject!.defaultDocumentId)
            ? undefined
            : prevProject!.defaultDocumentId,
        documents: updatedDocuments,
      };
    });
  };
};

import { Box, IconButton, InputAdornment, Stack, useTheme } from '@mui/material';
import { TABS } from '@/views/Knowledge/components/consts';
import Tabs, { TabsValues } from '@/views/Knowledge/components/Tabs';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import Icon from '@/components/Icon/Icon';
import OrganizationalDocuments from '@/views/Knowledge/components/OrganizationalDocuments';
import Memos from '@/views/Knowledge/components/Memos';
import FormInputSubmit, { FormInputSubmitRef } from '@/components/FormInputSubmit/FormInputSubmit';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import useKnowledge from '@/views/Knowledge/useKnowledge';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';

interface FormFilteredFiles {
  filteredFiles: string;
}

const tabs: TabsValues[] = [{ value: TABS.ORGANIZATIONAL_DOCUMENTS }, { value: TABS.MEMOS }];
const validationSchemaFilteredFiles = z.object({ filteredFiles: z.string() }).required();

const KnowledgeBase = () => {
  const [tabValue, setTabValue] = useState<TABS>(TABS.ORGANIZATIONAL_DOCUMENTS);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const { t } = useTranslation('common');
  const formInputSubmitRef = useRef<FormInputSubmitRef>(null);
  const { register, watch } = useForm<FormFilteredFiles>({
    resolver: zodResolver(validationSchemaFilteredFiles),
    defaultValues: { filteredFiles: '' },
  });
  const { palette } = useTheme();
  const { isModified, setIsModified, isActive } = useKnowledge();
  const { showConfirmDialog } = useConfirmDialog();

  const filteredFiles = watch('filteredFiles');

  useEffect(() => {
    if (isSearchOpen && formInputSubmitRef.current) {
      formInputSubmitRef.current.focus();
    }
  }, [isSearchOpen]);

  useEffect(() => {
    !isActive && setTabValue(TABS.ORGANIZATIONAL_DOCUMENTS);
  }, [isActive]);

  const handleTabChange = async (_: SyntheticEvent, newValue: TABS) => {
    const result =
      tabValue === TABS.MEMOS && isModified
        ? await showConfirmDialog({
            title: t('knowledge.memos.discardModal.title'),
            confirm: t('knowledge.memos.discardModal.discard'),
            cancel: t('knowledge.memos.discardModal.cancel'),
          })
        : true;

    if (result) {
      setTabValue(newValue);
      setIsSearchOpen(false);
      setIsModified(false);
    }
  };

  return (
    <Stack sx={{ height: '100%' }}>
      <TabContext value={tabValue}>
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Stack sx={{ pl: 1, width: '100%' }}>
            <Tabs tabs={tabs} handleTabChange={handleTabChange} />
          </Stack>
          {tabValue === TABS.ORGANIZATIONAL_DOCUMENTS && (
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  width: 200,
                  transform: 'translateY(-50%)',
                  zIndex: 1,
                  backgroundColor: palette.background.default,
                  borderRadius: '999px',
                  maxWidth: isSearchOpen ? 300 : 0,
                  maxHeight: isSearchOpen ? 50 : 0,
                  overflow: 'hidden',
                  transition: 'max-width 0.25s ease-in-out, max-height 0.25s ease-in-out',
                }}
              >
                <FormInputSubmit
                  register={register('filteredFiles')}
                  ref={formInputSubmitRef}
                  placeholder={t('knowledge.placeholders.searchPlaceholder')}
                  InputProps={{
                    onBlur: () => setIsSearchOpen(false),
                    startAdornment: (
                      <InputAdornment sx={{ color: palette.grey[700] }} position="start">
                        <Icon name="search" fontSize="medium" sx={{ color: palette.darkPurple.dark }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ '.MuiInputBase-input': { pl: 0, py: 0, pr: 0 }, px: 0.5, py: 0 }}
                />
              </Box>
              <IconButton
                color="primary"
                size="small"
                sx={{ boxShadow: isSearchOpen ? 0 : 2, transition: 'box-shadow 0.25s ease-in-out' }}
                onClick={() => setIsSearchOpen(true)}
              >
                <Icon name="search" fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Stack>
        <TabPanel
          sx={{ pt: 3, pb: 0, px: 0, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
          value={TABS.ORGANIZATIONAL_DOCUMENTS}
        >
          <OrganizationalDocuments filteredFiles={filteredFiles} />
        </TabPanel>
        <TabPanel
          sx={{ pt: 3, px: 0.5, pb: 2, height: '100%', overflow: 'hidden', mt: -3, ml: -3, pl: 3, pr: 0 }}
          value={TABS.MEMOS}
        >
          <Memos />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};

export default KnowledgeBase;

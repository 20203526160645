import React, { FC, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { AnnotationBackendJSON } from 'pspdfkit';
import { useDocuments } from '@/views/Project/hooks/useDocuments';
import { useProject } from '@/views/Project/hooks/useProject';
import { useQueries } from '@/views/Project/hooks/useQueries';
import {
  AnnotationsAndEquipmentResponse,
  getGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipQueryKey,
  useGetProjectsIdDocumentsDocumentIdAnnotationsAndEquip,
} from '@/api/generated';
import { AnnotationsContext } from '@/views/Project/contexts/AnnotationsContext';

interface AnnotationsProviderProps {
  children: React.ReactNode;
}

const ANNOTATIONS_REFETCH_INTERVAL = 500;

const AnnotationsProvider: FC<AnnotationsProviderProps> = ({ children }) => {
  const { t } = useTranslation('project');
  const { documentId } = useDocuments();
  const { projectSlug } = useProject();
  const { selectedMatch } = useQueries();
  const queryClient = useQueryClient();
  const [hiddenAnnotationsIds, setHiddenAnnotations] = useState<string[]>([]);
  const [highlightedAnnotationsIds, setHighlightedAnnotationsIds] = useState<string[]>([]);

  const {
    data: documentData,
    refetch,
    isLoading,
    error,
  } = useGetProjectsIdDocumentsDocumentIdAnnotationsAndEquip(projectSlug, documentId!, {
    query: {
      refetchInterval: ({ state }) => {
        if (state.fetchFailureCount === 3) return false;

        const isEquipmentListNotReady = (state.data?.equipment_list || []).some(({ isReady }) => !isReady);
        return isEquipmentListNotReady ? ANNOTATIONS_REFETCH_INTERVAL : false;
      },
    },
  });

  useEffect(() => {
    if (!error) return;
    enqueueSnackbar(t('annotationsLoadError'), { variant: 'error', autoHideDuration: 5000 });
  }, [error]);

  const equipmentList = useMemo(() => documentData?.equipment_list || [], [documentData?.equipment_list]);

  const documentAnnotations = documentData?.annotations as AnnotationBackendJSON[] | undefined;
  const annotations = useMemo(() => {
    const lockedMatchAnnotations = ((selectedMatch?.annotations || []) as AnnotationBackendJSON[]).map(annotation => ({
      ...annotation,
      flags: ['locked' as const],
    }));
    return [documentAnnotations, lockedMatchAnnotations].filter(annotation => !!annotation).flat();
  }, [selectedMatch, documentAnnotations]);

  const onUpdateDocumentData = (newDocumentData: Partial<AnnotationsAndEquipmentResponse>) => {
    queryClient.setQueryData<AnnotationsAndEquipmentResponse>(
      getGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipQueryKey(projectSlug, documentId!),
      prevData => ({
        equipment_list: newDocumentData.equipment_list ?? prevData?.equipment_list ?? [],
        annotations: newDocumentData.annotations ?? prevData?.annotations ?? [],
      }),
    );
  };

  const hideAnnotations = (annotationIdOrIds: string | Array<string>) => {
    const annotationIds = typeof annotationIdOrIds === 'string' ? [annotationIdOrIds] : annotationIdOrIds;
    setHiddenAnnotations(prevAnnotationIds => [...prevAnnotationIds, ...annotationIds]);
  };

  const showAnnotations = (annotationIdOrIds: string | Array<string>) => {
    const annotationIds = typeof annotationIdOrIds === 'string' ? [annotationIdOrIds] : annotationIdOrIds;
    setHiddenAnnotations(hiddenAnnotationsIds.filter(id => !annotationIds.includes(id)));
  };

  const value = useMemo(
    () => ({
      annotations,
      isAnnotationsFailedToLoad: !!error,
      documentAnnotations: (documentAnnotations ?? []) as AnnotationBackendJSON[],
      equipmentList,
      isLoading,
      refetchDocumentData: refetch,
      onUpdateDocumentData,
      hideAnnotations,
      showAnnotations,
      hiddenAnnotationsIds,
      highlightAnnotations: setHighlightedAnnotationsIds,
      highlightedAnnotationsIds,
    }),
    [documentData, annotations, equipmentList, refetch, error, isLoading, hiddenAnnotationsIds, highlightedAnnotationsIds],
  );

  return <AnnotationsContext.Provider value={value}>{children}</AnnotationsContext.Provider>;
};

export default AnnotationsProvider;

import { IconButton, IconButtonProps, useTheme } from '@mui/material';
import { FC } from 'react';
import LogoIcon from '@/assets/logo-icon.svg?react';
import { useTranslation } from 'react-i18next';
import Icon from '@/components/Icon/Icon';

interface KnowledgeBaseButtonProps extends IconButtonProps {
  isActive: boolean;
}

const KnowledgeBaseButton: FC<KnowledgeBaseButtonProps> = ({ isActive, ...props }) => {
  const { palette } = useTheme();
  const { t } = useTranslation('common');

  return (
    <IconButton
      color="primary"
      title={t('knowledge.knowledgeBaseButtonTitle')}
      sx={{
        px: 1.75,
        py: 1.5,
        position: 'fixed',
        bottom: 24,
        right: 25,
        zIndex: 1100,
        backgroundColor: isActive ? palette.accent.light : palette.feedback.main,
        color: palette.feedback.dark,
        maxWidth: 64,
        maxHeight: 64,
        '&:hover': {
          backgroundColor: palette.secondary.dark,
          boxShadow: 1,
        },
      }}
      {...props}
    >
      {isActive ? <Icon sx={{ fontSize: 'h1.fontSize' }} name="x" /> : <LogoIcon style={{ marginLeft: 2 }} />}
    </IconButton>
  );
};

export default KnowledgeBaseButton;

import { QueryClient } from '@tanstack/react-query';
import { Document, getGetProjectsIdDocumentsDocumentIdQueryKey } from '@/api/generated';
import { updateProjectCache } from '@/utils/updateProjectCache';

type Params = {
  queryClient: QueryClient;
  projectSlug?: string;
  documentId: string;
};

export const updateDocumentCache = (
  { queryClient, projectSlug, documentId }: Params,
  cb: (prevDocument: Document) => Document,
) => {
  if (!projectSlug) return;

  queryClient.setQueryData<Document>(
    getGetProjectsIdDocumentsDocumentIdQueryKey(projectSlug, { document_id: documentId }),
    prevDocument => {
      if (!prevDocument) return;

      const updatedDocument = cb(prevDocument);
      updateProjectCache({ queryClient, projectSlug }, prevProject => ({
        ...prevProject!,
        documents: prevProject!.documents?.map(document => (document._id === documentId ? updatedDocument : document)),
      }));
      return updatedDocument;
    },
  );
};

import { Box, IconButton, Stack, SxProps, Tab, Tabs, Typography } from '@mui/material';
import palette from '@/theme/palette';
import { Link, To } from 'react-router-dom';
import ArrowIcon from '@/assets/arrow-down.svg?react';
import { FC, ReactElement, ReactNode } from 'react';

export interface ViewHeaderTab {
  label: string;
  value: string;
  to: string;
}

export interface ViewHeaderProps {
  title?: ReactNode;
  children: ReactElement;
  backTo?: To;
  tabs: ViewHeaderTab[];
  currentTab: string;
  sx?: SxProps;
  tabSx?: SxProps;
}
const ViewHeader: FC<ViewHeaderProps> = ({ title, children, backTo, tabs, sx, currentTab, tabSx = {} }) => (
  <Box component="header" sx={{ borderBottom: `1px solid ${palette.grey?.['200']}`, ...sx }}>
    <Stack direction="row" justifyContent="space-between" p={title ? 5 : 0}>
      {title && (
        <Stack direction="row" alignItems="center" gap={1}>
          {backTo && (
            <IconButton
              color="primary"
              component={Link}
              to={backTo}
              sx={{ color: 'primary.dark', '& svg': { width: 24, height: 24, transform: 'rotate(90deg)' } }}
            >
              <ArrowIcon />
            </IconButton>
          )}
          <Typography variant="h2" component="h1">
            {title}
          </Typography>
        </Stack>
      )}
      {children}
    </Stack>
    <Tabs value={currentTab} sx={tabSx}>
      {tabs.map(({ label, value, to }) => (
        <Tab disableRipple title={label} key={label} label={label} value={value} to={to} component={Link} />
      ))}
    </Tabs>
  </Box>
);
export default ViewHeader;

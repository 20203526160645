import { Components } from '@mui/material/styles';

const MuiTooltip: Components['MuiTooltip'] = {
  styleOverrides: {
    popper: {
      zIndex: 2000,
    },
  },
};
export default MuiTooltip;

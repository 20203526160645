import { forwardRef, useImperativeHandle, useRef } from 'react';
import { TextField, TextFieldProps, useTheme } from '@mui/material';

type RoundedInputProps = Omit<TextFieldProps, 'ref'>;

const RoundedInput = forwardRef<HTMLDivElement | null, RoundedInputProps>(
  ({ inputProps, sx, inputRef: inputRefProp, ...props }, ref) => {
    const { palette } = useTheme();
    const inputRef = useRef<HTMLInputElement | null>(null);

    useImperativeHandle(inputRefProp, () => inputRef.current);

    return (
      <TextField
        {...props}
        ref={ref}
        inputRef={inputRef}
        inputProps={{
          sx: {
            py: 1,
            px: 2,
            backgroundColor: palette.background.default,
            '&::placeholder': {
              color: palette.chat.light,
              opacity: 1,
            },
            ...inputProps?.sx,
          },
        }}
        sx={{
          maxHeight: '300px',
          width: '100%',
          overflow: 'hidden',
          borderRadius: 5,
          boxShadow: 1,
          border: `1px solid ${palette.darkPurple.main}`,
          backgroundColor: palette.background.default,
          '.MuiInputBase-root': {
            p: 0,
            backgroundColor: palette.background.default,
          },
          '.MuiInputBase-input': {},
          ...sx,
        }}
      />
    );
  },
);

export default RoundedInput;

import { FC, useEffect, useRef, useState } from 'react';
import InitialChatScreen from '@/views/Knowledge/components/InitialChatScreen';
import { MessagesValue } from '@/views/Knowledge/OpenKnowledge';
import FormInputSubmit, { FormInputSubmitRef } from '@/components/FormInputSubmit/FormInputSubmit';
import { Box, CircularProgress, IconButton, InputAdornment, Stack, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';
import { useDropzone } from 'react-dropzone';
import { acceptedFileTypes } from '@/components/UploadFiles/consts';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import useKnowledge from '@/views/Knowledge/useKnowledge';
import {
  ChatWithKnowledgeBaseNewQuestionParams,
  ModelsThreadMessage,
  Source,
  Thread,
  useGetFollowUpQuestions,
} from '@/api/generated';
import * as z from 'zod';
import { useTranslation } from 'react-i18next';
import { convertMarkdownToHtmlSafely } from '@/utils/convertMarkdownToHtmlSafely';
import { TABS } from '@/views/Knowledge/components/consts';
import { MIN_WIDTH } from '@/views/Knowledge/utils/recalculateSafePositionForCollapsed';
import Message from '@/views/Knowledge/components/Message';
import nextTick from '@/services/nextTick';
import SendIconButton from '@/components/SendIconButton';

interface ChatProps {
  messages: MessagesValue[];
  tabValue: TABS;
  setMessages: (messages: MessagesValue[]) => void;
  isWeb: boolean;
  currentQuestionResponse?: Thread;
  setCurrentQuestionResponse: (currentQuestionResponse?: Thread) => void;
  setTabValue: (tabValue: TABS) => void;
}

interface FormCreateQuery {
  questionsCreation: ChatWithKnowledgeBaseNewQuestionParams['question'];
}

const validationSchemaCreateQuery = z.object({ questionsCreation: z.string() }).required();

const getQuestionsAndAnswers = (messages?: ModelsThreadMessage[]) => {
  if (!messages) return { questions: undefined, answers: undefined };
  const questions = messages.filter((_, i) => i % 2 === 0).map(({ content }) => content);
  const answers = messages?.filter((_, i) => i % 2 !== 0)?.map(({ content }) => convertMarkdownToHtmlSafely(content));

  return { questions, answers };
};

const Chat: FC<ChatProps> = ({
  tabValue,
  messages,
  setMessages,
  isWeb,
  currentQuestionResponse,
  setCurrentQuestionResponse,
  setTabValue,
}) => {
  const { palette, spacing } = useTheme();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputContainerRef = useRef<HTMLDivElement | null>(null);
  const [inputContainerHeight, setInputContainerHeight] = useState(0);
  const { uploadFile, createQuestion, currentThread, currentThreadId, isLoadingUpload, isQuestionPending, isActive } =
    useKnowledge();
  const { t } = useTranslation('common');
  const [isDisabledField, setIsDisabledField] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting: isSubmittingCreateQuery, isDirty },
    watch,
    reset,
    setValue,
  } = useForm<FormCreateQuery>({
    resolver: zodResolver(validationSchemaCreateQuery),
    defaultValues: { questionsCreation: '' },
  });
  const questionsCreation = watch('questionsCreation');
  const formInputSubmitRef = useRef<FormInputSubmitRef>(null);

  const { mutate: getSuggestions, data: suggestions, isPending: isSuggestionsLoading } = useGetFollowUpQuestions();
  const [pendingQuestion, setPendingQuestion] = useState<string | null>(null);

  const scrollToBottom = () => {
    const container = containerRef.current;
    if (!container) return;

    nextTick(() => container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' }));
  };

  const scrollToBottomWhenOnBottom = () => {
    const container = containerRef.current;
    if (!container) return;

    const isScrollAtBottom = container.scrollTop === container.scrollHeight - container.clientHeight;
    if (!isScrollAtBottom) return;

    scrollToBottom();
  };

  useEffect(() => {
    nextTick(() => setInputContainerHeight(inputContainerRef.current?.offsetHeight ?? 0));
    scrollToBottom();
  }, [questionsCreation, inputContainerRef]);

  useEffect(() => {
    if (isActive && formInputSubmitRef.current) {
      formInputSubmitRef.current.focus();
    }
  }, [tabValue, isActive]);

  useEffect(() => {
    if (!isActive) return;

    getSuggestions({
      data: {
        source: Source.org_knowledge,
        context: { page_id: '', query_id: '', equipment_id: '', text: '' },
      },
    });
  }, [isActive]);

  useEffect(() => {
    const { questions, answers } = getQuestionsAndAnswers(currentThread?.messages);
    const questionsAndAnswers = questions?.map((question, i) => ({ question, answer: answers?.[i] }));
    setMessages(questionsAndAnswers ?? []);
  }, [currentThread]);

  useEffect(() => {
    setIsDisabledField(!!questionsCreation);
    !questionsCreation && setValue('questionsCreation', '');
  }, [questionsCreation]);

  useEffect(() => {
    const { questions, answers } = getQuestionsAndAnswers(currentThread?.messages || currentQuestionResponse?.messages);
    const questionsAndAnswers = questions?.map((question, i) => ({ question, answer: answers?.[i] }));
    setMessages(questionsAndAnswers ?? []);
    reset();
  }, [tabValue, currentQuestionResponse]);

  useEffect(() => {
    isQuestionPending && setMessages(messages);
  }, [tabValue, isQuestionPending]);

  const handleUpload = async (files: File[]) =>
    files.map(async (file: File) => {
      try {
        await uploadFile(file);
      } catch (err) {
        console.error(err);
      }
    });

  const onSuggestionSelect = (text: string) => {
    setValue('questionsCreation', text);
  };

  const addQuestion = (newMessage: MessagesValue) => {
    setMessages([...messages, newMessage]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
    accept: acceptedFileTypes,
  });

  const onCreateQuestion = async () => {
    scrollToBottomWhenOnBottom();
    setPendingQuestion(questionsCreation);

    try {
      const res: Thread = await createQuestion(questionsCreation, currentThreadId || currentQuestionResponse?.thread_id, isWeb);
      setCurrentQuestionResponse(res);
      const { questions, answers } = getQuestionsAndAnswers(res?.messages);
      const questionsAndAnswers = questions?.map((question, i) => ({ question, answer: answers?.[i] }));
      questionsAndAnswers?.map(message => addQuestion(message));
      reset({ questionsCreation: '' });
    } catch (err) {
      console.error(err);
    }

    setPendingQuestion(null);
    scrollToBottomWhenOnBottom();
  };

  const { onClick, ...rootProps } = getRootProps();

  const endAdornment = (
    <InputAdornment position="end" sx={{ alignSelf: 'flex-end', minHeight: 'auto', height: 'auto', pb: '7px' }}>
      <Box sx={{ mr: 0.5 }}>
        {isLoadingUpload ? (
          <CircularProgress size={24} />
        ) : (
          <IconButton onClick={onClick}>
            <Icon name="attachment" fontSize="small" />
          </IconButton>
        )}
        <input {...getInputProps()} />
      </Box>
      <SendIconButton disabled={!isDirty && !isDisabledField} loading={isSubmittingCreateQuery || isQuestionPending} />
    </InputAdornment>
  );

  return (
    <Box sx={{ maxHeight: '100%', mt: 'auto', px: 6, pt: 2, overflowY: 'auto' }} ref={containerRef}>
      <Box {...rootProps} sx={{ pb: `${inputContainerHeight + parseInt(spacing(5))}px` }}>
        {!isQuestionPending && !isSubmittingCreateQuery && !messages.length ? (
          <InitialChatScreen
            suggestions={suggestions}
            isSuggestionsLoading={isSuggestionsLoading}
            setTabValue={setTabValue}
            onSuggestionSelect={onSuggestionSelect}
          />
        ) : (
          <Stack sx={{ height: '100%' }}>
            {messages.map(message => (
              <Message message={message} />
            ))}
            {pendingQuestion && (
              <Message message={{ question: pendingQuestion, answer: `<p>${t('knowledge.pendingMessage')}</p>` }} />
            )}
          </Stack>
        )}
        <Box
          ref={inputContainerRef}
          sx={{
            position: 'absolute',
            bottom: 0,
            width: '80%',
            minWidth: MIN_WIDTH * 0.8,
            left: '50%',
            transform: 'translateX(-50%)',
            mb: 3,
            zIndex: 1,
          }}
        >
          <FormInputSubmit
            handleSubmit={handleSubmit(onCreateQuestion)}
            disabled={isSubmittingCreateQuery}
            error={errors.questionsCreation}
            register={register('questionsCreation')}
            ref={formInputSubmitRef}
            placeholder={t('knowledge.placeholders.askPellesPlaceholder')}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ pl: 2, color: palette.primary.dark }} position="start">
                  <Icon name="ai" fontSize="xsmall" />
                </InputAdornment>
              ),
              endAdornment,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Chat;

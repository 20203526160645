import { FC } from 'react';
import { Typography, useTheme } from '@mui/material';

interface DialogHeaderProps {
  title: string;
}

const DialogHeader: FC<DialogHeaderProps> = ({ title }) => {
  const { typography, palette } = useTheme();

  return (
    <Typography
      variant="h4"
      component="h2"
      sx={{
        flexShrink: 0,
        width: '100%',
        mb: 3,
        textAlign: 'center',
        fontWeight: typography.fontWeightMedium,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: palette.grey[900],
      }}
    >
      {title}
    </Typography>
  );
};

export default DialogHeader;

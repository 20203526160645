/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * PellesAI backend service | Staging
 * OpenAPI spec version: v0.1.302-262-ge05b26e
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import {
  useCallback
} from 'react'
import { useCustomAxios } from './customAxios';
export type Login200 = { [key: string]: unknown };

export type LoginParams = {
access_key: string;
};

export type UploadVectorDbToBlobParams = {
directory: string;
};

export type DeleteFilesFromBlobParams = {
directory: string;
};

export type UpdateDocumentPagesNamesParams = {
document_id: string;
};

export type ChatWithImageParams = {
query: string;
document_id?: unknown;
page_number?: unknown;
};

export type UploadFileWithVdbResearchParams = {
document_id: string;
};

export type DeleteFileParams = {
file_id: string;
};

export type UpdateFilesystemObjectBody = Folder | File;

export type UpdateFilesystemBatchBodyItem = Folder | File;

export type DeleteFolderParams = {
folder_id: string;
};

export type GetOrganizationEquipmentDescriptionParams = {
annotation_type: PSPDFKitAnnotation;
description?: string;
limit?: number;
offset?: number;
};

export type GetUserEventsParams = {
user_id: string;
start_date?: string;
end_date?: string;
};

export type GetProjectEventsParams = {
project_id: string;
start_date?: string;
end_date?: string;
};

export type RemoveSuggestedActionFromFavoritesParams = {
suggested_action_id: string;
slug?: string;
};

export type SetSuggestedActionsFavoritesParams = {
slug?: string;
};

export type EditSuggestedActionFavoriteParams = {
slug?: string;
};

export type AddSuggestedActionToFavoritesParams = {
slug?: string;
};

export type GetSuggestedActionsFavoritesParams = {
slug?: string;
};

export type GetHomepageActionsParams = {
slug: string;
};

export type RoundToRectParams = {
diameter: number;
height?: number;
};

export type RectToRoundParams = {
width: number;
height: number;
};

export type CleanDeletedDocumentsFromProjectParams = {
slug: string;
skip_document_id?: string[];
};

export type DeleteDocumentFromVectorstoreParams = {
slug: string;
document_id: string;
};

export type DeleteDocumentParams = {
slug: string;
document_id: string;
};

export type DeleteAllProjectsFromUserParams = {
user_id: string;
slug?: string;
};

export type DeleteProjectParams = {
slug: string;
};

export type ChatWithKnowledgeBaseNewQuestionParams = {
question: string;
thread_id?: string;
use_web?: boolean;
};

export type RenamePageInOrgkbDocumentParams = {
page_number: number;
new_name: string;
};

export type GetDocumentFromKnowledgeBaseParams = {
document_id: string;
};

export type UpdateOrgQuotaParams = {
org_id: string;
};

export type GetOrgQuotaStatusParams = {
org_id: string;
};

export type CreateOrganizationTag201 = { [key: string]: unknown };

export type GetOrganizationTagsOrgIdParams = {
tag_name?: string;
type?: TagType;
subtype?: string;
};

export type GetOrganizationMapping200 = {[key: string]: string};

export type AutoDetectEquipmentParams = {
document_id: unknown;
};

export type UpdateEquipmentPriceParams = {
document_id: string;
};

export type DeleteEquipmentPieceParams = {
equipment_id: string;
};

export type SetPageIsPrivateParams = {
is_private: boolean;
};

export type AskAutomateParams = {
question: string;
page_id?: string;
reference?: string;
document_id?: string;
};

export type AskAutomateStreamParams = {
page_id: string;
question?: string;
document_id?: string;
};

export type GetProjectsSlugAutomateThreadParams = {
thread_id?: string;
};

export type PostProjectsSlugAutomateChatWithAutomateNewQuestionParams = {
question: string;
thread_id?: string;
};

export type SearchUsersParams = {
limit?: number;
offset?: number;
};

export type RenamePageParams = {
page_number: number;
new_name: string;
};

export type RenameDocumentParams = {
new_name: string;
};

export type RemoveFromFavoritesParams = {
page_number: number;
};

export type AddToFavoritesParams = {
page_number: number;
};

export type GetProjectsIdDocumentsDocumentIdAnnotations200Item = { [key: string]: unknown };

export type GetProjectsIdDocumentsDocumentIdParams = {
document_id: string;
signed?: boolean;
};

export type UploadFileWithVdbParams = {
parentId?: string;
order?: number;
};

export type PostProjectsIdQueriesQueryIdMatchMatchIdParams = {
feedback: Feedback;
};

export type PostProjectsIdQueriesQueryIdFeedbackParams = {
feedback: Feedback;
};

export type PostProjectsIdQueriesQueryIdStatusParams = {
query_status: QueryStatus;
};

export type PullAndExecuteChecklistByIdBody = string[] | null;

export type PostProjectsIdQueriesNewQuestionParams = {
question: string;
};

export type GetDocumentQuotaStatusParams = {
include_deleted?: boolean;
};

export type UnshareProjectParams = {
user_id: string;
};

export type GetProjectsParams = {
sortBy?: string;
nameFilter?: string;
statusFilter?: Status;
typeFilter?: string;
scopeFilter?: string;
ownerFilter?: string;
cityFilter?: string;
stateFilter?: string;
searchFilter?: string;
organizationSlug?: string;
sortDir?: string;
offset?: number;
limit?: number;
};

export type ModelsThreadMessageUseWeb = boolean | null;

export type ModelsThreadMessageCreator = BaseUser | AutomateUser;

export interface ModelsThreadMessage {
  content: string;
  created_at?: string;
  creator: ModelsThreadMessageCreator;
  run_id?: string;
  type?: string;
  use_web?: ModelsThreadMessageUseWeb;
}

export interface ModelsAutomateMessage {
  content: string;
  created_at?: string;
  creator: BaseUser;
  run_id?: string;
  type?: string;
}

export type AnnotationEventType = typeof AnnotationEventType[keyof typeof AnnotationEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnnotationEventType = {
  create: 'create',
  update: 'update',
  delete: 'delete',
} as const;

export type ValidationErrorLocItem = string | number;

export interface ValidationError {
  loc: ValidationErrorLocItem[];
  msg: string;
  type: string;
}

export type UserUpdateUsername = string | null;

export type UserUpdateTitle = string | null;

export type UserUpdateTags = Tag[] | null;

export type UserUpdateProjects = string[] | null;

export type UserUpdateOrganizationLogo = string[] | null;

export type UserUpdateOrganizationAnyOfItem = Organization | string;

export type UserUpdateOrganization = UserUpdateOrganizationAnyOfItem[] | null;

export type UserUpdateLastName = string | null;

export type UserUpdateFirstName = string | null;

export interface UserUpdate {
  firstName?: UserUpdateFirstName;
  lastName?: UserUpdateLastName;
  organization?: UserUpdateOrganization;
  organization_logo?: UserUpdateOrganizationLogo;
  projects?: UserUpdateProjects;
  tags?: UserUpdateTags;
  title?: UserUpdateTitle;
  username?: UserUpdateUsername;
}

export type UserProjects = string[] | null;

export type UserOrganizationLogo = string[] | null;

export interface User {
  _id?: string;
  algo_settings?: AlgorithmSettings;
  avatar?: string;
  email: string;
  favorite_actions?: SuggestedActionFavorite[];
  firstName: string;
  hashed_password?: UserHashedPassword;
  isAdmin?: boolean;
  lastName: string;
  organization?: UserOrganization;
  organization_logo?: UserOrganizationLogo;
  projects?: UserProjects;
  tags?: Tag[];
  title?: string;
  username: string;
}

export type UserOrganizationAnyOfItem = Organization | string;

export type UserOrganization = UserOrganizationAnyOfItem[] | null;

export type UserHashedPassword = string | null;

export type UnitType = typeof UnitType[keyof typeof UnitType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitType = {
  pieces: 'pieces',
  linear: 'linear',
  area: 'area',
} as const;

export type UnitSystem = typeof UnitSystem[keyof typeof UnitSystem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnitSystem = {
  dimensionless: 'dimensionless',
  metric: 'metric',
  imperial: 'imperial',
} as const;

export type ThreadMetadataTitle = string | null;

export type ThreadMetadataProjectId = string | null;

export type ThreadMetadataDescription = string | null;

export interface ThreadMetadata {
  _id?: string;
  description?: ThreadMetadataDescription;
  last_modified?: string;
  org_id: string;
  project_id?: ThreadMetadataProjectId;
  thread_id: string;
  title?: ThreadMetadataTitle;
}

export type ThreadTitle = string | null;

export type ThreadProjectId = string | null;

export type ThreadDescription = string | null;

export interface Thread {
  _id?: string;
  description?: ThreadDescription;
  last_modified?: string;
  messages?: ModelsThreadMessage[];
  org_id: string;
  project_id?: ThreadProjectId;
  thread_id: string;
  title?: ThreadTitle;
}

export type TagType = typeof TagType[keyof typeof TagType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TagType = {
  persona: 'persona',
  query: 'query',
  equipment: 'equipment',
} as const;

export type TagTypeProperty = TagType | null;

export type TagTagIcon = string | null;

export type TagSubType = string | null;

export type TagDescription = string | null;

export interface Tag {
  color?: string;
  createdAt?: string;
  createdBy?: string;
  description?: TagDescription;
  name: string;
  subType?: TagSubType;
  tag_icon?: TagTagIcon;
  type?: TagTypeProperty;
}

export type SuggestionType = typeof SuggestionType[keyof typeof SuggestionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SuggestionType = {
  question: 'question',
  action: 'action',
} as const;

export interface SuggestedActionResponse {
  /** The properties to be used for the action */
  action_props: ActionProps;
  /** The text to display to the user for the suggested action */
  display_text: string;
  /** The type of action to suggest from the list of possible actions */
  type: ActionType;
}

/**
 * The order of the favorite action in the list of favorites
 */
export type SuggestedActionFavoriteOrder = number | null;

export type SuggestedActionFavoriteActionProps = ActionProps | null;

export interface SuggestedActionFavorite {
  _id?: string;
  action_props?: SuggestedActionFavoriteActionProps;
  /** The text to display to the user for the suggested action */
  display_text: string;
  /** Whether the action is a favorite action */
  is_favorite?: boolean;
  /** The order of the favorite action in the list of favorites */
  order?: SuggestedActionFavoriteOrder;
  /** The type of action to suggest from the list of possible actions */
  type: ActionType;
}

export type Status = typeof Status[keyof typeof Status];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Status = {
  draft: 'draft',
  active: 'active',
  done: 'done',
} as const;

export interface StakeHolder {
  company?: string;
  email?: string;
  name: string;
  phone?: string;
  role?: string;
}

export type Source = typeof Source[keyof typeof Source];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Source = {
  double_check: 'double_check',
  automate: 'automate',
  create: 'create',
  org_knowledge: 'org_knowledge',
  count_and_measure: 'count_and_measure',
  create_follow_ups: 'create_follow_ups',
  homepage: 'homepage',
} as const;

export interface State {
  context?: Context;
  source: Source;
}

export type Role = typeof Role[keyof typeof Role];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Role = {
  viewer: 'viewer',
  editor: 'editor',
} as const;

export interface ReadyForQueries {
  docs_in_progress?: DocInProcess[];
  isReady: boolean;
}

export type RagModel = typeof RagModel[keyof typeof RagModel];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RagModel = {
  openai: 'openai',
  anthropic: 'anthropic',
  azure: 'azure',
  llama: 'llama',
  mistral: 'mistral',
  pelles: 'pelles',
} as const;

export interface QuotaTracking {
  max?: number;
  used?: number;
}

export interface Quota {
  ai_tokens?: number;
  max_documents_per_project?: number;
  max_projects?: number;
  max_size_per_project_mb?: number;
}

export interface Question {
  score?: number;
  suggestion_type?: SuggestionType;
  text: string;
}

export type QueryStatus = typeof QueryStatus[keyof typeof QueryStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryStatus = {
  open: 'open',
  done: 'done',
} as const;

export type QueryQuestionTags = Tag[] | null;

export type QueryQuestionReferenceIsAnomaly = boolean | null;

export type QueryQuestionReference = string | null;

export interface QueryQuestion {
  question: string;
  reference?: QueryQuestionReference;
  referenceIsAnomaly?: QueryQuestionReferenceIsAnomaly;
  tags?: QueryQuestionTags;
}

export type QueryPriority = typeof QueryPriority[keyof typeof QueryPriority];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryPriority = {
  low: 'low',
  mid: 'mid',
  high: 'high',
} as const;

export type QueryLiteCreatedBy = BaseUser | string | null;

export type QueryLiteAnswer = string | null;

export interface QueryLite {
  _id?: string;
  algo_settings?: AlgorithmSettings;
  answer?: QueryLiteAnswer;
  createdBy?: QueryLiteCreatedBy;
  isReady?: boolean;
  last_modified?: string;
  matches?: MetaMatch[];
  priority?: QueryPriority;
  question: string;
  status?: QueryStatus;
  tags?: Tag[];
}

export type QueryCreatedBy = BaseUser | string | null;

export type QueryAnswer = string | null;

export interface Query {
  _id?: string;
  algo_settings?: AlgorithmSettings;
  answer?: QueryAnswer;
  createdBy?: QueryCreatedBy;
  isReady?: boolean;
  last_modified?: string;
  matches?: Match[];
  priority?: QueryPriority;
  question: string;
  status?: QueryStatus;
  tags?: Tag[];
}

export type ProjectsListFiltersAnyOf = { [key: string]: unknown };

export type ProjectsListFilters = ProjectsListFiltersAnyOf | null;

export interface ProjectsList {
  filters?: ProjectsListFilters;
  projects?: ProjectResponse[];
  total?: number;
}

export type ProjectUpdateUserId = string | null;

export type ProjectUpdateUser = string | null;

export type ProjectUpdateUnitSystem = UnitSystem | null;

export type ProjectUpdateType = string | null;

export type ProjectUpdateTakeoffEstimatedCost = number | null;

export type ProjectUpdateSummary = string | null;

export type ProjectUpdateStatus = Status | null;

export type ProjectUpdateStatisticAnyOf = { [key: string]: unknown };

export type ProjectUpdateStatistic = ProjectUpdateStatisticAnyOf | null;

export type ProjectUpdateStakeholders = StakeHolder[] | null;

export type ProjectUpdateSlug = string | null;

export type ProjectUpdateScope = string[] | null;

export type ProjectUpdateProjectSize = string | null;

export type ProjectUpdateOwner = string | null;

export type ProjectUpdateOrganizationId = string | null;

export interface ProjectUpdate {
  address?: ProjectUpdateAddress;
  bidDueDate?: ProjectUpdateBidDueDate;
  collaborate_with?: ProjectUpdateCollaborateWith;
  created_at?: string;
  defaultDocumentId?: ProjectUpdateDefaultDocumentId;
  delivery_method?: ProjectUpdateDeliveryMethod;
  image?: ProjectUpdateImage;
  last_modified?: string;
  location?: ProjectUpdateLocation;
  name?: string;
  notes?: ProjectUpdateNotes;
  organization_id?: ProjectUpdateOrganizationId;
  owner?: ProjectUpdateOwner;
  project_size?: ProjectUpdateProjectSize;
  scope?: ProjectUpdateScope;
  slug?: ProjectUpdateSlug;
  stakeholders?: ProjectUpdateStakeholders;
  statistic?: ProjectUpdateStatistic;
  status?: ProjectUpdateStatus;
  summary?: ProjectUpdateSummary;
  takeoff_estimated_cost?: ProjectUpdateTakeoffEstimatedCost;
  type?: ProjectUpdateType;
  unit_system?: ProjectUpdateUnitSystem;
  user?: ProjectUpdateUser;
  user_id?: ProjectUpdateUserId;
}

export type ProjectUpdateNotesAnyOf = {[key: string]: Note};

export type ProjectUpdateNotes = ProjectUpdateNotesAnyOf | null;

export type ProjectUpdateLocation = ProjectLocation | null;

export type ProjectUpdateImage = string | null;

export type ProjectUpdateDeliveryMethod = string | null;

export type ProjectUpdateDefaultDocumentId = string | null;

export type ProjectUpdateCollaborateWith = BaseUser[] | null;

export type ProjectUpdateBidDueDate = string | null;

export type ProjectUpdateAddress = string | null;

export type ProjectResponseUserId = string | null;

export type ProjectResponseUser = string | null;

export type ProjectResponseUnitSystem = UnitSystem | null;

export type ProjectResponseType = string | null;

export type ProjectResponseSummary = string | null;

export type ProjectResponseStatus = Status | null;

export type ProjectResponseStatistic = { [key: string]: unknown };

export type ProjectResponseStakeholders = StakeHolder[] | null;

export type ProjectResponseScope = string[] | null;

export type ProjectResponseOwner = string | null;

export type ProjectResponseOrganizationId = string | null;

export interface ProjectResponse {
  _id: string;
  address?: ProjectResponseAddress;
  bidDueDate?: ProjectResponseBidDueDate;
  collaborate_with?: ProjectResponseCollaborateWith;
  created_at?: string;
  defaultDocumentId?: ProjectResponseDefaultDocumentId;
  delivery_method?: string;
  image?: ProjectResponseImage;
  last_modified?: string;
  location?: ProjectResponseLocation;
  name?: string;
  notes?: ProjectResponseNotes;
  organization_id?: ProjectResponseOrganizationId;
  owner?: ProjectResponseOwner;
  project_size?: string;
  scope?: ProjectResponseScope;
  slug: string;
  stakeholders?: ProjectResponseStakeholders;
  statistic?: ProjectResponseStatistic;
  status?: ProjectResponseStatus;
  summary?: ProjectResponseSummary;
  takeoff_estimated_cost?: number;
  type?: ProjectResponseType;
  unit_system?: ProjectResponseUnitSystem;
  user?: ProjectResponseUser;
  user_id?: ProjectResponseUserId;
}

export type ProjectResponseNotesAnyOf = {[key: string]: Note};

export type ProjectResponseNotes = ProjectResponseNotesAnyOf | null;

export type ProjectResponseLocation = ProjectLocation | null;

export type ProjectResponseImage = string | null;

export type ProjectResponseDefaultDocumentId = string | null;

export type ProjectResponseCollaborateWith = BaseUser[] | null;

export type ProjectResponseBidDueDate = string | null;

export type ProjectResponseAddress = string | null;

export type ProjectLocationZip = string | null;

export type ProjectLocationNumber = number | null;

export type ProjectLocationLng = number | null;

export type ProjectLocationLat = number | null;

export interface ProjectLocation {
  city?: string;
  country?: string;
  lat?: ProjectLocationLat;
  lng?: ProjectLocationLng;
  number?: ProjectLocationNumber;
  state?: string;
  street?: string;
  zip?: ProjectLocationZip;
}

export type ProjectFullUserId = string | null;

export type ProjectFullUser = string | null;

export type ProjectFullUnitSystem = UnitSystem | null;

export type ProjectFullType = string | null;

export type ProjectFullSummary = string | null;

export type ProjectFullStatus = Status | null;

export type ProjectFullStatistic = { [key: string]: unknown };

export type ProjectFullStakeholders = StakeHolder[] | null;

export type ProjectFullScope = string[] | null;

export type ProjectFullOwner = string | null;

export type ProjectFullOrganizationId = string | null;

export type ProjectFullNotesAnyOf = {[key: string]: Note};

export type ProjectFullNotes = ProjectFullNotesAnyOf | null;

export type ProjectFullLocation = ProjectLocation | null;

export type ProjectFullLastEvent = { [key: string]: unknown };

export type ProjectFullImage = string | null;

export type ProjectFullDefaultDocumentId = string | null;

export type ProjectFullCollaborateWith = BaseUser[] | null;

export type ProjectFullBidDueDate = string | null;

export type ProjectFullAddress = string | null;

export interface ProjectFull {
  _id: string;
  address?: ProjectFullAddress;
  bidDueDate?: ProjectFullBidDueDate;
  collaborate_with?: ProjectFullCollaborateWith;
  created_at?: string;
  defaultDocumentId?: ProjectFullDefaultDocumentId;
  delivery_method?: string;
  documents?: DocumentMetadata[];
  filesystem?: FileSystem;
  image?: ProjectFullImage;
  last_event?: ProjectFullLastEvent;
  last_modified?: string;
  location?: ProjectFullLocation;
  name?: string;
  notes?: ProjectFullNotes;
  organization_id?: ProjectFullOrganizationId;
  owner?: ProjectFullOwner;
  project_size?: string;
  scope?: ProjectFullScope;
  slug: string;
  stakeholders?: ProjectFullStakeholders;
  statistic?: ProjectFullStatistic;
  status?: ProjectFullStatus;
  summary?: ProjectFullSummary;
  takeoff_estimated_cost?: number;
  type?: ProjectFullType;
  unit_system?: ProjectFullUnitSystem;
  user?: ProjectFullUser;
  user_id?: ProjectFullUserId;
}

export type ProjectCreateUserId = string | null;

export type ProjectCreateUser = string | null;

export type ProjectCreateUnitSystem = UnitSystem | null;

export type ProjectCreateType = string | null;

export type ProjectCreateSummary = string | null;

export type ProjectCreateStatus = Status | null;

export type ProjectCreateStatistic = { [key: string]: unknown };

export type ProjectCreateStakeholders = StakeHolder[] | null;

export type ProjectCreateSlug = string | null;

export type ProjectCreateScope = string[] | null;

export type ProjectCreateQueries = Query[] | null;

export type ProjectCreateOwner = string | null;

export type ProjectCreateOrganizationId = string | null;

export type ProjectCreateNotesAnyOf = {[key: string]: Note};

export type ProjectCreateNotes = ProjectCreateNotesAnyOf | null;

export type ProjectCreateLocation = ProjectLocation | null;

export type ProjectCreateIsDeleted = boolean | null;

export type ProjectCreateImage = string | null;

export type ProjectCreateDocuments = Document[] | null;

export type ProjectCreateDefaultDocumentId = string | null;

export type ProjectCreateCollaborateWith = BaseUser[] | null;

export type ProjectCreateBidDueDate = string | null;

export type ProjectCreateAddress = string | null;

export interface ProjectCreate {
  _id?: string;
  address?: ProjectCreateAddress;
  bidDueDate?: ProjectCreateBidDueDate;
  collaborate_with?: ProjectCreateCollaborateWith;
  created_at?: string;
  defaultDocumentId?: ProjectCreateDefaultDocumentId;
  delivery_method?: string;
  documents?: ProjectCreateDocuments;
  image?: ProjectCreateImage;
  isDeleted?: ProjectCreateIsDeleted;
  last_modified?: string;
  location?: ProjectCreateLocation;
  name?: string;
  notes?: ProjectCreateNotes;
  organization_id?: ProjectCreateOrganizationId;
  owner?: ProjectCreateOwner;
  project_size?: string;
  queries?: ProjectCreateQueries;
  scope?: ProjectCreateScope;
  slug?: ProjectCreateSlug;
  stakeholders?: ProjectCreateStakeholders;
  statistic?: ProjectCreateStatistic;
  status?: ProjectCreateStatus;
  summary?: ProjectCreateSummary;
  takeoff_estimated_cost?: number;
  type?: ProjectCreateType;
  unit_system?: ProjectCreateUnitSystem;
  user?: ProjectCreateUser;
  user_id?: ProjectCreateUserId;
}

export type Parser = typeof Parser[keyof typeof Parser];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Parser = {
  pelles_parser: 'pelles_parser',
  llama_parser: 'llama_parser',
  simple_parser: 'simple_parser',
  all: 'all',
} as const;

export type PagesUpdateParentId = string | null;

export type PagesUpdateOrder = number | null;

export type PagesUpdateDescription = string | null;

export interface PagesUpdate {
  description?: PagesUpdateDescription;
  name: string;
  order?: PagesUpdateOrder;
  page_id: string;
  parentId?: PagesUpdateParentId;
}

export type PageUpdateParentId = string | null;

export type PageUpdateOrder = number | null;

export type PageUpdateName = string | null;

export type PageUpdateMarkdown = string | null;

export type PageUpdateDescription = string | null;

export type PageUpdateCreatedBy = string | null;

export type PageUpdateContentAnyOfItem = { [key: string]: unknown };

export type PageUpdateContent = PageUpdateContentAnyOfItem[] | null;

export interface PageUpdate {
  content?: PageUpdateContent;
  created_by?: PageUpdateCreatedBy;
  description?: PageUpdateDescription;
  last_modified?: string;
  markdown?: PageUpdateMarkdown;
  name?: PageUpdateName;
  order?: PageUpdateOrder;
  parentId?: PageUpdateParentId;
}

export type PageResponseVersion = number | null;

export type PageResponseRole = Role | null;

export type PageResponseParentId = string | null;

export type PageResponsePageIcon = string | null;

export type PageResponseMarkdown = string | null;

export type PageResponseIsPrivate = boolean | null;

export type PageResponseDescription = string | null;

export type PageResponseContentAnyOfItem = { [key: string]: unknown };

export type PageResponseContent = PageResponseContentAnyOfItem[] | null;

export interface PageResponse {
  _id?: string;
  content?: PageResponseContent;
  created_at?: string;
  created_by: string;
  description?: PageResponseDescription;
  isPrivate?: PageResponseIsPrivate;
  last_modified?: string;
  markdown?: PageResponseMarkdown;
  name: string;
  order?: number;
  page_icon?: PageResponsePageIcon;
  parentId?: PageResponseParentId;
  project_id: string;
  role?: PageResponseRole;
  version?: PageResponseVersion;
}

export type PageMetadataVersion = number | null;

export type PageMetadataRole = Role | null;

export type PageMetadataParentId = string | null;

export type PageMetadataPageIcon = string | null;

export type PageMetadataIsPrivate = boolean | null;

export type PageMetadataDescription = string | null;

export interface PageMetadata {
  _id?: string;
  created_at?: string;
  created_by: string;
  description?: PageMetadataDescription;
  isPrivate?: PageMetadataIsPrivate;
  last_modified?: string;
  name: string;
  order?: number;
  page_icon?: PageMetadataPageIcon;
  parentId?: PageMetadataParentId;
  project_id: string;
  role?: PageMetadataRole;
  version?: PageMetadataVersion;
}

export type PageCreateParentId = string | null;

export type PageCreateOrder = number | null;

export type PageCreateMarkdown = string | null;

export type PageCreateDescription = string | null;

export type PageCreateContentAnyOfItem = { [key: string]: unknown };

export type PageCreateContent = PageCreateContentAnyOfItem[] | null;

export interface PageCreate {
  content?: PageCreateContent;
  description?: PageCreateDescription;
  markdown?: PageCreateMarkdown;
  name: string;
  order?: PageCreateOrder;
  parentId?: PageCreateParentId;
}

export type PSPDFKitAnnotation = typeof PSPDFKitAnnotation[keyof typeof PSPDFKitAnnotation];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PSPDFKitAnnotation = {
  'pspdfkit/shape/rectangle': 'pspdfkit/shape/rectangle',
  'pspdfkit/shape/line': 'pspdfkit/shape/line',
  'pspdfkit/shape/ellipse': 'pspdfkit/shape/ellipse',
  'pspdfkit/shape/polygon': 'pspdfkit/shape/polygon',
  'pspdfkit/shape/polyline': 'pspdfkit/shape/polyline',
  'pspdfkit/stamp': 'pspdfkit/stamp',
  'pspdfkit/image': 'pspdfkit/image',
  'multi-count': 'multi-count',
  'magic-wand': 'magic-wand',
  pieces: 'pieces',
  area: 'area',
  linear: 'linear',
} as const;

export type OrganizationProjects = string[] | null;

export type OrganizationOrganizationLogo = string | null;

export type OrganizationDescription = string | null;

export interface Organization {
  _id?: string;
  catalog?: EquipmentPiecePrice[];
  checklists?: Checklist[];
  description?: OrganizationDescription;
  knowledge_base?: KnowledgeBase;
  last_modified?: string;
  name: string;
  organization_logo?: OrganizationOrganizationLogo;
  projects?: OrganizationProjects;
  quota?: Quota;
  tags?: Tag[];
}

export type NoteUpdateText = string | null;

export type NoteUpdateColor = string | null;

export interface NoteUpdate {
  color?: NoteUpdateColor;
  last_modified?: string;
  text?: NoteUpdateText;
}

export type NoteTags = string[] | null;

export type NoteShared = boolean | null;

export type NoteQueryRefs = string[] | null;

export type NoteIsDeleted = boolean | null;

export type NoteDocumentId = string | null;

export type NoteCreatedBy = string | null;

export type NoteColor = string | null;

export type NoteAnnotation = Annotation | null;

export interface Note {
  _id: string;
  annotation?: NoteAnnotation;
  color?: NoteColor;
  createdBy?: NoteCreatedBy;
  document_id?: NoteDocumentId;
  isDeleted?: NoteIsDeleted;
  last_modified?: string;
  query_refs?: NoteQueryRefs;
  shared?: NoteShared;
  tags?: NoteTags;
  text?: string;
}

export type MetaMatchNote = string | null;

export type MetaMatchContent = string | null;

export interface MetaMatch {
  content?: MetaMatchContent;
  document_id: string;
  flags?: string[];
  id?: string;
  note?: MetaMatchNote;
  page: number;
  score: number;
}

export type MemoUpdateTitle = string | null;

export type MemoUpdateContent = string | null;

export interface MemoUpdate {
  content?: MemoUpdateContent;
  last_modified?: string;
  title?: MemoUpdateTitle;
}

/**
 * Memo Meta Is used for sharing the memo as a memos list
 */
export interface MemoMeta {
  created_at?: string;
  created_by: string;
  id: string;
  last_modified?: string;
  title: string;
}

export interface MemoCreate {
  content: string;
  title: string;
}

/**
 * Memo is the complete memo content
 */
export interface Memo {
  content: string;
  created_at?: string;
  created_by: string;
  id?: string;
  last_modified?: string;
  title: string;
}

export type MatchNote = string | null;

export type MatchContent = string | null;

export type MatchAnnotationsAnyOfItemAnyOf = { [key: string]: unknown };

export interface MarkupHighlight {
  _id?: string;
  bbox: number[];
  blendMode?: string;
  color?: string;
  createdAt?: string;
  opacity?: number;
  pageIndex: number;
  rects: number[][];
  type?: string;
  updatedAt?: string;
  v?: number;
}

export type MatchAnnotationsAnyOfItem = MatchAnnotationsAnyOfItemAnyOf | MarkupHighlight;

export type MatchAnnotations = MatchAnnotationsAnyOfItem[] | null;

export interface Match {
  annotations?: MatchAnnotations;
  content?: MatchContent;
  document_id: string;
  flags?: string[];
  id?: string;
  note?: MatchNote;
  page: number;
  score: number;
}

export interface KnowledgeBase {
  created_at?: string;
  documents?: DocumentMetadata[];
  id?: string;
  last_modified?: string;
  memos?: Memo[];
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export type FolderIcon = string | null;

export interface Folder {
  icon?: FolderIcon;
  id?: string;
  isFolder?: boolean;
  name: string;
  order?: number;
  parentId?: string;
}

export interface FileSystem {
  root?: FileSystemRoot;
}

export interface File {
  file_type?: string;
  id: string;
  isFolder?: boolean;
  order?: number;
  parentId?: string;
}

export type FileSystemRoot = {[key: string]: File | Folder};

export type FeedbackType = typeof FeedbackType[keyof typeof FeedbackType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeedbackType = {
  bug: 'bug',
  feature_request: 'feature_request',
  general: 'general',
} as const;

export type Feedback = typeof Feedback[keyof typeof Feedback];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Feedback = {
  positive: 'positive',
  negative: 'negative',
  na: 'na',
} as const;

export interface FeedBack {
  createdAt?: string;
  text: string;
  type?: FeedbackType;
}

export type EventType = typeof EventType[keyof typeof EventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventType = {
  create: 'create',
  edit: 'edit',
  takeoff: 'takeoff',
  run: 'run',
  delete: 'delete',
} as const;

export type EventSubType = typeof EventSubType[keyof typeof EventSubType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventSubType = {
  upload: 'upload',
  Query: 'Query',
  rename: 'rename',
  share: 'share',
  unshare: 'unshare',
  delete: 'delete',
  annotate: 'annotate',
  page_naming: 'page_naming',
  auto_detect: 'auto_detect',
  note: 'note',
  delete_doc: 'delete_doc',
  update: 'update',
  checklist: 'checklist',
  feedback: 'feedback',
  tag: 'tag',
  update_answer: 'update_answer',
  page: 'page',
  project: 'project',
  equipment_item: 'equipment_item',
  batch: 'batch',
  single: 'single',
  status: 'status',
} as const;

export type EventRecordInfo = { [key: string]: unknown };

export type EventRecordEventSubType = EventSubType | null;

export type EventCategory = typeof EventCategory[keyof typeof EventCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventCategory = {
  checklist: 'checklist',
  equipment: 'equipment',
  feedback: 'feedback',
  note: 'note',
  organization: 'organization',
  user: 'user',
  project: 'project',
  document: 'document',
  query: 'query',
  page: 'page',
  takeoff: 'takeoff',
} as const;

export interface EventRecord {
  _id?: string;
  event_category: EventCategory;
  event_sub_type?: EventRecordEventSubType;
  event_type: EventType;
  info?: EventRecordInfo;
  org_id: string;
  project_id?: string;
  timestamp?: string;
  user_id: string;
}

export type EquipmentPiecePriceUpdateDescription = string | null;

export interface EquipmentPiecePriceUpdate {
  description?: EquipmentPiecePriceUpdateDescription;
  equipment_id: string;
  price_per_unit: number;
  updatedAt?: string;
}

export interface EquipmentPiecePriceCreate {
  description?: string;
  type: string;
}

export type EquipmentPiecePriceUnitType = UnitType | null;

export type EquipmentPiecePriceDescription = string | null;

export type EquipmentPiecePriceCreatedBy = string | null;

export interface EquipmentPiecePrice {
  _id?: string;
  createdBy?: EquipmentPiecePriceCreatedBy;
  currency?: string;
  description?: EquipmentPiecePriceDescription;
  description_options?: string[];
  equipment_id?: string;
  pound_per_ft?: number;
  price_per_unit: number;
  unit: string;
  unitType?: EquipmentPiecePriceUnitType;
  updatedAt?: string;
}

export type EquipmentPieceUnitSystem = UnitSystem | null;

export type EquipmentPieceTags = string[] | null;

export type EquipmentPiecePricePerUnit = number | null;

export type EquipmentPieceIcon = EquipmentIcon | string;

export type EquipmentPieceCustomData = { [key: string]: unknown };

export type EquipmentPieceConstructingEquipmentItems = ConstructingEquipmentPiece[] | null;

export type EquipmentPieceConstructingAnnotationIds = string[] | null;

export type EquipmentPieceCalculatedAttributes = { [key: string]: unknown };

export interface EquipmentPiece {
  _id: string;
  autoDetected?: boolean;
  calculated_attributes?: EquipmentPieceCalculatedAttributes;
  color: string;
  constructing_annotation_ids?: EquipmentPieceConstructingAnnotationIds;
  constructing_equipment_items?: EquipmentPieceConstructingEquipmentItems;
  count?: number;
  createdAt?: string;
  customData?: EquipmentPieceCustomData;
  defaultAnnotationStyle?: DefaultAnnotationStyle;
  description: string;
  description_options?: string[];
  icon: EquipmentPieceIcon;
  iconColors: string[];
  isReady?: boolean;
  name: string;
  price_per_unit?: EquipmentPiecePricePerUnit;
  tags?: EquipmentPieceTags;
  total_price?: number;
  type?: string;
  unit: string;
  unitSystem?: EquipmentPieceUnitSystem;
  unitType: UnitType;
  updatedAt?: string;
}

export type EquipmentIcon = typeof EquipmentIcon[keyof typeof EquipmentIcon];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EquipmentIcon = {
  multi_count: 'multi_count',
  area: 'area',
  linear: 'linear',
  auto_count: 'auto_count',
} as const;

export interface EquipmentDescriptionSearch {
  description: string;
  equipment_id: string;
}

export interface DocumentsQuotaStatus {
  documents_count?: QuotaTracking;
  total_documents_size?: QuotaTracking;
}

export type DocumentPageTitleAnnotationAnnotation = { [key: string]: unknown };

export interface DocumentPageTitleAnnotation {
  annotation: DocumentPageTitleAnnotationAnnotation;
}

export type DocumentMetadataUpdateScale = number | null;

export type DocumentMetadataUpdatePath = string[] | null;

export type DocumentMetadataUpdatePageNames = string[] | null;

export type DocumentMetadataUpdateFilename = string | null;

export type DocumentMetadataUpdateCollaborateWith = string[] | null;

export interface DocumentMetadataUpdate {
  collaborate_with?: DocumentMetadataUpdateCollaborateWith;
  filename?: DocumentMetadataUpdateFilename;
  last_modified?: string;
  pageNames?: DocumentMetadataUpdatePageNames;
  path?: DocumentMetadataUpdatePath;
  scale?: DocumentMetadataUpdateScale;
}

export type DocumentMetadataScale = number | null;

export type DocumentMetadataPath = string[] | null;

export type DocumentMetadataPageNames = string[] | null;

export type DocumentMetadataFileSize = number | null;

export type DocumentMetadataFavoritePages = number[] | null;

export type DocumentMetadataCollaborateWith = string[] | null;

export interface DocumentMetadata {
  _id?: string;
  collaborate_with?: DocumentMetadataCollaborateWith;
  favorite_pages?: DocumentMetadataFavoritePages;
  file_size?: DocumentMetadataFileSize;
  filename: string;
  last_modified?: string;
  original_filename: string;
  pageNames?: DocumentMetadataPageNames;
  path?: DocumentMetadataPath;
  scale?: DocumentMetadataScale;
  url: string;
}

export type DocumentEquipmentAnnotationAnnotation = { [key: string]: unknown };

export interface DocumentEquipmentAnnotation {
  annotation: DocumentEquipmentAnnotationAnnotation;
}

export type DocumentAnnotationAddAnnotationsAnyOfItem = { [key: string]: unknown };

export type DocumentAnnotationAddAnnotations = DocumentAnnotationAddAnnotationsAnyOfItem[] | null;

export interface DocumentAnnotationAdd {
  annotations?: DocumentAnnotationAddAnnotations;
}

export type DocumentScale = number | null;

export type DocumentPath = string[] | null;

export type DocumentPageNames = string[] | null;

export type DocumentFileSize = number | null;

export type DocumentFavoritePages = number[] | null;

export type DocumentEquipmentList = EquipmentPiece[] | null;

export type DocumentCollaborateWith = string[] | null;

export type DocumentAnnotationsAnyOfItem = { [key: string]: unknown };

export type DocumentAnnotations = DocumentAnnotationsAnyOfItem[] | null;

export interface Document {
  _id?: string;
  annotations?: DocumentAnnotations;
  collaborate_with?: DocumentCollaborateWith;
  equipment_list?: DocumentEquipmentList;
  favorite_pages?: DocumentFavoritePages;
  file_size?: DocumentFileSize;
  filename: string;
  last_modified?: string;
  original_filename: string;
  pageNames?: DocumentPageNames;
  path?: DocumentPath;
  scale?: DocumentScale;
  url: string;
}

export interface DocInProcess {
  id: string;
  name: string;
}

export type DefaultAnnotationStyleType = string | null;

export type DefaultAnnotationStyleStrokeWidth = number | null;

export type DefaultAnnotationStyleStrokeColor = string | null;

export type DefaultAnnotationStyleOpacity = number | null;

export interface DefaultAnnotationStyle {
  fillColor?: DefaultAnnotationStyleFillColor;
  lineCaps?: DefaultAnnotationStyleLineCaps;
  opacity?: DefaultAnnotationStyleOpacity;
  strokeColor?: DefaultAnnotationStyleStrokeColor;
  strokeWidth?: DefaultAnnotationStyleStrokeWidth;
  type?: DefaultAnnotationStyleType;
}

export type DefaultAnnotationStyleLineCapsAnyOf = { [key: string]: unknown };

export type DefaultAnnotationStyleLineCaps = DefaultAnnotationStyleLineCapsAnyOf | null;

export type DefaultAnnotationStyleFillColor = string | null;

export type ContextText = string | null;

export interface Context {
  equipment_id?: string;
  page_id?: string;
  query_id?: string;
  text?: ContextText;
}

export type ConstructingEquipmentPiecePricePerUnit = number | null;

export type ConstructingEquipmentPieceIcon = EquipmentIcon | string;

export type ConstructingEquipmentPieceCustomData = { [key: string]: unknown };

export type ConstructingEquipmentPieceCreatedBy = string | null;

export type ConstructingEquipmentPieceCalculatedAttributes = { [key: string]: unknown };

export interface ConstructingEquipmentPiece {
  _id: string;
  autoDetected?: boolean;
  calculated_attributes?: ConstructingEquipmentPieceCalculatedAttributes;
  color: string;
  count?: number;
  createdAt?: string;
  createdBy?: ConstructingEquipmentPieceCreatedBy;
  customData?: ConstructingEquipmentPieceCustomData;
  description: string;
  description_options?: string[];
  icon: ConstructingEquipmentPieceIcon;
  iconColors: string[];
  name: string;
  parent_equipment_id: string;
  price_per_unit?: ConstructingEquipmentPiecePricePerUnit;
  total_price?: number;
  unit: string;
  unitType: UnitType;
  updatedAt?: string;
}

export type ChecklistDescription = string | null;

export interface Checklist {
  _id?: string;
  createdAt?: string;
  createdBy?: string;
  description?: ChecklistDescription;
  is_default?: boolean;
  last_modified?: string;
  name: string;
  queries?: QueryQuestion[];
}

export interface CatalogItemResponse {
  isExist: boolean;
  item: EquipmentPiecePrice;
}

export interface BodyUploadFileWithVdbV1ProjectsSlugUploadPost {
  file: Blob;
}

export interface BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost {
  file: Blob;
}

export interface BodyUploadDocToKnowledgeBaseV1OrganizationsOrgIdKnowledgeBaseDocumentsUploadPost {
  file: Blob;
}

export interface BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost {
  file: Blob;
}

export interface BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost {
  file: Blob;
}

export interface BaseUser {
  _id?: string;
  avatar?: string;
  email: string;
  favorite_actions?: SuggestedActionFavorite[];
  firstName: string;
  lastName: string;
  tags?: Tag[];
  title?: string;
  username: string;
}

export interface AutomateUser {
  _id?: string;
  avatar?: string;
  email?: string;
  favorite_actions?: SuggestedActionFavorite[];
  firstName?: string;
  lastName?: string;
  tags?: Tag[];
  title?: string;
  username?: string;
}

export interface Automate {
  _id?: string;
  last_modified?: string;
  messages?: ModelsAutomateMessage[];
  project_id: string;
  thread_id: string;
}

export type AnnotationsEventAnnotationsItem = { [key: string]: unknown };

export interface AnnotationsEvent {
  annotations: AnnotationsEventAnnotationsItem[];
  eventAnnotationIds: string[];
  eventType: AnnotationEventType;
}

export type AnnotationsAndEquipmentResponseAnnotationsItem = { [key: string]: unknown };

export interface AnnotationsAndEquipmentResponse {
  annotations: AnnotationsAndEquipmentResponseAnnotationsItem[];
  equipment_list: EquipmentPiece[];
}

export type AnnotationAnnotation = { [key: string]: unknown };

export interface Annotation {
  annotation?: AnnotationAnnotation;
}

export interface AlgorithmSettings {
  lookout_model?: RagModel;
  parser?: Parser;
  rag_k?: number;
  rag_model?: RagModel;
}

export type ActionType = typeof ActionType[keyof typeof ActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionType = {
  open_doublecheck: 'open_doublecheck',
  run_checklist: 'run_checklist',
  new_create_page: 'new_create_page',
  open_create_page: 'open_create_page',
  open_edit_project: 'open_edit_project',
  new_takeoff: 'new_takeoff',
  continue_takeoff: 'continue_takeoff',
  open_orgKB: 'open_orgKB',
  open_upload_files_modal: 'open_upload_files_modal',
  share_project: 'share_project',
} as const;

/**
 * The doc page_num to be used for the action
 */
export type ActionPropsPageIndex = number | null;

/**
 * create page_id if relevant
 */
export type ActionPropsPageId = string | null;

/**
 * The document ID to be used for the action
 */
export type ActionPropsDocId = string | null;

export interface ActionProps {
  /** The document ID to be used for the action */
  doc_id?: ActionPropsDocId;
  /** create page_id if relevant */
  page_id?: ActionPropsPageId;
  /** The doc page_num to be used for the action */
  page_index?: ActionPropsPageIndex;
}





/**
 * @summary Get Metrics
 */
export const useGetMetricsHook = () => {
        const getMetrics = useCustomAxios<unknown>();

        return useCallback((
    
 signal?: AbortSignal
) => {
        return getMetrics(
          {url: `/metrics`, method: 'GET', signal
    },
          );
        }, [getMetrics])
      }
    

export const getGetMetricsQueryKey = () => {
    return [`/metrics`] as const;
    }

    
export const useGetMetricsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetMetricsHook>>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMetricsHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMetricsQueryKey();

  const getMetrics =  useGetMetricsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMetricsHook>>>> = ({ signal }) => getMetrics(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMetricsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMetricsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetMetricsHook>>>>
export type GetMetricsQueryError = unknown

/**
 * @summary Get Metrics
 */
export const useGetMetrics = <TData = Awaited<ReturnType<ReturnType<typeof useGetMetricsHook>>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMetricsHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetMetricsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Reset Cache
 */
export const useResetCacheHook = () => {
        const resetCache = useCustomAxios<unknown>();

        return useCallback((
    
 signal?: AbortSignal
) => {
        return resetCache(
          {url: `/v1/projects/reset_cache`, method: 'GET', signal
    },
          );
        }, [resetCache])
      }
    

export const getResetCacheQueryKey = () => {
    return [`/v1/projects/reset_cache`] as const;
    }

    
export const useResetCacheQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useResetCacheHook>>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useResetCacheHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getResetCacheQueryKey();

  const resetCache =  useResetCacheHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useResetCacheHook>>>> = ({ signal }) => resetCache(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useResetCacheHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type ResetCacheQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useResetCacheHook>>>>
export type ResetCacheQueryError = unknown

/**
 * @summary Reset Cache
 */
export const useResetCache = <TData = Awaited<ReturnType<ReturnType<typeof useResetCacheHook>>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useResetCacheHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useResetCacheQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Post Projects
 */
export const usePostProjectsHook = () => {
        const postProjects = useCustomAxios<ProjectResponse>();

        return useCallback((
    projectCreate: ProjectCreate,
 ) => {
        return postProjects(
          {url: `/v1/projects/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: projectCreate
    },
          );
        }, [postProjects])
      }
    


export const usePostProjectsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsHook>>>, TError,{data: ProjectCreate}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsHook>>>, TError,{data: ProjectCreate}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const postProjects =  usePostProjectsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostProjectsHook>>>, {data: ProjectCreate}> = (props) => {
          const {data} = props ?? {};

          return  postProjects(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostProjectsHook>>>>
    export type PostProjectsMutationBody = ProjectCreate
    export type PostProjectsMutationError = HTTPValidationError

    /**
 * @summary Post Projects
 */
export const usePostProjects = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsHook>>>, TError,{data: ProjectCreate}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePostProjectsHook>>>,
        TError,
        {data: ProjectCreate},
        TContext
      > => {

      const mutationOptions = usePostProjectsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Projects
 */
export const useGetProjectsHook = () => {
        const getProjects = useCustomAxios<ProjectsList>();

        return useCallback((
    params?: GetProjectsParams,
 signal?: AbortSignal
) => {
        return getProjects(
          {url: `/v1/projects/`, method: 'GET',
        params, signal
    },
          );
        }, [getProjects])
      }
    

export const getGetProjectsQueryKey = (params?: GetProjectsParams,) => {
    return [`/v1/projects/`, ...(params ? [params]: [])] as const;
    }

    
export const useGetProjectsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsHook>>>, TError = HTTPValidationError>(params?: GetProjectsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsQueryKey(params);

  const getProjects =  useGetProjectsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectsHook>>>> = ({ signal }) => getProjects(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectsHook>>>>
export type GetProjectsQueryError = HTTPValidationError

/**
 * @summary Get Projects
 */
export const useGetProjects = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsHook>>>, TError = HTTPValidationError>(
 params?: GetProjectsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetProjectsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Projects Id
 */
export const useGetProjectsIdHook = () => {
        const getProjectsId = useCustomAxios<ProjectFull>();

        return useCallback((
    slug: string,
 signal?: AbortSignal
) => {
        return getProjectsId(
          {url: `/v1/projects/${slug}`, method: 'GET', signal
    },
          );
        }, [getProjectsId])
      }
    

export const getGetProjectsIdQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}`] as const;
    }

    
export const useGetProjectsIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsIdHook>>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsIdQueryKey(slug);

  const getProjectsId =  useGetProjectsIdHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdHook>>>> = ({ signal }) => getProjectsId(slug, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdHook>>>>
export type GetProjectsIdQueryError = HTTPValidationError

/**
 * @summary Get Projects Id
 */
export const useGetProjectsId = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsIdHook>>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetProjectsIdQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Edit Project
 */
export const useEditProjectHook = () => {
        const editProject = useCustomAxios<ProjectResponse>();

        return useCallback((
    slug: string,
    projectUpdate: ProjectUpdate,
 ) => {
        return editProject(
          {url: `/v1/projects/${slug}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: projectUpdate
    },
          );
        }, [editProject])
      }
    


export const useEditProjectMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useEditProjectHook>>>, TError,{slug: string;data: ProjectUpdate}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useEditProjectHook>>>, TError,{slug: string;data: ProjectUpdate}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const editProject =  useEditProjectHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useEditProjectHook>>>, {slug: string;data: ProjectUpdate}> = (props) => {
          const {slug,data} = props ?? {};

          return  editProject(slug,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EditProjectMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useEditProjectHook>>>>
    export type EditProjectMutationBody = ProjectUpdate
    export type EditProjectMutationError = HTTPValidationError

    /**
 * @summary Edit Project
 */
export const useEditProject = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useEditProjectHook>>>, TError,{slug: string;data: ProjectUpdate}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useEditProjectHook>>>,
        TError,
        {slug: string;data: ProjectUpdate},
        TContext
      > => {

      const mutationOptions = useEditProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Share Project
 */
export const useShareProjectHook = () => {
        const shareProject = useCustomAxios<ProjectResponse>();

        return useCallback((
    slug: string,
    baseUser: BaseUser,
 ) => {
        return shareProject(
          {url: `/v1/projects/${slug}/share`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: baseUser
    },
          );
        }, [shareProject])
      }
    


export const useShareProjectMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useShareProjectHook>>>, TError,{slug: string;data: BaseUser}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useShareProjectHook>>>, TError,{slug: string;data: BaseUser}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const shareProject =  useShareProjectHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useShareProjectHook>>>, {slug: string;data: BaseUser}> = (props) => {
          const {slug,data} = props ?? {};

          return  shareProject(slug,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ShareProjectMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useShareProjectHook>>>>
    export type ShareProjectMutationBody = BaseUser
    export type ShareProjectMutationError = HTTPValidationError

    /**
 * @summary Share Project
 */
export const useShareProject = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useShareProjectHook>>>, TError,{slug: string;data: BaseUser}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useShareProjectHook>>>,
        TError,
        {slug: string;data: BaseUser},
        TContext
      > => {

      const mutationOptions = useShareProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Unshare Project
 */
export const useUnshareProjectHook = () => {
        const unshareProject = useCustomAxios<ProjectResponse>();

        return useCallback((
    slug: string,
    params: UnshareProjectParams,
 ) => {
        return unshareProject(
          {url: `/v1/projects/${slug}/unshare`, method: 'POST',
        params
    },
          );
        }, [unshareProject])
      }
    


export const useUnshareProjectMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUnshareProjectHook>>>, TError,{slug: string;params: UnshareProjectParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUnshareProjectHook>>>, TError,{slug: string;params: UnshareProjectParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const unshareProject =  useUnshareProjectHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUnshareProjectHook>>>, {slug: string;params: UnshareProjectParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  unshareProject(slug,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UnshareProjectMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUnshareProjectHook>>>>
    
    export type UnshareProjectMutationError = HTTPValidationError

    /**
 * @summary Unshare Project
 */
export const useUnshareProject = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUnshareProjectHook>>>, TError,{slug: string;params: UnshareProjectParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUnshareProjectHook>>>,
        TError,
        {slug: string;params: UnshareProjectParams},
        TContext
      > => {

      const mutationOptions = useUnshareProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Change Project Status
 */
export const useChangeProjectStatusHook = () => {
        const changeProjectStatus = useCustomAxios<ProjectResponse>();

        return useCallback((
    slug: string,
    status: Status,
 ) => {
        return changeProjectStatus(
          {url: `/v1/projects/${slug}/update-status`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: status
    },
          );
        }, [changeProjectStatus])
      }
    


export const useChangeProjectStatusMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeProjectStatusHook>>>, TError,{slug: string;data: Status}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeProjectStatusHook>>>, TError,{slug: string;data: Status}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const changeProjectStatus =  useChangeProjectStatusHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useChangeProjectStatusHook>>>, {slug: string;data: Status}> = (props) => {
          const {slug,data} = props ?? {};

          return  changeProjectStatus(slug,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChangeProjectStatusMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChangeProjectStatusHook>>>>
    export type ChangeProjectStatusMutationBody = Status
    export type ChangeProjectStatusMutationError = HTTPValidationError

    /**
 * @summary Change Project Status
 */
export const useChangeProjectStatus = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeProjectStatusHook>>>, TError,{slug: string;data: Status}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useChangeProjectStatusHook>>>,
        TError,
        {slug: string;data: Status},
        TContext
      > => {

      const mutationOptions = useChangeProjectStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get allowance (currently only count and total size of docs) for a given project
 * @summary Get Document Quota Status
 */
export const useGetDocumentQuotaStatusHook = () => {
        const getDocumentQuotaStatus = useCustomAxios<DocumentsQuotaStatus>();

        return useCallback((
    slug: string,
    params?: GetDocumentQuotaStatusParams,
 signal?: AbortSignal
) => {
        return getDocumentQuotaStatus(
          {url: `/v1/projects${slug}/quota/status`, method: 'GET',
        params, signal
    },
          );
        }, [getDocumentQuotaStatus])
      }
    

export const getGetDocumentQuotaStatusQueryKey = (slug: string,
    params?: GetDocumentQuotaStatusParams,) => {
    return [`/v1/projects${slug}/quota/status`, ...(params ? [params]: [])] as const;
    }

    
export const useGetDocumentQuotaStatusQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentQuotaStatusHook>>>, TError = HTTPValidationError>(slug: string,
    params?: GetDocumentQuotaStatusParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentQuotaStatusHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDocumentQuotaStatusQueryKey(slug,params);

  const getDocumentQuotaStatus =  useGetDocumentQuotaStatusHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDocumentQuotaStatusHook>>>> = ({ signal }) => getDocumentQuotaStatus(slug,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentQuotaStatusHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDocumentQuotaStatusQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDocumentQuotaStatusHook>>>>
export type GetDocumentQuotaStatusQueryError = HTTPValidationError

/**
 * @summary Get Document Quota Status
 */
export const useGetDocumentQuotaStatus = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentQuotaStatusHook>>>, TError = HTTPValidationError>(
 slug: string,
    params?: GetDocumentQuotaStatusParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentQuotaStatusHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetDocumentQuotaStatusQueryOptions(slug,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Post Projects Id Queries New Question
 */
export const usePostProjectsIdQueriesNewQuestionHook = () => {
        const postProjectsIdQueriesNewQuestion = useCustomAxios<Query>();

        return useCallback((
    slug: string,
    params: PostProjectsIdQueriesNewQuestionParams,
 ) => {
        return postProjectsIdQueriesNewQuestion(
          {url: `/v1/projects/${slug}/queries/new_question`, method: 'POST',
        params
    },
          );
        }, [postProjectsIdQueriesNewQuestion])
      }
    


export const usePostProjectsIdQueriesNewQuestionMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesNewQuestionHook>>>, TError,{slug: string;params: PostProjectsIdQueriesNewQuestionParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesNewQuestionHook>>>, TError,{slug: string;params: PostProjectsIdQueriesNewQuestionParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const postProjectsIdQueriesNewQuestion =  usePostProjectsIdQueriesNewQuestionHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesNewQuestionHook>>>, {slug: string;params: PostProjectsIdQueriesNewQuestionParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  postProjectsIdQueriesNewQuestion(slug,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdQueriesNewQuestionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesNewQuestionHook>>>>
    
    export type PostProjectsIdQueriesNewQuestionMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Queries New Question
 */
export const usePostProjectsIdQueriesNewQuestion = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesNewQuestionHook>>>, TError,{slug: string;params: PostProjectsIdQueriesNewQuestionParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesNewQuestionHook>>>,
        TError,
        {slug: string;params: PostProjectsIdQueriesNewQuestionParams},
        TContext
      > => {

      const mutationOptions = usePostProjectsIdQueriesNewQuestionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Checklist Queries From Csv
 */
export const useChecklistQueriesFromCsvHook = () => {
        const checklistQueriesFromCsv = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    bodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost: BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost,
 ) => {const formData = new FormData();
formData.append('file', bodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost.file)

        return checklistQueriesFromCsv(
          {url: `/v1/projects/${slug}/queries/new_checklist_from_csv`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }, [checklistQueriesFromCsv])
      }
    


export const useChecklistQueriesFromCsvMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChecklistQueriesFromCsvHook>>>, TError,{slug: string;data: BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChecklistQueriesFromCsvHook>>>, TError,{slug: string;data: BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const checklistQueriesFromCsv =  useChecklistQueriesFromCsvHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useChecklistQueriesFromCsvHook>>>, {slug: string;data: BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost}> = (props) => {
          const {slug,data} = props ?? {};

          return  checklistQueriesFromCsv(slug,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChecklistQueriesFromCsvMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChecklistQueriesFromCsvHook>>>>
    export type ChecklistQueriesFromCsvMutationBody = BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost
    export type ChecklistQueriesFromCsvMutationError = HTTPValidationError

    /**
 * @summary Checklist Queries From Csv
 */
export const useChecklistQueriesFromCsv = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChecklistQueriesFromCsvHook>>>, TError,{slug: string;data: BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useChecklistQueriesFromCsvHook>>>,
        TError,
        {slug: string;data: BodyChecklistQueriesFromCsvV1ProjectsSlugQueriesNewChecklistFromCsvPost},
        TContext
      > => {

      const mutationOptions = useChecklistQueriesFromCsvMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Pull And Execute Checklist By Id
 */
export const usePullAndExecuteChecklistByIdHook = () => {
        const pullAndExecuteChecklistById = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    pullAndExecuteChecklistByIdBody: PullAndExecuteChecklistByIdBody,
 ) => {
        return pullAndExecuteChecklistById(
          {url: `/v1/projects/${slug}/queries/id/checklist`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: pullAndExecuteChecklistByIdBody
    },
          );
        }, [pullAndExecuteChecklistById])
      }
    


export const usePullAndExecuteChecklistByIdMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePullAndExecuteChecklistByIdHook>>>, TError,{slug: string;data: PullAndExecuteChecklistByIdBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePullAndExecuteChecklistByIdHook>>>, TError,{slug: string;data: PullAndExecuteChecklistByIdBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const pullAndExecuteChecklistById =  usePullAndExecuteChecklistByIdHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePullAndExecuteChecklistByIdHook>>>, {slug: string;data: PullAndExecuteChecklistByIdBody}> = (props) => {
          const {slug,data} = props ?? {};

          return  pullAndExecuteChecklistById(slug,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PullAndExecuteChecklistByIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePullAndExecuteChecklistByIdHook>>>>
    export type PullAndExecuteChecklistByIdMutationBody = PullAndExecuteChecklistByIdBody
    export type PullAndExecuteChecklistByIdMutationError = HTTPValidationError

    /**
 * @summary Pull And Execute Checklist By Id
 */
export const usePullAndExecuteChecklistById = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePullAndExecuteChecklistByIdHook>>>, TError,{slug: string;data: PullAndExecuteChecklistByIdBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePullAndExecuteChecklistByIdHook>>>,
        TError,
        {slug: string;data: PullAndExecuteChecklistByIdBody},
        TContext
      > => {

      const mutationOptions = usePullAndExecuteChecklistByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Projects Id Queries
 */
export const useGetProjectsIdQueriesHook = () => {
        const getProjectsIdQueries = useCustomAxios<QueryLite[]>();

        return useCallback((
    slug: string,
 signal?: AbortSignal
) => {
        return getProjectsIdQueries(
          {url: `/v1/projects/${slug}/queries/`, method: 'GET', signal
    },
          );
        }, [getProjectsIdQueries])
      }
    

export const getGetProjectsIdQueriesQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}/queries/`] as const;
    }

    
export const useGetProjectsIdQueriesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsIdQueriesHook>>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdQueriesHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsIdQueriesQueryKey(slug);

  const getProjectsIdQueries =  useGetProjectsIdQueriesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdQueriesHook>>>> = ({ signal }) => getProjectsIdQueries(slug, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdQueriesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsIdQueriesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdQueriesHook>>>>
export type GetProjectsIdQueriesQueryError = HTTPValidationError

/**
 * @summary Get Projects Id Queries
 */
export const useGetProjectsIdQueries = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsIdQueriesHook>>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdQueriesHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetProjectsIdQueriesQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Post Projects Id Queries Query Id Status
 */
export const usePostProjectsIdQueriesQueryIdStatusHook = () => {
        const postProjectsIdQueriesQueryIdStatus = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    queryId: string,
    params: PostProjectsIdQueriesQueryIdStatusParams,
 ) => {
        return postProjectsIdQueriesQueryIdStatus(
          {url: `/v1/projects/${slug}/queries/${queryId}/status`, method: 'POST',
        params
    },
          );
        }, [postProjectsIdQueriesQueryIdStatus])
      }
    


export const usePostProjectsIdQueriesQueryIdStatusMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdStatusHook>>>, TError,{slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdStatusParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdStatusHook>>>, TError,{slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdStatusParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const postProjectsIdQueriesQueryIdStatus =  usePostProjectsIdQueriesQueryIdStatusHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdStatusHook>>>, {slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdStatusParams}> = (props) => {
          const {slug,queryId,params} = props ?? {};

          return  postProjectsIdQueriesQueryIdStatus(slug,queryId,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdQueriesQueryIdStatusMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdStatusHook>>>>
    
    export type PostProjectsIdQueriesQueryIdStatusMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Queries Query Id Status
 */
export const usePostProjectsIdQueriesQueryIdStatus = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdStatusHook>>>, TError,{slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdStatusParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdStatusHook>>>,
        TError,
        {slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdStatusParams},
        TContext
      > => {

      const mutationOptions = usePostProjectsIdQueriesQueryIdStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Post Projects Id Queries Query Id Delete
 */
export const usePostProjectsIdQueriesQueryIdDeleteHook = () => {
        const postProjectsIdQueriesQueryIdDelete = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    queryId: string,
 ) => {
        return postProjectsIdQueriesQueryIdDelete(
          {url: `/v1/projects/${slug}/queries/${queryId}/delete`, method: 'POST'
    },
          );
        }, [postProjectsIdQueriesQueryIdDelete])
      }
    


export const usePostProjectsIdQueriesQueryIdDeleteMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdDeleteHook>>>, TError,{slug: string;queryId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdDeleteHook>>>, TError,{slug: string;queryId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const postProjectsIdQueriesQueryIdDelete =  usePostProjectsIdQueriesQueryIdDeleteHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdDeleteHook>>>, {slug: string;queryId: string}> = (props) => {
          const {slug,queryId} = props ?? {};

          return  postProjectsIdQueriesQueryIdDelete(slug,queryId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdQueriesQueryIdDeleteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdDeleteHook>>>>
    
    export type PostProjectsIdQueriesQueryIdDeleteMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Queries Query Id Delete
 */
export const usePostProjectsIdQueriesQueryIdDelete = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdDeleteHook>>>, TError,{slug: string;queryId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdDeleteHook>>>,
        TError,
        {slug: string;queryId: string},
        TContext
      > => {

      const mutationOptions = usePostProjectsIdQueriesQueryIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Batch Delete Project Query
 */
export const useBatchDeleteProjectQueryHook = () => {
        const batchDeleteProjectQuery = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    batchDeleteProjectQueryBody: string[],
 ) => {
        return batchDeleteProjectQuery(
          {url: `/v1/projects/${slug}/queries/delete/batch`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: batchDeleteProjectQueryBody
    },
          );
        }, [batchDeleteProjectQuery])
      }
    


export const useBatchDeleteProjectQueryMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBatchDeleteProjectQueryHook>>>, TError,{slug: string;data: string[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBatchDeleteProjectQueryHook>>>, TError,{slug: string;data: string[]}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const batchDeleteProjectQuery =  useBatchDeleteProjectQueryHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useBatchDeleteProjectQueryHook>>>, {slug: string;data: string[]}> = (props) => {
          const {slug,data} = props ?? {};

          return  batchDeleteProjectQuery(slug,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type BatchDeleteProjectQueryMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useBatchDeleteProjectQueryHook>>>>
    export type BatchDeleteProjectQueryMutationBody = string[]
    export type BatchDeleteProjectQueryMutationError = HTTPValidationError

    /**
 * @summary Batch Delete Project Query
 */
export const useBatchDeleteProjectQuery = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBatchDeleteProjectQueryHook>>>, TError,{slug: string;data: string[]}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useBatchDeleteProjectQueryHook>>>,
        TError,
        {slug: string;data: string[]},
        TContext
      > => {

      const mutationOptions = useBatchDeleteProjectQueryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Post Projects Id Queries Query Id Feedback
 */
export const usePostProjectsIdQueriesQueryIdFeedbackHook = () => {
        const postProjectsIdQueriesQueryIdFeedback = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    queryId: string,
    params: PostProjectsIdQueriesQueryIdFeedbackParams,
 ) => {
        return postProjectsIdQueriesQueryIdFeedback(
          {url: `/v1/projects/${slug}/queries/${queryId}/feedback`, method: 'POST',
        params
    },
          );
        }, [postProjectsIdQueriesQueryIdFeedback])
      }
    


export const usePostProjectsIdQueriesQueryIdFeedbackMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdFeedbackHook>>>, TError,{slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdFeedbackParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdFeedbackHook>>>, TError,{slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdFeedbackParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const postProjectsIdQueriesQueryIdFeedback =  usePostProjectsIdQueriesQueryIdFeedbackHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdFeedbackHook>>>, {slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdFeedbackParams}> = (props) => {
          const {slug,queryId,params} = props ?? {};

          return  postProjectsIdQueriesQueryIdFeedback(slug,queryId,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdQueriesQueryIdFeedbackMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdFeedbackHook>>>>
    
    export type PostProjectsIdQueriesQueryIdFeedbackMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Queries Query Id Feedback
 */
export const usePostProjectsIdQueriesQueryIdFeedback = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdFeedbackHook>>>, TError,{slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdFeedbackParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdFeedbackHook>>>,
        TError,
        {slug: string;queryId: string;params: PostProjectsIdQueriesQueryIdFeedbackParams},
        TContext
      > => {

      const mutationOptions = usePostProjectsIdQueriesQueryIdFeedbackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Post Projects Id Queries Query Id Match Match Id
 */
export const usePostProjectsIdQueriesQueryIdMatchMatchIdHook = () => {
        const postProjectsIdQueriesQueryIdMatchMatchId = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    queryId: string,
    matchId: string,
    params: PostProjectsIdQueriesQueryIdMatchMatchIdParams,
 ) => {
        return postProjectsIdQueriesQueryIdMatchMatchId(
          {url: `/v1/projects/${slug}/queries/${queryId}/match/${matchId}/feedback`, method: 'POST',
        params
    },
          );
        }, [postProjectsIdQueriesQueryIdMatchMatchId])
      }
    


export const usePostProjectsIdQueriesQueryIdMatchMatchIdMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdMatchMatchIdHook>>>, TError,{slug: string;queryId: string;matchId: string;params: PostProjectsIdQueriesQueryIdMatchMatchIdParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdMatchMatchIdHook>>>, TError,{slug: string;queryId: string;matchId: string;params: PostProjectsIdQueriesQueryIdMatchMatchIdParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const postProjectsIdQueriesQueryIdMatchMatchId =  usePostProjectsIdQueriesQueryIdMatchMatchIdHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdMatchMatchIdHook>>>, {slug: string;queryId: string;matchId: string;params: PostProjectsIdQueriesQueryIdMatchMatchIdParams}> = (props) => {
          const {slug,queryId,matchId,params} = props ?? {};

          return  postProjectsIdQueriesQueryIdMatchMatchId(slug,queryId,matchId,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdQueriesQueryIdMatchMatchIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdMatchMatchIdHook>>>>
    
    export type PostProjectsIdQueriesQueryIdMatchMatchIdMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Queries Query Id Match Match Id
 */
export const usePostProjectsIdQueriesQueryIdMatchMatchId = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdMatchMatchIdHook>>>, TError,{slug: string;queryId: string;matchId: string;params: PostProjectsIdQueriesQueryIdMatchMatchIdParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePostProjectsIdQueriesQueryIdMatchMatchIdHook>>>,
        TError,
        {slug: string;queryId: string;matchId: string;params: PostProjectsIdQueriesQueryIdMatchMatchIdParams},
        TContext
      > => {

      const mutationOptions = usePostProjectsIdQueriesQueryIdMatchMatchIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Put Projects Id Queries Query Id Tag
 */
export const usePutProjectsIdQueriesQueryIdTagHook = () => {
        const putProjectsIdQueriesQueryIdTag = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    queryId: string,
    tag: Tag,
 ) => {
        return putProjectsIdQueriesQueryIdTag(
          {url: `/v1/projects/${slug}/queries/${queryId}/tags`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: tag
    },
          );
        }, [putProjectsIdQueriesQueryIdTag])
      }
    


export const usePutProjectsIdQueriesQueryIdTagMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutProjectsIdQueriesQueryIdTagHook>>>, TError,{slug: string;queryId: string;data: Tag}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutProjectsIdQueriesQueryIdTagHook>>>, TError,{slug: string;queryId: string;data: Tag}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const putProjectsIdQueriesQueryIdTag =  usePutProjectsIdQueriesQueryIdTagHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePutProjectsIdQueriesQueryIdTagHook>>>, {slug: string;queryId: string;data: Tag}> = (props) => {
          const {slug,queryId,data} = props ?? {};

          return  putProjectsIdQueriesQueryIdTag(slug,queryId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutProjectsIdQueriesQueryIdTagMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePutProjectsIdQueriesQueryIdTagHook>>>>
    export type PutProjectsIdQueriesQueryIdTagMutationBody = Tag
    export type PutProjectsIdQueriesQueryIdTagMutationError = HTTPValidationError

    /**
 * @summary Put Projects Id Queries Query Id Tag
 */
export const usePutProjectsIdQueriesQueryIdTag = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutProjectsIdQueriesQueryIdTagHook>>>, TError,{slug: string;queryId: string;data: Tag}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePutProjectsIdQueriesQueryIdTagHook>>>,
        TError,
        {slug: string;queryId: string;data: Tag},
        TContext
      > => {

      const mutationOptions = usePutProjectsIdQueriesQueryIdTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Projects Id Queries Query Id Tag Tag
 */
export const useDeleteProjectsIdQueriesQueryIdTagTagHook = () => {
        const deleteProjectsIdQueriesQueryIdTagTag = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    queryId: string,
    tag: string,
 ) => {
        return deleteProjectsIdQueriesQueryIdTagTag(
          {url: `/v1/projects/${slug}/queries/${queryId}/tags/${tag}`, method: 'DELETE'
    },
          );
        }, [deleteProjectsIdQueriesQueryIdTagTag])
      }
    


export const useDeleteProjectsIdQueriesQueryIdTagTagMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdQueriesQueryIdTagTagHook>>>, TError,{slug: string;queryId: string;tag: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdQueriesQueryIdTagTagHook>>>, TError,{slug: string;queryId: string;tag: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deleteProjectsIdQueriesQueryIdTagTag =  useDeleteProjectsIdQueriesQueryIdTagTagHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdQueriesQueryIdTagTagHook>>>, {slug: string;queryId: string;tag: string}> = (props) => {
          const {slug,queryId,tag} = props ?? {};

          return  deleteProjectsIdQueriesQueryIdTagTag(slug,queryId,tag,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectsIdQueriesQueryIdTagTagMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdQueriesQueryIdTagTagHook>>>>
    
    export type DeleteProjectsIdQueriesQueryIdTagTagMutationError = HTTPValidationError

    /**
 * @summary Delete Projects Id Queries Query Id Tag Tag
 */
export const useDeleteProjectsIdQueriesQueryIdTagTag = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdQueriesQueryIdTagTagHook>>>, TError,{slug: string;queryId: string;tag: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdQueriesQueryIdTagTagHook>>>,
        TError,
        {slug: string;queryId: string;tag: string},
        TContext
      > => {

      const mutationOptions = useDeleteProjectsIdQueriesQueryIdTagTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Edit Answer
 */
export const useEditAnswerHook = () => {
        const editAnswer = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    queryId: string,
    editAnswerBody: string,
 ) => {
        return editAnswer(
          {url: `/v1/projects/${slug}/queries/${queryId}/answer`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: editAnswerBody
    },
          );
        }, [editAnswer])
      }
    


export const useEditAnswerMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useEditAnswerHook>>>, TError,{slug: string;queryId: string;data: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useEditAnswerHook>>>, TError,{slug: string;queryId: string;data: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const editAnswer =  useEditAnswerHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useEditAnswerHook>>>, {slug: string;queryId: string;data: string}> = (props) => {
          const {slug,queryId,data} = props ?? {};

          return  editAnswer(slug,queryId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EditAnswerMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useEditAnswerHook>>>>
    export type EditAnswerMutationBody = string
    export type EditAnswerMutationError = HTTPValidationError

    /**
 * @summary Edit Answer
 */
export const useEditAnswer = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useEditAnswerHook>>>, TError,{slug: string;queryId: string;data: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useEditAnswerHook>>>,
        TError,
        {slug: string;queryId: string;data: string},
        TContext
      > => {

      const mutationOptions = useEditAnswerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Query By Id
 */
export const useGetQueryByIdHook = () => {
        const getQueryById = useCustomAxios<Query>();

        return useCallback((
    slug: string,
    queryId: string,
 signal?: AbortSignal
) => {
        return getQueryById(
          {url: `/v1/projects/${slug}/queries/${queryId}/`, method: 'GET', signal
    },
          );
        }, [getQueryById])
      }
    

export const getGetQueryByIdQueryKey = (slug: string,
    queryId: string,) => {
    return [`/v1/projects/${slug}/queries/${queryId}/`] as const;
    }

    
export const useGetQueryByIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetQueryByIdHook>>>, TError = HTTPValidationError>(slug: string,
    queryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetQueryByIdHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetQueryByIdQueryKey(slug,queryId);

  const getQueryById =  useGetQueryByIdHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetQueryByIdHook>>>> = ({ signal }) => getQueryById(slug,queryId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && queryId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetQueryByIdHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetQueryByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetQueryByIdHook>>>>
export type GetQueryByIdQueryError = HTTPValidationError

/**
 * @summary Get Query By Id
 */
export const useGetQueryById = <TData = Awaited<ReturnType<ReturnType<typeof useGetQueryByIdHook>>>, TError = HTTPValidationError>(
 slug: string,
    queryId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetQueryByIdHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetQueryByIdQueryOptions(slug,queryId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Upload File With Vdb
 */
export const useUploadFileWithVdbHook = () => {
        const uploadFileWithVdb = useCustomAxios<Document>();

        return useCallback((
    slug: string,
    bodyUploadFileWithVdbV1ProjectsSlugUploadPost: BodyUploadFileWithVdbV1ProjectsSlugUploadPost,
    params?: UploadFileWithVdbParams,
 ) => {const formData = new FormData();
formData.append('file', bodyUploadFileWithVdbV1ProjectsSlugUploadPost.file)

        return uploadFileWithVdb(
          {url: `/v1/projects/${slug}/upload`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData,
        params
    },
          );
        }, [uploadFileWithVdb])
      }
    


export const useUploadFileWithVdbMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadFileWithVdbHook>>>, TError,{slug: string;data: BodyUploadFileWithVdbV1ProjectsSlugUploadPost;params?: UploadFileWithVdbParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadFileWithVdbHook>>>, TError,{slug: string;data: BodyUploadFileWithVdbV1ProjectsSlugUploadPost;params?: UploadFileWithVdbParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const uploadFileWithVdb =  useUploadFileWithVdbHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUploadFileWithVdbHook>>>, {slug: string;data: BodyUploadFileWithVdbV1ProjectsSlugUploadPost;params?: UploadFileWithVdbParams}> = (props) => {
          const {slug,data,params} = props ?? {};

          return  uploadFileWithVdb(slug,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UploadFileWithVdbMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUploadFileWithVdbHook>>>>
    export type UploadFileWithVdbMutationBody = BodyUploadFileWithVdbV1ProjectsSlugUploadPost
    export type UploadFileWithVdbMutationError = HTTPValidationError

    /**
 * @summary Upload File With Vdb
 */
export const useUploadFileWithVdb = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadFileWithVdbHook>>>, TError,{slug: string;data: BodyUploadFileWithVdbV1ProjectsSlugUploadPost;params?: UploadFileWithVdbParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUploadFileWithVdbHook>>>,
        TError,
        {slug: string;data: BodyUploadFileWithVdbV1ProjectsSlugUploadPost;params?: UploadFileWithVdbParams},
        TContext
      > => {

      const mutationOptions = useUploadFileWithVdbMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Projects Id Documents Document Id
 */
export const useGetProjectsIdDocumentsDocumentIdHook = () => {
        const getProjectsIdDocumentsDocumentId = useCustomAxios<DocumentMetadata>();

        return useCallback((
    slug: string,
    params: GetProjectsIdDocumentsDocumentIdParams,
 signal?: AbortSignal
) => {
        return getProjectsIdDocumentsDocumentId(
          {url: `/v1/projects/${slug}/documents`, method: 'GET',
        params, signal
    },
          );
        }, [getProjectsIdDocumentsDocumentId])
      }
    

export const getGetProjectsIdDocumentsDocumentIdQueryKey = (slug: string,
    params: GetProjectsIdDocumentsDocumentIdParams,) => {
    return [`/v1/projects/${slug}/documents`, ...(params ? [params]: [])] as const;
    }

    
export const useGetProjectsIdDocumentsDocumentIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdHook>>>, TError = HTTPValidationError>(slug: string,
    params: GetProjectsIdDocumentsDocumentIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsIdDocumentsDocumentIdQueryKey(slug,params);

  const getProjectsIdDocumentsDocumentId =  useGetProjectsIdDocumentsDocumentIdHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdHook>>>> = ({ signal }) => getProjectsIdDocumentsDocumentId(slug,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsIdDocumentsDocumentIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdHook>>>>
export type GetProjectsIdDocumentsDocumentIdQueryError = HTTPValidationError

/**
 * @summary Get Projects Id Documents Document Id
 */
export const useGetProjectsIdDocumentsDocumentId = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdHook>>>, TError = HTTPValidationError>(
 slug: string,
    params: GetProjectsIdDocumentsDocumentIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetProjectsIdDocumentsDocumentIdQueryOptions(slug,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Projects Id Documents
 */
export const useGetProjectsIdDocumentsHook = () => {
        const getProjectsIdDocuments = useCustomAxios<DocumentMetadata[]>();

        return useCallback((
    slug: string,
 signal?: AbortSignal
) => {
        return getProjectsIdDocuments(
          {url: `/v1/projects/${slug}/`, method: 'GET', signal
    },
          );
        }, [getProjectsIdDocuments])
      }
    

export const getGetProjectsIdDocumentsQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}/`] as const;
    }

    
export const useGetProjectsIdDocumentsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsHook>>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsIdDocumentsQueryKey(slug);

  const getProjectsIdDocuments =  useGetProjectsIdDocumentsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsHook>>>> = ({ signal }) => getProjectsIdDocuments(slug, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsIdDocumentsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsHook>>>>
export type GetProjectsIdDocumentsQueryError = HTTPValidationError

/**
 * @summary Get Projects Id Documents
 */
export const useGetProjectsIdDocuments = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsHook>>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetProjectsIdDocumentsQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Projects Id Documents Document Id Annotations
 */
export const useGetProjectsIdDocumentsDocumentIdAnnotationsHook = () => {
        const getProjectsIdDocumentsDocumentIdAnnotations = useCustomAxios<GetProjectsIdDocumentsDocumentIdAnnotations200Item[]>();

        return useCallback((
    slug: string,
    documentId: string,
 signal?: AbortSignal
) => {
        return getProjectsIdDocumentsDocumentIdAnnotations(
          {url: `/v1/projects/${slug}/${documentId}/annotations`, method: 'GET', signal
    },
          );
        }, [getProjectsIdDocumentsDocumentIdAnnotations])
      }
    

export const getGetProjectsIdDocumentsDocumentIdAnnotationsQueryKey = (slug: string,
    documentId: string,) => {
    return [`/v1/projects/${slug}/${documentId}/annotations`] as const;
    }

    
export const useGetProjectsIdDocumentsDocumentIdAnnotationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationsHook>>>, TError = HTTPValidationError>(slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationsHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsIdDocumentsDocumentIdAnnotationsQueryKey(slug,documentId);

  const getProjectsIdDocumentsDocumentIdAnnotations =  useGetProjectsIdDocumentsDocumentIdAnnotationsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationsHook>>>> = ({ signal }) => getProjectsIdDocumentsDocumentIdAnnotations(slug,documentId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && documentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsIdDocumentsDocumentIdAnnotationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationsHook>>>>
export type GetProjectsIdDocumentsDocumentIdAnnotationsQueryError = HTTPValidationError

/**
 * @summary Get Projects Id Documents Document Id Annotations
 */
export const useGetProjectsIdDocumentsDocumentIdAnnotations = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationsHook>>>, TError = HTTPValidationError>(
 slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationsHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetProjectsIdDocumentsDocumentIdAnnotationsQueryOptions(slug,documentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * ! New annotations endpoint !
we are not getting an AnnotationEvent payload with:
1. the event type (add, remove, modify)
2. the id(s) of the annotation(s) causing this to trigger
3. the annotation list (list of dicts essentially)

Flow is as follows:
- use the dispatcher to classify the event (based on type + modified annotation)
- call the relevant endpoint to deal with it
 * @summary Post Projects Id Documents Document Id Annotations
 */
export const usePostProjectsIdDocumentsDocumentIdAnnotationsHook = () => {
        const postProjectsIdDocumentsDocumentIdAnnotations = useCustomAxios<Document>();

        return useCallback((
    slug: string,
    documentId: string,
    annotationsEvent: AnnotationsEvent,
 ) => {
        return postProjectsIdDocumentsDocumentIdAnnotations(
          {url: `/v1/projects/${slug}/${documentId}/annotations`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: annotationsEvent
    },
          );
        }, [postProjectsIdDocumentsDocumentIdAnnotations])
      }
    


export const usePostProjectsIdDocumentsDocumentIdAnnotationsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdDocumentsDocumentIdAnnotationsHook>>>, TError,{slug: string;documentId: string;data: AnnotationsEvent}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdDocumentsDocumentIdAnnotationsHook>>>, TError,{slug: string;documentId: string;data: AnnotationsEvent}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const postProjectsIdDocumentsDocumentIdAnnotations =  usePostProjectsIdDocumentsDocumentIdAnnotationsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdDocumentsDocumentIdAnnotationsHook>>>, {slug: string;documentId: string;data: AnnotationsEvent}> = (props) => {
          const {slug,documentId,data} = props ?? {};

          return  postProjectsIdDocumentsDocumentIdAnnotations(slug,documentId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdDocumentsDocumentIdAnnotationsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdDocumentsDocumentIdAnnotationsHook>>>>
    export type PostProjectsIdDocumentsDocumentIdAnnotationsMutationBody = AnnotationsEvent
    export type PostProjectsIdDocumentsDocumentIdAnnotationsMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Documents Document Id Annotations
 */
export const usePostProjectsIdDocumentsDocumentIdAnnotations = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdDocumentsDocumentIdAnnotationsHook>>>, TError,{slug: string;documentId: string;data: AnnotationsEvent}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePostProjectsIdDocumentsDocumentIdAnnotationsHook>>>,
        TError,
        {slug: string;documentId: string;data: AnnotationsEvent},
        TContext
      > => {

      const mutationOptions = usePostProjectsIdDocumentsDocumentIdAnnotationsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Projects Id Documents Document Id Annotations And Equip
 */
export const useGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipHook = () => {
        const getProjectsIdDocumentsDocumentIdAnnotationsAndEquip = useCustomAxios<AnnotationsAndEquipmentResponse>();

        return useCallback((
    slug: string,
    documentId: string,
 signal?: AbortSignal
) => {
        return getProjectsIdDocumentsDocumentIdAnnotationsAndEquip(
          {url: `/v1/projects/${slug}/${documentId}/annotations_and_equipment`, method: 'GET', signal
    },
          );
        }, [getProjectsIdDocumentsDocumentIdAnnotationsAndEquip])
      }
    

export const getGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipQueryKey = (slug: string,
    documentId: string,) => {
    return [`/v1/projects/${slug}/${documentId}/annotations_and_equipment`] as const;
    }

    
export const useGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipHook>>>, TError = HTTPValidationError>(slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipQueryKey(slug,documentId);

  const getProjectsIdDocumentsDocumentIdAnnotationsAndEquip =  useGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipHook>>>> = ({ signal }) => getProjectsIdDocumentsDocumentIdAnnotationsAndEquip(slug,documentId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && documentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsIdDocumentsDocumentIdAnnotationsAndEquipQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipHook>>>>
export type GetProjectsIdDocumentsDocumentIdAnnotationsAndEquipQueryError = HTTPValidationError

/**
 * @summary Get Projects Id Documents Document Id Annotations And Equip
 */
export const useGetProjectsIdDocumentsDocumentIdAnnotationsAndEquip = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipHook>>>, TError = HTTPValidationError>(
 slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetProjectsIdDocumentsDocumentIdAnnotationsAndEquipQueryOptions(slug,documentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Projects Id Documents Document Id Lock Annotation
 */
export const useGetProjectsIdDocumentsDocumentIdLockAnnotationHook = () => {
        const getProjectsIdDocumentsDocumentIdLockAnnotation = useCustomAxios<Document>();

        return useCallback((
    slug: string,
    documentId: string,
    documentAnnotationAdd: DocumentAnnotationAdd,
 ) => {
        return getProjectsIdDocumentsDocumentIdLockAnnotation(
          {url: `/v1/projects/${slug}/${documentId}/lock_annotation`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: documentAnnotationAdd
    },
          );
        }, [getProjectsIdDocumentsDocumentIdLockAnnotation])
      }
    


export const useGetProjectsIdDocumentsDocumentIdLockAnnotationMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdLockAnnotationHook>>>, TError,{slug: string;documentId: string;data: DocumentAnnotationAdd}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdLockAnnotationHook>>>, TError,{slug: string;documentId: string;data: DocumentAnnotationAdd}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const getProjectsIdDocumentsDocumentIdLockAnnotation =  useGetProjectsIdDocumentsDocumentIdLockAnnotationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdLockAnnotationHook>>>, {slug: string;documentId: string;data: DocumentAnnotationAdd}> = (props) => {
          const {slug,documentId,data} = props ?? {};

          return  getProjectsIdDocumentsDocumentIdLockAnnotation(slug,documentId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetProjectsIdDocumentsDocumentIdLockAnnotationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdLockAnnotationHook>>>>
    export type GetProjectsIdDocumentsDocumentIdLockAnnotationMutationBody = DocumentAnnotationAdd
    export type GetProjectsIdDocumentsDocumentIdLockAnnotationMutationError = HTTPValidationError

    /**
 * @summary Get Projects Id Documents Document Id Lock Annotation
 */
export const useGetProjectsIdDocumentsDocumentIdLockAnnotation = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdLockAnnotationHook>>>, TError,{slug: string;documentId: string;data: DocumentAnnotationAdd}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdLockAnnotationHook>>>,
        TError,
        {slug: string;documentId: string;data: DocumentAnnotationAdd},
        TContext
      > => {

      const mutationOptions = useGetProjectsIdDocumentsDocumentIdLockAnnotationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Post Projects Id Documents Document Id Update
 */
export const usePostProjectsIdDocumentsDocumentIdUpdateHook = () => {
        const postProjectsIdDocumentsDocumentIdUpdate = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    documentId: string,
    documentMetadataUpdate: DocumentMetadataUpdate,
 ) => {
        return postProjectsIdDocumentsDocumentIdUpdate(
          {url: `/v1/projects/${slug}/${documentId}/update`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: documentMetadataUpdate
    },
          );
        }, [postProjectsIdDocumentsDocumentIdUpdate])
      }
    


export const usePostProjectsIdDocumentsDocumentIdUpdateMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdDocumentsDocumentIdUpdateHook>>>, TError,{slug: string;documentId: string;data: DocumentMetadataUpdate}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdDocumentsDocumentIdUpdateHook>>>, TError,{slug: string;documentId: string;data: DocumentMetadataUpdate}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const postProjectsIdDocumentsDocumentIdUpdate =  usePostProjectsIdDocumentsDocumentIdUpdateHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdDocumentsDocumentIdUpdateHook>>>, {slug: string;documentId: string;data: DocumentMetadataUpdate}> = (props) => {
          const {slug,documentId,data} = props ?? {};

          return  postProjectsIdDocumentsDocumentIdUpdate(slug,documentId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdDocumentsDocumentIdUpdateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdDocumentsDocumentIdUpdateHook>>>>
    export type PostProjectsIdDocumentsDocumentIdUpdateMutationBody = DocumentMetadataUpdate
    export type PostProjectsIdDocumentsDocumentIdUpdateMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Documents Document Id Update
 */
export const usePostProjectsIdDocumentsDocumentIdUpdate = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdDocumentsDocumentIdUpdateHook>>>, TError,{slug: string;documentId: string;data: DocumentMetadataUpdate}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePostProjectsIdDocumentsDocumentIdUpdateHook>>>,
        TError,
        {slug: string;documentId: string;data: DocumentMetadataUpdate},
        TContext
      > => {

      const mutationOptions = usePostProjectsIdDocumentsDocumentIdUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Auto name pages based on repeating bbox (iterate over pages and extract text in bbox)
 * @summary Auto Name Pages
 */
export const useAutoNamePagesHook = () => {
        const autoNamePages = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    documentId: unknown,
    documentPageTitleAnnotation: DocumentPageTitleAnnotation,
 ) => {
        return autoNamePages(
          {url: `/v1/projects/${slug}/${documentId}/auto_page_naming`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: documentPageTitleAnnotation
    },
          );
        }, [autoNamePages])
      }
    


export const useAutoNamePagesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAutoNamePagesHook>>>, TError,{slug: string;documentId: unknown;data: DocumentPageTitleAnnotation}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAutoNamePagesHook>>>, TError,{slug: string;documentId: unknown;data: DocumentPageTitleAnnotation}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const autoNamePages =  useAutoNamePagesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAutoNamePagesHook>>>, {slug: string;documentId: unknown;data: DocumentPageTitleAnnotation}> = (props) => {
          const {slug,documentId,data} = props ?? {};

          return  autoNamePages(slug,documentId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AutoNamePagesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAutoNamePagesHook>>>>
    export type AutoNamePagesMutationBody = DocumentPageTitleAnnotation
    export type AutoNamePagesMutationError = HTTPValidationError

    /**
 * @summary Auto Name Pages
 */
export const useAutoNamePages = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAutoNamePagesHook>>>, TError,{slug: string;documentId: unknown;data: DocumentPageTitleAnnotation}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useAutoNamePagesHook>>>,
        TError,
        {slug: string;documentId: unknown;data: DocumentPageTitleAnnotation},
        TContext
      > => {

      const mutationOptions = useAutoNamePagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Batch Delete Documents
 */
export const useBatchDeleteDocumentsHook = () => {
        const batchDeleteDocuments = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    batchDeleteDocumentsBody: string[],
 ) => {
        return batchDeleteDocuments(
          {url: `/v1/projects/${slug}/batch`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: batchDeleteDocumentsBody
    },
          );
        }, [batchDeleteDocuments])
      }
    


export const useBatchDeleteDocumentsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBatchDeleteDocumentsHook>>>, TError,{slug: string;data: string[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBatchDeleteDocumentsHook>>>, TError,{slug: string;data: string[]}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const batchDeleteDocuments =  useBatchDeleteDocumentsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useBatchDeleteDocumentsHook>>>, {slug: string;data: string[]}> = (props) => {
          const {slug,data} = props ?? {};

          return  batchDeleteDocuments(slug,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type BatchDeleteDocumentsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useBatchDeleteDocumentsHook>>>>
    export type BatchDeleteDocumentsMutationBody = string[]
    export type BatchDeleteDocumentsMutationError = HTTPValidationError

    /**
 * @summary Batch Delete Documents
 */
export const useBatchDeleteDocuments = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBatchDeleteDocumentsHook>>>, TError,{slug: string;data: string[]}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useBatchDeleteDocumentsHook>>>,
        TError,
        {slug: string;data: string[]},
        TContext
      > => {

      const mutationOptions = useBatchDeleteDocumentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Projects Id Documents Document Id
 */
export const useDeleteProjectsIdDocumentsDocumentIdHook = () => {
        const deleteProjectsIdDocumentsDocumentId = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    documentId: string,
 ) => {
        return deleteProjectsIdDocumentsDocumentId(
          {url: `/v1/projects/${slug}/${documentId}`, method: 'DELETE'
    },
          );
        }, [deleteProjectsIdDocumentsDocumentId])
      }
    


export const useDeleteProjectsIdDocumentsDocumentIdMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdDocumentsDocumentIdHook>>>, TError,{slug: string;documentId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdDocumentsDocumentIdHook>>>, TError,{slug: string;documentId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deleteProjectsIdDocumentsDocumentId =  useDeleteProjectsIdDocumentsDocumentIdHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdDocumentsDocumentIdHook>>>, {slug: string;documentId: string}> = (props) => {
          const {slug,documentId} = props ?? {};

          return  deleteProjectsIdDocumentsDocumentId(slug,documentId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectsIdDocumentsDocumentIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdDocumentsDocumentIdHook>>>>
    
    export type DeleteProjectsIdDocumentsDocumentIdMutationError = HTTPValidationError

    /**
 * @summary Delete Projects Id Documents Document Id
 */
export const useDeleteProjectsIdDocumentsDocumentId = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdDocumentsDocumentIdHook>>>, TError,{slug: string;documentId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdDocumentsDocumentIdHook>>>,
        TError,
        {slug: string;documentId: string},
        TContext
      > => {

      const mutationOptions = useDeleteProjectsIdDocumentsDocumentIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * check if all project documents are ready to start accepting queries
 * @summary Check If All Documents Are Vectorized
 */
export const useCheckIfAllDocumentsAreVectorizedHook = () => {
        const checkIfAllDocumentsAreVectorized = useCustomAxios<ReadyForQueries>();

        return useCallback((
    slug: string,
 signal?: AbortSignal
) => {
        return checkIfAllDocumentsAreVectorized(
          {url: `/v1/projects/${slug}/documents/ready`, method: 'GET', signal
    },
          );
        }, [checkIfAllDocumentsAreVectorized])
      }
    

export const getCheckIfAllDocumentsAreVectorizedQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}/documents/ready`] as const;
    }

    
export const useCheckIfAllDocumentsAreVectorizedQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useCheckIfAllDocumentsAreVectorizedHook>>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckIfAllDocumentsAreVectorizedHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCheckIfAllDocumentsAreVectorizedQueryKey(slug);

  const checkIfAllDocumentsAreVectorized =  useCheckIfAllDocumentsAreVectorizedHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCheckIfAllDocumentsAreVectorizedHook>>>> = ({ signal }) => checkIfAllDocumentsAreVectorized(slug, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckIfAllDocumentsAreVectorizedHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type CheckIfAllDocumentsAreVectorizedQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCheckIfAllDocumentsAreVectorizedHook>>>>
export type CheckIfAllDocumentsAreVectorizedQueryError = HTTPValidationError

/**
 * @summary Check If All Documents Are Vectorized
 */
export const useCheckIfAllDocumentsAreVectorized = <TData = Awaited<ReturnType<ReturnType<typeof useCheckIfAllDocumentsAreVectorizedHook>>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckIfAllDocumentsAreVectorizedHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useCheckIfAllDocumentsAreVectorizedQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Check If Document Is Vecorized
 */
export const useCheckIfDocumentIsVecorizedHook = () => {
        const checkIfDocumentIsVecorized = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    documentId: string,
 signal?: AbortSignal
) => {
        return checkIfDocumentIsVecorized(
          {url: `/v1/projects/${slug}/${documentId}/ready`, method: 'GET', signal
    },
          );
        }, [checkIfDocumentIsVecorized])
      }
    

export const getCheckIfDocumentIsVecorizedQueryKey = (slug: string,
    documentId: string,) => {
    return [`/v1/projects/${slug}/${documentId}/ready`] as const;
    }

    
export const useCheckIfDocumentIsVecorizedQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useCheckIfDocumentIsVecorizedHook>>>, TError = HTTPValidationError>(slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckIfDocumentIsVecorizedHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCheckIfDocumentIsVecorizedQueryKey(slug,documentId);

  const checkIfDocumentIsVecorized =  useCheckIfDocumentIsVecorizedHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCheckIfDocumentIsVecorizedHook>>>> = ({ signal }) => checkIfDocumentIsVecorized(slug,documentId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && documentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckIfDocumentIsVecorizedHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type CheckIfDocumentIsVecorizedQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCheckIfDocumentIsVecorizedHook>>>>
export type CheckIfDocumentIsVecorizedQueryError = HTTPValidationError

/**
 * @summary Check If Document Is Vecorized
 */
export const useCheckIfDocumentIsVecorized = <TData = Awaited<ReturnType<ReturnType<typeof useCheckIfDocumentIsVecorizedHook>>>, TError = HTTPValidationError>(
 slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckIfDocumentIsVecorizedHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useCheckIfDocumentIsVecorizedQueryOptions(slug,documentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Add a page to document favorite pages list (if not already there)
 * @summary Add To Favorites
 */
export const useAddToFavoritesHook = () => {
        const addToFavorites = useCustomAxios<DocumentMetadata>();

        return useCallback((
    slug: string,
    documentId: string,
    params: AddToFavoritesParams,
 ) => {
        return addToFavorites(
          {url: `/v1/projects/${slug}/${documentId}/add-favorite-page`, method: 'POST',
        params
    },
          );
        }, [addToFavorites])
      }
    


export const useAddToFavoritesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddToFavoritesHook>>>, TError,{slug: string;documentId: string;params: AddToFavoritesParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddToFavoritesHook>>>, TError,{slug: string;documentId: string;params: AddToFavoritesParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const addToFavorites =  useAddToFavoritesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAddToFavoritesHook>>>, {slug: string;documentId: string;params: AddToFavoritesParams}> = (props) => {
          const {slug,documentId,params} = props ?? {};

          return  addToFavorites(slug,documentId,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddToFavoritesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddToFavoritesHook>>>>
    
    export type AddToFavoritesMutationError = HTTPValidationError

    /**
 * @summary Add To Favorites
 */
export const useAddToFavorites = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddToFavoritesHook>>>, TError,{slug: string;documentId: string;params: AddToFavoritesParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useAddToFavoritesHook>>>,
        TError,
        {slug: string;documentId: string;params: AddToFavoritesParams},
        TContext
      > => {

      const mutationOptions = useAddToFavoritesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Remove a page from document favorite pages list (if present)
 * @summary Remove From Favorites
 */
export const useRemoveFromFavoritesHook = () => {
        const removeFromFavorites = useCustomAxios<DocumentMetadata>();

        return useCallback((
    slug: string,
    documentId: string,
    params: RemoveFromFavoritesParams,
 ) => {
        return removeFromFavorites(
          {url: `/v1/projects/${slug}/${documentId}/remove-favorite-page`, method: 'POST',
        params
    },
          );
        }, [removeFromFavorites])
      }
    


export const useRemoveFromFavoritesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveFromFavoritesHook>>>, TError,{slug: string;documentId: string;params: RemoveFromFavoritesParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveFromFavoritesHook>>>, TError,{slug: string;documentId: string;params: RemoveFromFavoritesParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const removeFromFavorites =  useRemoveFromFavoritesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRemoveFromFavoritesHook>>>, {slug: string;documentId: string;params: RemoveFromFavoritesParams}> = (props) => {
          const {slug,documentId,params} = props ?? {};

          return  removeFromFavorites(slug,documentId,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RemoveFromFavoritesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRemoveFromFavoritesHook>>>>
    
    export type RemoveFromFavoritesMutationError = HTTPValidationError

    /**
 * @summary Remove From Favorites
 */
export const useRemoveFromFavorites = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveFromFavoritesHook>>>, TError,{slug: string;documentId: string;params: RemoveFromFavoritesParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useRemoveFromFavoritesHook>>>,
        TError,
        {slug: string;documentId: string;params: RemoveFromFavoritesParams},
        TContext
      > => {

      const mutationOptions = useRemoveFromFavoritesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Rename a document
 * @summary Rename Document
 */
export const useRenameDocumentHook = () => {
        const renameDocument = useCustomAxios<DocumentMetadata>();

        return useCallback((
    slug: string,
    documentId: string,
    params: RenameDocumentParams,
 ) => {
        return renameDocument(
          {url: `/v1/projects/${slug}/${documentId}/rename`, method: 'POST',
        params
    },
          );
        }, [renameDocument])
      }
    


export const useRenameDocumentMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRenameDocumentHook>>>, TError,{slug: string;documentId: string;params: RenameDocumentParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRenameDocumentHook>>>, TError,{slug: string;documentId: string;params: RenameDocumentParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const renameDocument =  useRenameDocumentHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRenameDocumentHook>>>, {slug: string;documentId: string;params: RenameDocumentParams}> = (props) => {
          const {slug,documentId,params} = props ?? {};

          return  renameDocument(slug,documentId,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RenameDocumentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRenameDocumentHook>>>>
    
    export type RenameDocumentMutationError = HTTPValidationError

    /**
 * @summary Rename Document
 */
export const useRenameDocument = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRenameDocumentHook>>>, TError,{slug: string;documentId: string;params: RenameDocumentParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useRenameDocumentHook>>>,
        TError,
        {slug: string;documentId: string;params: RenameDocumentParams},
        TContext
      > => {

      const mutationOptions = useRenameDocumentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Rename a page in a document
 * @summary Rename Page
 */
export const useRenamePageHook = () => {
        const renamePage = useCustomAxios<DocumentMetadata>();

        return useCallback((
    slug: string,
    documentId: string,
    params: RenamePageParams,
 ) => {
        return renamePage(
          {url: `/v1/projects/${slug}/${documentId}/page/rename`, method: 'POST',
        params
    },
          );
        }, [renamePage])
      }
    


export const useRenamePageMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRenamePageHook>>>, TError,{slug: string;documentId: string;params: RenamePageParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRenamePageHook>>>, TError,{slug: string;documentId: string;params: RenamePageParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const renamePage =  useRenamePageHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRenamePageHook>>>, {slug: string;documentId: string;params: RenamePageParams}> = (props) => {
          const {slug,documentId,params} = props ?? {};

          return  renamePage(slug,documentId,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RenamePageMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRenamePageHook>>>>
    
    export type RenamePageMutationError = HTTPValidationError

    /**
 * @summary Rename Page
 */
export const useRenamePage = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRenamePageHook>>>, TError,{slug: string;documentId: string;params: RenamePageParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useRenamePageHook>>>,
        TError,
        {slug: string;documentId: string;params: RenamePageParams},
        TContext
      > => {

      const mutationOptions = useRenamePageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Rerun document ingestion for documents that aren't ingested
 * @summary Rerun Docs
 */
export const useRerunDocsHook = () => {
        const rerunDocs = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
 ) => {
        return rerunDocs(
          {url: `/v1/projects/${slug}/rerun`, method: 'POST'
    },
          );
        }, [rerunDocs])
      }
    


export const useRerunDocsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRerunDocsHook>>>, TError,{slug: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRerunDocsHook>>>, TError,{slug: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const rerunDocs =  useRerunDocsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRerunDocsHook>>>, {slug: string}> = (props) => {
          const {slug} = props ?? {};

          return  rerunDocs(slug,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RerunDocsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRerunDocsHook>>>>
    
    export type RerunDocsMutationError = HTTPValidationError

    /**
 * @summary Rerun Docs
 */
export const useRerunDocs = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRerunDocsHook>>>, TError,{slug: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useRerunDocsHook>>>,
        TError,
        {slug: string},
        TContext
      > => {

      const mutationOptions = useRerunDocsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Rerun document ingestion for a specific document
 * @summary Rerun Doc
 */
export const useRerunDocHook = () => {
        const rerunDoc = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    documentId: string,
 ) => {
        return rerunDoc(
          {url: `/v1/projects/${slug}/rerun/${documentId}`, method: 'POST'
    },
          );
        }, [rerunDoc])
      }
    


export const useRerunDocMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRerunDocHook>>>, TError,{slug: string;documentId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRerunDocHook>>>, TError,{slug: string;documentId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const rerunDoc =  useRerunDocHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRerunDocHook>>>, {slug: string;documentId: string}> = (props) => {
          const {slug,documentId} = props ?? {};

          return  rerunDoc(slug,documentId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RerunDocMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRerunDocHook>>>>
    
    export type RerunDocMutationError = HTTPValidationError

    /**
 * @summary Rerun Doc
 */
export const useRerunDoc = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRerunDocHook>>>, TError,{slug: string;documentId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useRerunDocHook>>>,
        TError,
        {slug: string;documentId: string},
        TContext
      > => {

      const mutationOptions = useRerunDocMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Users Me
 */
export const useGetUsersMeHook = () => {
        const getUsersMe = useCustomAxios<User>();

        return useCallback((
    
 signal?: AbortSignal
) => {
        return getUsersMe(
          {url: `/v1/users/me`, method: 'GET', signal
    },
          );
        }, [getUsersMe])
      }
    

export const getGetUsersMeQueryKey = () => {
    return [`/v1/users/me`] as const;
    }

    
export const useGetUsersMeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetUsersMeHook>>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersMeHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersMeQueryKey();

  const getUsersMe =  useGetUsersMeHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUsersMeHook>>>> = ({ signal }) => getUsersMe(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersMeHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersMeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUsersMeHook>>>>
export type GetUsersMeQueryError = unknown

/**
 * @summary Get Users Me
 */
export const useGetUsersMe = <TData = Awaited<ReturnType<ReturnType<typeof useGetUsersMeHook>>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersMeHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetUsersMeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Users
 */
export const useGetUsersHook = () => {
        const getUsers = useCustomAxios<User[]>();

        return useCallback((
    
 signal?: AbortSignal
) => {
        return getUsers(
          {url: `/v1/users/`, method: 'GET', signal
    },
          );
        }, [getUsers])
      }
    

export const getGetUsersQueryKey = () => {
    return [`/v1/users/`] as const;
    }

    
export const useGetUsersQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersQueryKey();

  const getUsers =  useGetUsersHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>> = ({ signal }) => getUsers(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>>
export type GetUsersQueryError = unknown

/**
 * @summary Get Users
 */
export const useGetUsers = <TData = Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetUsersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary List Organization Users
 */
export const useListOrganizationUsersHook = () => {
        const listOrganizationUsers = useCustomAxios<BaseUser[]>();

        return useCallback((
    orgId: string,
 signal?: AbortSignal
) => {
        return listOrganizationUsers(
          {url: `/v1/users/${orgId}`, method: 'GET', signal
    },
          );
        }, [listOrganizationUsers])
      }
    

export const getListOrganizationUsersQueryKey = (orgId: string,) => {
    return [`/v1/users/${orgId}`] as const;
    }

    
export const useListOrganizationUsersQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListOrganizationUsersHook>>>, TError = HTTPValidationError>(orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListOrganizationUsersHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListOrganizationUsersQueryKey(orgId);

  const listOrganizationUsers =  useListOrganizationUsersHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListOrganizationUsersHook>>>> = ({ signal }) => listOrganizationUsers(orgId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListOrganizationUsersHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type ListOrganizationUsersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListOrganizationUsersHook>>>>
export type ListOrganizationUsersQueryError = HTTPValidationError

/**
 * @summary List Organization Users
 */
export const useListOrganizationUsers = <TData = Awaited<ReturnType<ReturnType<typeof useListOrganizationUsersHook>>>, TError = HTTPValidationError>(
 orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListOrganizationUsersHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useListOrganizationUsersQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Users Id
 */
export const useGetUsersIdHook = () => {
        const getUsersId = useCustomAxios<User>();

        return useCallback((
    id: string,
 signal?: AbortSignal
) => {
        return getUsersId(
          {url: `/v1/users/${id}`, method: 'GET', signal
    },
          );
        }, [getUsersId])
      }
    

export const getGetUsersIdQueryKey = (id: string,) => {
    return [`/v1/users/${id}`] as const;
    }

    
export const useGetUsersIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetUsersIdHook>>>, TError = HTTPValidationError>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersIdHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersIdQueryKey(id);

  const getUsersId =  useGetUsersIdHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUsersIdHook>>>> = ({ signal }) => getUsersId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersIdHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUsersIdHook>>>>
export type GetUsersIdQueryError = HTTPValidationError

/**
 * @summary Get Users Id
 */
export const useGetUsersId = <TData = Awaited<ReturnType<ReturnType<typeof useGetUsersIdHook>>>, TError = HTTPValidationError>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersIdHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetUsersIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Put Users Id
 */
export const usePutUsersIdHook = () => {
        const putUsersId = useCustomAxios<User>();

        return useCallback((
    id: string,
    userUpdate: UserUpdate,
 ) => {
        return putUsersId(
          {url: `/v1/users/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: userUpdate
    },
          );
        }, [putUsersId])
      }
    


export const usePutUsersIdMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutUsersIdHook>>>, TError,{id: string;data: UserUpdate}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutUsersIdHook>>>, TError,{id: string;data: UserUpdate}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const putUsersId =  usePutUsersIdHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePutUsersIdHook>>>, {id: string;data: UserUpdate}> = (props) => {
          const {id,data} = props ?? {};

          return  putUsersId(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutUsersIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePutUsersIdHook>>>>
    export type PutUsersIdMutationBody = UserUpdate
    export type PutUsersIdMutationError = HTTPValidationError

    /**
 * @summary Put Users Id
 */
export const usePutUsersId = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutUsersIdHook>>>, TError,{id: string;data: UserUpdate}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePutUsersIdHook>>>,
        TError,
        {id: string;data: UserUpdate},
        TContext
      > => {

      const mutationOptions = usePutUsersIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Search for users using query str against user names and emails, limit is the max number of results to return, offset is the number of results to skip
 * @summary Search Users
 */
export const useSearchUsersHook = () => {
        const searchUsers = useCustomAxios<BaseUser[]>();

        return useCallback((
    queryStr: string,
    params?: SearchUsersParams,
 signal?: AbortSignal
) => {
        return searchUsers(
          {url: `/v1/users/search/${queryStr}`, method: 'GET',
        params, signal
    },
          );
        }, [searchUsers])
      }
    

export const getSearchUsersQueryKey = (queryStr: string,
    params?: SearchUsersParams,) => {
    return [`/v1/users/search/${queryStr}`, ...(params ? [params]: [])] as const;
    }

    
export const useSearchUsersQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useSearchUsersHook>>>, TError = HTTPValidationError>(queryStr: string,
    params?: SearchUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useSearchUsersHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchUsersQueryKey(queryStr,params);

  const searchUsers =  useSearchUsersHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useSearchUsersHook>>>> = ({ signal }) => searchUsers(queryStr,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(queryStr), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useSearchUsersHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchUsersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSearchUsersHook>>>>
export type SearchUsersQueryError = HTTPValidationError

/**
 * @summary Search Users
 */
export const useSearchUsers = <TData = Awaited<ReturnType<ReturnType<typeof useSearchUsersHook>>>, TError = HTTPValidationError>(
 queryStr: string,
    params?: SearchUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useSearchUsersHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useSearchUsersQueryOptions(queryStr,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Put Users Algo Settings
 */
export const usePutUsersAlgoSettingsHook = () => {
        const putUsersAlgoSettings = useCustomAxios<unknown>();

        return useCallback((
    id: string,
    algorithmSettings: AlgorithmSettings,
 ) => {
        return putUsersAlgoSettings(
          {url: `/v1/users${id}/algo_settings`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: algorithmSettings
    },
          );
        }, [putUsersAlgoSettings])
      }
    


export const usePutUsersAlgoSettingsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutUsersAlgoSettingsHook>>>, TError,{id: string;data: AlgorithmSettings}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutUsersAlgoSettingsHook>>>, TError,{id: string;data: AlgorithmSettings}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const putUsersAlgoSettings =  usePutUsersAlgoSettingsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePutUsersAlgoSettingsHook>>>, {id: string;data: AlgorithmSettings}> = (props) => {
          const {id,data} = props ?? {};

          return  putUsersAlgoSettings(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutUsersAlgoSettingsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePutUsersAlgoSettingsHook>>>>
    export type PutUsersAlgoSettingsMutationBody = AlgorithmSettings
    export type PutUsersAlgoSettingsMutationError = HTTPValidationError

    /**
 * @summary Put Users Algo Settings
 */
export const usePutUsersAlgoSettings = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutUsersAlgoSettingsHook>>>, TError,{id: string;data: AlgorithmSettings}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePutUsersAlgoSettingsHook>>>,
        TError,
        {id: string;data: AlgorithmSettings},
        TContext
      > => {

      const mutationOptions = usePutUsersAlgoSettingsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get the list of notes in a project
 * @summary Get Projects Id Notes
 */
export const useGetProjectsIdNotesHook = () => {
        const getProjectsIdNotes = useCustomAxios<Note[]>();

        return useCallback((
    slug: string,
 signal?: AbortSignal
) => {
        return getProjectsIdNotes(
          {url: `/v1/projects/${slug}/notes/`, method: 'GET', signal
    },
          );
        }, [getProjectsIdNotes])
      }
    

export const getGetProjectsIdNotesQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}/notes/`] as const;
    }

    
export const useGetProjectsIdNotesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsIdNotesHook>>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdNotesHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsIdNotesQueryKey(slug);

  const getProjectsIdNotes =  useGetProjectsIdNotesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdNotesHook>>>> = ({ signal }) => getProjectsIdNotes(slug, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdNotesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsIdNotesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdNotesHook>>>>
export type GetProjectsIdNotesQueryError = HTTPValidationError

/**
 * @summary Get Projects Id Notes
 */
export const useGetProjectsIdNotes = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsIdNotesHook>>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdNotesHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetProjectsIdNotesQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a note in a project
 * @summary Post Projects Id Notes
 */
export const usePostProjectsIdNotesHook = () => {
        const postProjectsIdNotes = useCustomAxios<Note>();

        return useCallback((
    slug: string,
    note: Note,
 ) => {
        return postProjectsIdNotes(
          {url: `/v1/projects/${slug}/notes/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: note
    },
          );
        }, [postProjectsIdNotes])
      }
    


export const usePostProjectsIdNotesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdNotesHook>>>, TError,{slug: string;data: Note}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdNotesHook>>>, TError,{slug: string;data: Note}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const postProjectsIdNotes =  usePostProjectsIdNotesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdNotesHook>>>, {slug: string;data: Note}> = (props) => {
          const {slug,data} = props ?? {};

          return  postProjectsIdNotes(slug,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsIdNotesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdNotesHook>>>>
    export type PostProjectsIdNotesMutationBody = Note
    export type PostProjectsIdNotesMutationError = HTTPValidationError

    /**
 * @summary Post Projects Id Notes
 */
export const usePostProjectsIdNotes = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsIdNotesHook>>>, TError,{slug: string;data: Note}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePostProjectsIdNotesHook>>>,
        TError,
        {slug: string;data: Note},
        TContext
      > => {

      const mutationOptions = usePostProjectsIdNotesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete a note in a project, this is a soft delete, to fully remove the note, set remove=True below
 * @summary Delete Projects Id Notes Note Id
 */
export const useDeleteProjectsIdNotesNoteIdHook = () => {
        const deleteProjectsIdNotesNoteId = useCustomAxios<void>();

        return useCallback((
    slug: string,
    noteId: string,
 ) => {
        return deleteProjectsIdNotesNoteId(
          {url: `/v1/projects/${slug}/notes/${noteId}/delete`, method: 'DELETE'
    },
          );
        }, [deleteProjectsIdNotesNoteId])
      }
    


export const useDeleteProjectsIdNotesNoteIdMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdNotesNoteIdHook>>>, TError,{slug: string;noteId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdNotesNoteIdHook>>>, TError,{slug: string;noteId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deleteProjectsIdNotesNoteId =  useDeleteProjectsIdNotesNoteIdHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdNotesNoteIdHook>>>, {slug: string;noteId: string}> = (props) => {
          const {slug,noteId} = props ?? {};

          return  deleteProjectsIdNotesNoteId(slug,noteId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectsIdNotesNoteIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdNotesNoteIdHook>>>>
    
    export type DeleteProjectsIdNotesNoteIdMutationError = HTTPValidationError

    /**
 * @summary Delete Projects Id Notes Note Id
 */
export const useDeleteProjectsIdNotesNoteId = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdNotesNoteIdHook>>>, TError,{slug: string;noteId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteProjectsIdNotesNoteIdHook>>>,
        TError,
        {slug: string;noteId: string},
        TContext
      > => {

      const mutationOptions = useDeleteProjectsIdNotesNoteIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Note updates can originate in 2 ways:
1. an implicit put request with a NoteUpdate object with the new values
2. Note was modified directly in the pspdf and comes in as an annotation, detected as "modified" in the dispatcher
 * @summary Put Projects Id Notes Note Id
 */
export const usePutProjectsIdNotesNoteIdHook = () => {
        const putProjectsIdNotesNoteId = useCustomAxios<Note>();

        return useCallback((
    slug: string,
    noteId: string,
    noteUpdate: NoteUpdate,
 ) => {
        return putProjectsIdNotesNoteId(
          {url: `/v1/projects/${slug}/notes/${noteId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: noteUpdate
    },
          );
        }, [putProjectsIdNotesNoteId])
      }
    


export const usePutProjectsIdNotesNoteIdMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutProjectsIdNotesNoteIdHook>>>, TError,{slug: string;noteId: string;data: NoteUpdate}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutProjectsIdNotesNoteIdHook>>>, TError,{slug: string;noteId: string;data: NoteUpdate}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const putProjectsIdNotesNoteId =  usePutProjectsIdNotesNoteIdHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePutProjectsIdNotesNoteIdHook>>>, {slug: string;noteId: string;data: NoteUpdate}> = (props) => {
          const {slug,noteId,data} = props ?? {};

          return  putProjectsIdNotesNoteId(slug,noteId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutProjectsIdNotesNoteIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePutProjectsIdNotesNoteIdHook>>>>
    export type PutProjectsIdNotesNoteIdMutationBody = NoteUpdate
    export type PutProjectsIdNotesNoteIdMutationError = HTTPValidationError

    /**
 * @summary Put Projects Id Notes Note Id
 */
export const usePutProjectsIdNotesNoteId = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutProjectsIdNotesNoteIdHook>>>, TError,{slug: string;noteId: string;data: NoteUpdate}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePutProjectsIdNotesNoteIdHook>>>,
        TError,
        {slug: string;noteId: string;data: NoteUpdate},
        TContext
      > => {

      const mutationOptions = usePutProjectsIdNotesNoteIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Post Projects Slug Automate Chat With Automate New Question
 */
export const usePostProjectsSlugAutomateChatWithAutomateNewQuestionHook = () => {
        const postProjectsSlugAutomateChatWithAutomateNewQuestion = useCustomAxios<Automate>();

        return useCallback((
    slug: string,
    params: PostProjectsSlugAutomateChatWithAutomateNewQuestionParams,
 ) => {
        return postProjectsSlugAutomateChatWithAutomateNewQuestion(
          {url: `/v1/projects/${slug}/automate/chat_with_automate/new_question`, method: 'POST',
        params
    },
          );
        }, [postProjectsSlugAutomateChatWithAutomateNewQuestion])
      }
    


export const usePostProjectsSlugAutomateChatWithAutomateNewQuestionMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsSlugAutomateChatWithAutomateNewQuestionHook>>>, TError,{slug: string;params: PostProjectsSlugAutomateChatWithAutomateNewQuestionParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsSlugAutomateChatWithAutomateNewQuestionHook>>>, TError,{slug: string;params: PostProjectsSlugAutomateChatWithAutomateNewQuestionParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const postProjectsSlugAutomateChatWithAutomateNewQuestion =  usePostProjectsSlugAutomateChatWithAutomateNewQuestionHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostProjectsSlugAutomateChatWithAutomateNewQuestionHook>>>, {slug: string;params: PostProjectsSlugAutomateChatWithAutomateNewQuestionParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  postProjectsSlugAutomateChatWithAutomateNewQuestion(slug,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostProjectsSlugAutomateChatWithAutomateNewQuestionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostProjectsSlugAutomateChatWithAutomateNewQuestionHook>>>>
    
    export type PostProjectsSlugAutomateChatWithAutomateNewQuestionMutationError = HTTPValidationError

    /**
 * @summary Post Projects Slug Automate Chat With Automate New Question
 */
export const usePostProjectsSlugAutomateChatWithAutomateNewQuestion = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostProjectsSlugAutomateChatWithAutomateNewQuestionHook>>>, TError,{slug: string;params: PostProjectsSlugAutomateChatWithAutomateNewQuestionParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePostProjectsSlugAutomateChatWithAutomateNewQuestionHook>>>,
        TError,
        {slug: string;params: PostProjectsSlugAutomateChatWithAutomateNewQuestionParams},
        TContext
      > => {

      const mutationOptions = usePostProjectsSlugAutomateChatWithAutomateNewQuestionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Projects Slug Automate Thread
 */
export const useGetProjectsSlugAutomateThreadHook = () => {
        const getProjectsSlugAutomateThread = useCustomAxios<Automate>();

        return useCallback((
    slug: string,
    params?: GetProjectsSlugAutomateThreadParams,
 signal?: AbortSignal
) => {
        return getProjectsSlugAutomateThread(
          {url: `/v1/projects/${slug}/automate/thread`, method: 'GET',
        params, signal
    },
          );
        }, [getProjectsSlugAutomateThread])
      }
    

export const getGetProjectsSlugAutomateThreadQueryKey = (slug: string,
    params?: GetProjectsSlugAutomateThreadParams,) => {
    return [`/v1/projects/${slug}/automate/thread`, ...(params ? [params]: [])] as const;
    }

    
export const useGetProjectsSlugAutomateThreadQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsSlugAutomateThreadHook>>>, TError = HTTPValidationError>(slug: string,
    params?: GetProjectsSlugAutomateThreadParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsSlugAutomateThreadHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsSlugAutomateThreadQueryKey(slug,params);

  const getProjectsSlugAutomateThread =  useGetProjectsSlugAutomateThreadHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectsSlugAutomateThreadHook>>>> = ({ signal }) => getProjectsSlugAutomateThread(slug,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsSlugAutomateThreadHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsSlugAutomateThreadQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectsSlugAutomateThreadHook>>>>
export type GetProjectsSlugAutomateThreadQueryError = HTTPValidationError

/**
 * @summary Get Projects Slug Automate Thread
 */
export const useGetProjectsSlugAutomateThread = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsSlugAutomateThreadHook>>>, TError = HTTPValidationError>(
 slug: string,
    params?: GetProjectsSlugAutomateThreadParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsSlugAutomateThreadHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetProjectsSlugAutomateThreadQueryOptions(slug,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Projects Slug Automate Latest Thread
 */
export const useGetProjectsSlugAutomateLatestThreadHook = () => {
        const getProjectsSlugAutomateLatestThread = useCustomAxios<Automate>();

        return useCallback((
    slug: string,
 signal?: AbortSignal
) => {
        return getProjectsSlugAutomateLatestThread(
          {url: `/v1/projects/${slug}/automate/latest_thread`, method: 'GET', signal
    },
          );
        }, [getProjectsSlugAutomateLatestThread])
      }
    

export const getGetProjectsSlugAutomateLatestThreadQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}/automate/latest_thread`] as const;
    }

    
export const useGetProjectsSlugAutomateLatestThreadQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsSlugAutomateLatestThreadHook>>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsSlugAutomateLatestThreadHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectsSlugAutomateLatestThreadQueryKey(slug);

  const getProjectsSlugAutomateLatestThread =  useGetProjectsSlugAutomateLatestThreadHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectsSlugAutomateLatestThreadHook>>>> = ({ signal }) => getProjectsSlugAutomateLatestThread(slug, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsSlugAutomateLatestThreadHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectsSlugAutomateLatestThreadQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectsSlugAutomateLatestThreadHook>>>>
export type GetProjectsSlugAutomateLatestThreadQueryError = HTTPValidationError

/**
 * @summary Get Projects Slug Automate Latest Thread
 */
export const useGetProjectsSlugAutomateLatestThread = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsSlugAutomateLatestThreadHook>>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsSlugAutomateLatestThreadHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetProjectsSlugAutomateLatestThreadQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Ask Automate Stream
 */
export const useAskAutomateStreamHook = () => {
        const askAutomateStream = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    params: AskAutomateStreamParams,
 signal?: AbortSignal
) => {
        return askAutomateStream(
          {url: `/v1/projects/${slug}/automate/new_question_stream`, method: 'GET',
        params, signal
    },
          );
        }, [askAutomateStream])
      }
    

export const getAskAutomateStreamQueryKey = (slug: string,
    params: AskAutomateStreamParams,) => {
    return [`/v1/projects/${slug}/automate/new_question_stream`, ...(params ? [params]: [])] as const;
    }

    
export const useAskAutomateStreamQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useAskAutomateStreamHook>>>, TError = HTTPValidationError>(slug: string,
    params: AskAutomateStreamParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAskAutomateStreamHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAskAutomateStreamQueryKey(slug,params);

  const askAutomateStream =  useAskAutomateStreamHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAskAutomateStreamHook>>>> = ({ signal }) => askAutomateStream(slug,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAskAutomateStreamHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type AskAutomateStreamQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAskAutomateStreamHook>>>>
export type AskAutomateStreamQueryError = HTTPValidationError

/**
 * @summary Ask Automate Stream
 */
export const useAskAutomateStream = <TData = Awaited<ReturnType<ReturnType<typeof useAskAutomateStreamHook>>>, TError = HTTPValidationError>(
 slug: string,
    params: AskAutomateStreamParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAskAutomateStreamHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useAskAutomateStreamQueryOptions(slug,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Ask Automate
 */
export const useAskAutomateHook = () => {
        const askAutomate = useCustomAxios<string>();

        return useCallback((
    slug: string,
    params: AskAutomateParams,
 ) => {
        return askAutomate(
          {url: `/v1/projects/${slug}/automate/new_question_wo_stream`, method: 'POST',
        params
    },
          );
        }, [askAutomate])
      }
    


export const useAskAutomateMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAskAutomateHook>>>, TError,{slug: string;params: AskAutomateParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAskAutomateHook>>>, TError,{slug: string;params: AskAutomateParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const askAutomate =  useAskAutomateHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAskAutomateHook>>>, {slug: string;params: AskAutomateParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  askAutomate(slug,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AskAutomateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAskAutomateHook>>>>
    
    export type AskAutomateMutationError = HTTPValidationError

    /**
 * @summary Ask Automate
 */
export const useAskAutomate = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAskAutomateHook>>>, TError,{slug: string;params: AskAutomateParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useAskAutomateHook>>>,
        TError,
        {slug: string;params: AskAutomateParams},
        TContext
      > => {

      const mutationOptions = useAskAutomateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a list of pages metadata objects (all but the actual text json)
 * @summary Get All Pages
 */
export const useGetAllPagesHook = () => {
        const getAllPages = useCustomAxios<PageMetadata[]>();

        return useCallback((
    slug: string,
 signal?: AbortSignal
) => {
        return getAllPages(
          {url: `/v1/projects/${slug}/pages/`, method: 'GET', signal
    },
          );
        }, [getAllPages])
      }
    

export const getGetAllPagesQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}/pages/`] as const;
    }

    
export const useGetAllPagesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllPagesHook>>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPagesHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllPagesQueryKey(slug);

  const getAllPages =  useGetAllPagesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllPagesHook>>>> = ({ signal }) => getAllPages(slug, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPagesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllPagesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllPagesHook>>>>
export type GetAllPagesQueryError = HTTPValidationError

/**
 * @summary Get All Pages
 */
export const useGetAllPages = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllPagesHook>>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPagesHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllPagesQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new page
 * @summary Automate Page
 */
export const useAutomatePageHook = () => {
        const automatePage = useCustomAxios<PageResponse>();

        return useCallback((
    slug: string,
    pageCreate: PageCreate,
 ) => {
        return automatePage(
          {url: `/v1/projects/${slug}/pages/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: pageCreate
    },
          );
        }, [automatePage])
      }
    


export const useAutomatePageMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAutomatePageHook>>>, TError,{slug: string;data: PageCreate}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAutomatePageHook>>>, TError,{slug: string;data: PageCreate}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const automatePage =  useAutomatePageHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAutomatePageHook>>>, {slug: string;data: PageCreate}> = (props) => {
          const {slug,data} = props ?? {};

          return  automatePage(slug,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AutomatePageMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAutomatePageHook>>>>
    export type AutomatePageMutationBody = PageCreate
    export type AutomatePageMutationError = HTTPValidationError

    /**
 * @summary Automate Page
 */
export const useAutomatePage = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAutomatePageHook>>>, TError,{slug: string;data: PageCreate}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useAutomatePageHook>>>,
        TError,
        {slug: string;data: PageCreate},
        TContext
      > => {

      const mutationOptions = useAutomatePageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update a page by page ID (only replace non-null fields)
 * @summary Update Pages
 */
export const useUpdatePagesHook = () => {
        const updatePages = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    pagesUpdate: PagesUpdate[],
 ) => {
        return updatePages(
          {url: `/v1/projects/${slug}/pages/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: pagesUpdate
    },
          );
        }, [updatePages])
      }
    


export const useUpdatePagesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdatePagesHook>>>, TError,{slug: string;data: PagesUpdate[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdatePagesHook>>>, TError,{slug: string;data: PagesUpdate[]}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const updatePages =  useUpdatePagesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdatePagesHook>>>, {slug: string;data: PagesUpdate[]}> = (props) => {
          const {slug,data} = props ?? {};

          return  updatePages(slug,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdatePagesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdatePagesHook>>>>
    export type UpdatePagesMutationBody = PagesUpdate[]
    export type UpdatePagesMutationError = HTTPValidationError

    /**
 * @summary Update Pages
 */
export const useUpdatePages = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdatePagesHook>>>, TError,{slug: string;data: PagesUpdate[]}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdatePagesHook>>>,
        TError,
        {slug: string;data: PagesUpdate[]},
        TContext
      > => {

      const mutationOptions = useUpdatePagesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a full page by page id
 * @summary Get Page By Id
 */
export const useGetPageByIdHook = () => {
        const getPageById = useCustomAxios<PageResponse>();

        return useCallback((
    slug: string,
    pageId: string,
 signal?: AbortSignal
) => {
        return getPageById(
          {url: `/v1/projects/${slug}/pages/${pageId}`, method: 'GET', signal
    },
          );
        }, [getPageById])
      }
    

export const getGetPageByIdQueryKey = (slug: string,
    pageId: string,) => {
    return [`/v1/projects/${slug}/pages/${pageId}`] as const;
    }

    
export const useGetPageByIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPageByIdHook>>>, TError = HTTPValidationError>(slug: string,
    pageId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPageByIdHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPageByIdQueryKey(slug,pageId);

  const getPageById =  useGetPageByIdHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPageByIdHook>>>> = ({ signal }) => getPageById(slug,pageId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && pageId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPageByIdHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPageByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPageByIdHook>>>>
export type GetPageByIdQueryError = HTTPValidationError

/**
 * @summary Get Page By Id
 */
export const useGetPageById = <TData = Awaited<ReturnType<ReturnType<typeof useGetPageByIdHook>>>, TError = HTTPValidationError>(
 slug: string,
    pageId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPageByIdHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetPageByIdQueryOptions(slug,pageId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update a page by page ID (only replace non-null fields)
 * @summary Update Page
 */
export const useUpdatePageHook = () => {
        const updatePage = useCustomAxios<PageResponse>();

        return useCallback((
    slug: string,
    pageId: string,
    pageUpdate: PageUpdate,
 ) => {
        return updatePage(
          {url: `/v1/projects/${slug}/pages/${pageId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: pageUpdate
    },
          );
        }, [updatePage])
      }
    


export const useUpdatePageMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdatePageHook>>>, TError,{slug: string;pageId: string;data: PageUpdate}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdatePageHook>>>, TError,{slug: string;pageId: string;data: PageUpdate}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const updatePage =  useUpdatePageHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdatePageHook>>>, {slug: string;pageId: string;data: PageUpdate}> = (props) => {
          const {slug,pageId,data} = props ?? {};

          return  updatePage(slug,pageId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdatePageMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdatePageHook>>>>
    export type UpdatePageMutationBody = PageUpdate
    export type UpdatePageMutationError = HTTPValidationError

    /**
 * @summary Update Page
 */
export const useUpdatePage = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdatePageHook>>>, TError,{slug: string;pageId: string;data: PageUpdate}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdatePageHook>>>,
        TError,
        {slug: string;pageId: string;data: PageUpdate},
        TContext
      > => {

      const mutationOptions = useUpdatePageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Page
 */
export const useDeletePageHook = () => {
        const deletePage = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    pageId: string,
 ) => {
        return deletePage(
          {url: `/v1/projects/${slug}/pages/${pageId}`, method: 'DELETE'
    },
          );
        }, [deletePage])
      }
    


export const useDeletePageMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePageHook>>>, TError,{slug: string;pageId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePageHook>>>, TError,{slug: string;pageId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deletePage =  useDeletePageHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeletePageHook>>>, {slug: string;pageId: string}> = (props) => {
          const {slug,pageId} = props ?? {};

          return  deletePage(slug,pageId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeletePageMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeletePageHook>>>>
    
    export type DeletePageMutationError = HTTPValidationError

    /**
 * @summary Delete Page
 */
export const useDeletePage = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeletePageHook>>>, TError,{slug: string;pageId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeletePageHook>>>,
        TError,
        {slug: string;pageId: string},
        TContext
      > => {

      const mutationOptions = useDeletePageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Set a page to private or public (is_private: bool)
 * @summary Set Page Is Private
 */
export const useSetPageIsPrivateHook = () => {
        const setPageIsPrivate = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    pageId: string,
    params: SetPageIsPrivateParams,
 ) => {
        return setPageIsPrivate(
          {url: `/v1/projects/${slug}/pages/${pageId}/set_is_private`, method: 'POST',
        params
    },
          );
        }, [setPageIsPrivate])
      }
    


export const useSetPageIsPrivateMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetPageIsPrivateHook>>>, TError,{slug: string;pageId: string;params: SetPageIsPrivateParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetPageIsPrivateHook>>>, TError,{slug: string;pageId: string;params: SetPageIsPrivateParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const setPageIsPrivate =  useSetPageIsPrivateHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSetPageIsPrivateHook>>>, {slug: string;pageId: string;params: SetPageIsPrivateParams}> = (props) => {
          const {slug,pageId,params} = props ?? {};

          return  setPageIsPrivate(slug,pageId,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SetPageIsPrivateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetPageIsPrivateHook>>>>
    
    export type SetPageIsPrivateMutationError = HTTPValidationError

    /**
 * @summary Set Page Is Private
 */
export const useSetPageIsPrivate = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetPageIsPrivateHook>>>, TError,{slug: string;pageId: string;params: SetPageIsPrivateParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useSetPageIsPrivateHook>>>,
        TError,
        {slug: string;pageId: string;params: SetPageIsPrivateParams},
        TContext
      > => {

      const mutationOptions = useSetPageIsPrivateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get All Equipment Pieces
 */
export const useGetAllEquipmentPiecesHook = () => {
        const getAllEquipmentPieces = useCustomAxios<EquipmentPiece[]>();

        return useCallback((
    slug: string,
    documentId: string,
 signal?: AbortSignal
) => {
        return getAllEquipmentPieces(
          {url: `/v1/projects/${slug}/equipment/${documentId}`, method: 'GET', signal
    },
          );
        }, [getAllEquipmentPieces])
      }
    

export const getGetAllEquipmentPiecesQueryKey = (slug: string,
    documentId: string,) => {
    return [`/v1/projects/${slug}/equipment/${documentId}`] as const;
    }

    
export const useGetAllEquipmentPiecesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllEquipmentPiecesHook>>>, TError = HTTPValidationError>(slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllEquipmentPiecesHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllEquipmentPiecesQueryKey(slug,documentId);

  const getAllEquipmentPieces =  useGetAllEquipmentPiecesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllEquipmentPiecesHook>>>> = ({ signal }) => getAllEquipmentPieces(slug,documentId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && documentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllEquipmentPiecesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllEquipmentPiecesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllEquipmentPiecesHook>>>>
export type GetAllEquipmentPiecesQueryError = HTTPValidationError

/**
 * @summary Get All Equipment Pieces
 */
export const useGetAllEquipmentPieces = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllEquipmentPiecesHook>>>, TError = HTTPValidationError>(
 slug: string,
    documentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllEquipmentPiecesHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllEquipmentPiecesQueryOptions(slug,documentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Delete equipment piece by id and all its associated annotations
 * @summary Delete Equipment Piece
 */
export const useDeleteEquipmentPieceHook = () => {
        const deleteEquipmentPiece = useCustomAxios<Document>();

        return useCallback((
    slug: string,
    documentId: string,
    params: DeleteEquipmentPieceParams,
 ) => {
        return deleteEquipmentPiece(
          {url: `/v1/projects/${slug}/equipment/${documentId}`, method: 'DELETE',
        params
    },
          );
        }, [deleteEquipmentPiece])
      }
    


export const useDeleteEquipmentPieceMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteEquipmentPieceHook>>>, TError,{slug: string;documentId: string;params: DeleteEquipmentPieceParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteEquipmentPieceHook>>>, TError,{slug: string;documentId: string;params: DeleteEquipmentPieceParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deleteEquipmentPiece =  useDeleteEquipmentPieceHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteEquipmentPieceHook>>>, {slug: string;documentId: string;params: DeleteEquipmentPieceParams}> = (props) => {
          const {slug,documentId,params} = props ?? {};

          return  deleteEquipmentPiece(slug,documentId,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteEquipmentPieceMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteEquipmentPieceHook>>>>
    
    export type DeleteEquipmentPieceMutationError = HTTPValidationError

    /**
 * @summary Delete Equipment Piece
 */
export const useDeleteEquipmentPiece = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteEquipmentPieceHook>>>, TError,{slug: string;documentId: string;params: DeleteEquipmentPieceParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteEquipmentPieceHook>>>,
        TError,
        {slug: string;documentId: string;params: DeleteEquipmentPieceParams},
        TContext
      > => {

      const mutationOptions = useDeleteEquipmentPieceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Batch delete equipment pieces by id and all their associated annotations
 * @summary Batch Delete Equipment Piece
 */
export const useBatchDeleteEquipmentPieceHook = () => {
        const batchDeleteEquipmentPiece = useCustomAxios<Document>();

        return useCallback((
    slug: string,
    documentId: string,
    batchDeleteEquipmentPieceBody: string[],
 ) => {
        return batchDeleteEquipmentPiece(
          {url: `/v1/projects/${slug}/equipment/${documentId}/batch`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: batchDeleteEquipmentPieceBody
    },
          );
        }, [batchDeleteEquipmentPiece])
      }
    


export const useBatchDeleteEquipmentPieceMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBatchDeleteEquipmentPieceHook>>>, TError,{slug: string;documentId: string;data: string[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBatchDeleteEquipmentPieceHook>>>, TError,{slug: string;documentId: string;data: string[]}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const batchDeleteEquipmentPiece =  useBatchDeleteEquipmentPieceHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useBatchDeleteEquipmentPieceHook>>>, {slug: string;documentId: string;data: string[]}> = (props) => {
          const {slug,documentId,data} = props ?? {};

          return  batchDeleteEquipmentPiece(slug,documentId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type BatchDeleteEquipmentPieceMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useBatchDeleteEquipmentPieceHook>>>>
    export type BatchDeleteEquipmentPieceMutationBody = string[]
    export type BatchDeleteEquipmentPieceMutationError = HTTPValidationError

    /**
 * @summary Batch Delete Equipment Piece
 */
export const useBatchDeleteEquipmentPiece = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBatchDeleteEquipmentPieceHook>>>, TError,{slug: string;documentId: string;data: string[]}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useBatchDeleteEquipmentPieceHook>>>,
        TError,
        {slug: string;documentId: string;data: string[]},
        TContext
      > => {

      const mutationOptions = useBatchDeleteEquipmentPieceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update equipment price or description by ID, note equipment_price_item atm is unique per organization (not project or user)
 * @summary Update Equipment Price
 */
export const useUpdateEquipmentPriceHook = () => {
        const updateEquipmentPrice = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    equipmentId: string,
    equipmentPiecePriceUpdate: EquipmentPiecePriceUpdate,
    params: UpdateEquipmentPriceParams,
 ) => {
        return updateEquipmentPrice(
          {url: `/v1/projects/${slug}/equipment/catalog/${equipmentId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: equipmentPiecePriceUpdate,
        params
    },
          );
        }, [updateEquipmentPrice])
      }
    


export const useUpdateEquipmentPriceMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEquipmentPriceHook>>>, TError,{slug: string;equipmentId: string;data: EquipmentPiecePriceUpdate;params: UpdateEquipmentPriceParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEquipmentPriceHook>>>, TError,{slug: string;equipmentId: string;data: EquipmentPiecePriceUpdate;params: UpdateEquipmentPriceParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const updateEquipmentPrice =  useUpdateEquipmentPriceHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateEquipmentPriceHook>>>, {slug: string;equipmentId: string;data: EquipmentPiecePriceUpdate;params: UpdateEquipmentPriceParams}> = (props) => {
          const {slug,equipmentId,data,params} = props ?? {};

          return  updateEquipmentPrice(slug,equipmentId,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateEquipmentPriceMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateEquipmentPriceHook>>>>
    export type UpdateEquipmentPriceMutationBody = EquipmentPiecePriceUpdate
    export type UpdateEquipmentPriceMutationError = HTTPValidationError

    /**
 * @summary Update Equipment Price
 */
export const useUpdateEquipmentPrice = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEquipmentPriceHook>>>, TError,{slug: string;equipmentId: string;data: EquipmentPiecePriceUpdate;params: UpdateEquipmentPriceParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateEquipmentPriceHook>>>,
        TError,
        {slug: string;equipmentId: string;data: EquipmentPiecePriceUpdate;params: UpdateEquipmentPriceParams},
        TContext
      > => {

      const mutationOptions = useUpdateEquipmentPriceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Pattern-match bbox against pdf page and add to the equipment list
Accepts a rectangle annotation, creates an empty_equipment_item (isReady=False) and adds it to the equipment list
Dispatch request the computer vision service to pattern-match the equipment item bbox in the pdf page and update the equipment item
(also appends the annotation to the document annotations list)
 * @summary Auto Detect Equipment
 */
export const useAutoDetectEquipmentHook = () => {
        const autoDetectEquipment = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    documentEquipmentAnnotation: DocumentEquipmentAnnotation,
    params: AutoDetectEquipmentParams,
 ) => {
        return autoDetectEquipment(
          {url: `/v1/projects/${slug}/equipment/auto_detect_equipment`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: documentEquipmentAnnotation,
        params
    },
          );
        }, [autoDetectEquipment])
      }
    


export const useAutoDetectEquipmentMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAutoDetectEquipmentHook>>>, TError,{slug: string;data: DocumentEquipmentAnnotation;params: AutoDetectEquipmentParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAutoDetectEquipmentHook>>>, TError,{slug: string;data: DocumentEquipmentAnnotation;params: AutoDetectEquipmentParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const autoDetectEquipment =  useAutoDetectEquipmentHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAutoDetectEquipmentHook>>>, {slug: string;data: DocumentEquipmentAnnotation;params: AutoDetectEquipmentParams}> = (props) => {
          const {slug,data,params} = props ?? {};

          return  autoDetectEquipment(slug,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AutoDetectEquipmentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAutoDetectEquipmentHook>>>>
    export type AutoDetectEquipmentMutationBody = DocumentEquipmentAnnotation
    export type AutoDetectEquipmentMutationError = HTTPValidationError

    /**
 * @summary Auto Detect Equipment
 */
export const useAutoDetectEquipment = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAutoDetectEquipmentHook>>>, TError,{slug: string;data: DocumentEquipmentAnnotation;params: AutoDetectEquipmentParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useAutoDetectEquipmentHook>>>,
        TError,
        {slug: string;data: DocumentEquipmentAnnotation;params: AutoDetectEquipmentParams},
        TContext
      > => {

      const mutationOptions = useAutoDetectEquipmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Dispatch request to the computer vision service to classify the equipment item
 * @summary Classify Equipment
 */
export const useClassifyEquipmentHook = () => {
        const classifyEquipment = useCustomAxios<EquipmentPiece>();

        return useCallback((
    slug: string,
    documentId: string,
    equipmentId: string,
 signal?: AbortSignal
) => {
        return classifyEquipment(
          {url: `/v1/projects/${slug}/equipment/${documentId}/${equipmentId}/classify`, method: 'GET', signal
    },
          );
        }, [classifyEquipment])
      }
    

export const getClassifyEquipmentQueryKey = (slug: string,
    documentId: string,
    equipmentId: string,) => {
    return [`/v1/projects/${slug}/equipment/${documentId}/${equipmentId}/classify`] as const;
    }

    
export const useClassifyEquipmentQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useClassifyEquipmentHook>>>, TError = HTTPValidationError>(slug: string,
    documentId: string,
    equipmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useClassifyEquipmentHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getClassifyEquipmentQueryKey(slug,documentId,equipmentId);

  const classifyEquipment =  useClassifyEquipmentHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useClassifyEquipmentHook>>>> = ({ signal }) => classifyEquipment(slug,documentId,equipmentId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug && documentId && equipmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useClassifyEquipmentHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type ClassifyEquipmentQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useClassifyEquipmentHook>>>>
export type ClassifyEquipmentQueryError = HTTPValidationError

/**
 * @summary Classify Equipment
 */
export const useClassifyEquipment = <TData = Awaited<ReturnType<ReturnType<typeof useClassifyEquipmentHook>>>, TError = HTTPValidationError>(
 slug: string,
    documentId: string,
    equipmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useClassifyEquipmentHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useClassifyEquipmentQueryOptions(slug,documentId,equipmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Feedback
 */
export const useCreateFeedbackHook = () => {
        const createFeedback = useCustomAxios<FeedBack>();

        return useCallback((
    feedBack: FeedBack,
 ) => {
        return createFeedback(
          {url: `/v1/feedback/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: feedBack
    },
          );
        }, [createFeedback])
      }
    


export const useCreateFeedbackMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateFeedbackHook>>>, TError,{data: FeedBack}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateFeedbackHook>>>, TError,{data: FeedBack}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const createFeedback =  useCreateFeedbackHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateFeedbackHook>>>, {data: FeedBack}> = (props) => {
          const {data} = props ?? {};

          return  createFeedback(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateFeedbackMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateFeedbackHook>>>>
    export type CreateFeedbackMutationBody = FeedBack
    export type CreateFeedbackMutationError = HTTPValidationError

    /**
 * @summary Create Feedback
 */
export const useCreateFeedback = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateFeedbackHook>>>, TError,{data: FeedBack}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useCreateFeedbackHook>>>,
        TError,
        {data: FeedBack},
        TContext
      > => {

      const mutationOptions = useCreateFeedbackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get all organizations in the tenant
 * @summary Get Organizations
 */
export const useGetOrganizationsHook = () => {
        const getOrganizations = useCustomAxios<Organization[]>();

        return useCallback((
    
 signal?: AbortSignal
) => {
        return getOrganizations(
          {url: `/v1/organizations/`, method: 'GET', signal
    },
          );
        }, [getOrganizations])
      }
    

export const getGetOrganizationsQueryKey = () => {
    return [`/v1/organizations/`] as const;
    }

    
export const useGetOrganizationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationsHook>>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationsHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationsQueryKey();

  const getOrganizations =  useGetOrganizationsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrganizationsHook>>>> = ({ signal }) => getOrganizations(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrganizationsHook>>>>
export type GetOrganizationsQueryError = unknown

/**
 * @summary Get Organizations
 */
export const useGetOrganizations = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationsHook>>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationsHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrganizationsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Organization
 */
export const useCreateOrganizationHook = () => {
        const createOrganization = useCustomAxios<Organization>();

        return useCallback((
    organization: Organization,
 ) => {
        return createOrganization(
          {url: `/v1/organizations/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: organization
    },
          );
        }, [createOrganization])
      }
    


export const useCreateOrganizationMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationHook>>>, TError,{data: Organization}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationHook>>>, TError,{data: Organization}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const createOrganization =  useCreateOrganizationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationHook>>>, {data: Organization}> = (props) => {
          const {data} = props ?? {};

          return  createOrganization(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateOrganizationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationHook>>>>
    export type CreateOrganizationMutationBody = Organization
    export type CreateOrganizationMutationError = HTTPValidationError

    /**
 * @summary Create Organization
 */
export const useCreateOrganization = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationHook>>>, TError,{data: Organization}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useCreateOrganizationHook>>>,
        TError,
        {data: Organization},
        TContext
      > => {

      const mutationOptions = useCreateOrganizationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a mapping of org_id to organization name (for all organizations in the tenant)
 * @summary Get Organization Mapping
 */
export const useGetOrganizationMappingHook = () => {
        const getOrganizationMapping = useCustomAxios<GetOrganizationMapping200>();

        return useCallback((
    
 signal?: AbortSignal
) => {
        return getOrganizationMapping(
          {url: `/v1/organizations/mapping`, method: 'GET', signal
    },
          );
        }, [getOrganizationMapping])
      }
    

export const getGetOrganizationMappingQueryKey = () => {
    return [`/v1/organizations/mapping`] as const;
    }

    
export const useGetOrganizationMappingQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationMappingHook>>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationMappingHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationMappingQueryKey();

  const getOrganizationMapping =  useGetOrganizationMappingHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrganizationMappingHook>>>> = ({ signal }) => getOrganizationMapping(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationMappingHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationMappingQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrganizationMappingHook>>>>
export type GetOrganizationMappingQueryError = unknown

/**
 * @summary Get Organization Mapping
 */
export const useGetOrganizationMapping = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationMappingHook>>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationMappingHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrganizationMappingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get all tags of an organization
 * @summary Get Organization Tags Org Id
 */
export const useGetOrganizationTagsOrgIdHook = () => {
        const getOrganizationTagsOrgId = useCustomAxios<Tag[]>();

        return useCallback((
    orgId: string,
    params?: GetOrganizationTagsOrgIdParams,
 signal?: AbortSignal
) => {
        return getOrganizationTagsOrgId(
          {url: `/v1/organizations/${orgId}/tags`, method: 'GET',
        params, signal
    },
          );
        }, [getOrganizationTagsOrgId])
      }
    

export const getGetOrganizationTagsOrgIdQueryKey = (orgId: string,
    params?: GetOrganizationTagsOrgIdParams,) => {
    return [`/v1/organizations/${orgId}/tags`, ...(params ? [params]: [])] as const;
    }

    
export const useGetOrganizationTagsOrgIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationTagsOrgIdHook>>>, TError = HTTPValidationError>(orgId: string,
    params?: GetOrganizationTagsOrgIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationTagsOrgIdHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationTagsOrgIdQueryKey(orgId,params);

  const getOrganizationTagsOrgId =  useGetOrganizationTagsOrgIdHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrganizationTagsOrgIdHook>>>> = ({ signal }) => getOrganizationTagsOrgId(orgId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationTagsOrgIdHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationTagsOrgIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrganizationTagsOrgIdHook>>>>
export type GetOrganizationTagsOrgIdQueryError = HTTPValidationError

/**
 * @summary Get Organization Tags Org Id
 */
export const useGetOrganizationTagsOrgId = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationTagsOrgIdHook>>>, TError = HTTPValidationError>(
 orgId: string,
    params?: GetOrganizationTagsOrgIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationTagsOrgIdHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrganizationTagsOrgIdQueryOptions(orgId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new tag in an organization - only if it does not exist already (unique by name)
 * @summary Create Organization Tag
 */
export const useCreateOrganizationTagHook = () => {
        const createOrganizationTag = useCustomAxios<CreateOrganizationTag201>();

        return useCallback((
    orgId: string,
    tag: Tag,
 ) => {
        return createOrganizationTag(
          {url: `/v1/organizations/${orgId}/tags`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: tag
    },
          );
        }, [createOrganizationTag])
      }
    


export const useCreateOrganizationTagMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationTagHook>>>, TError,{orgId: string;data: Tag}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationTagHook>>>, TError,{orgId: string;data: Tag}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const createOrganizationTag =  useCreateOrganizationTagHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationTagHook>>>, {orgId: string;data: Tag}> = (props) => {
          const {orgId,data} = props ?? {};

          return  createOrganizationTag(orgId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateOrganizationTagMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationTagHook>>>>
    export type CreateOrganizationTagMutationBody = Tag
    export type CreateOrganizationTagMutationError = HTTPValidationError

    /**
 * @summary Create Organization Tag
 */
export const useCreateOrganizationTag = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationTagHook>>>, TError,{orgId: string;data: Tag}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useCreateOrganizationTagHook>>>,
        TError,
        {orgId: string;data: Tag},
        TContext
      > => {

      const mutationOptions = useCreateOrganizationTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update a specific tag of an organization by its name (replace it with the new one provided in the request body)
 * @summary Update Organization Tag
 */
export const useUpdateOrganizationTagHook = () => {
        const updateOrganizationTag = useCustomAxios<unknown>();

        return useCallback((
    orgId: string,
    tagName: string,
    tag: Tag,
 ) => {
        return updateOrganizationTag(
          {url: `/v1/organizations/${orgId}/tags/${tagName}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: tag
    },
          );
        }, [updateOrganizationTag])
      }
    


export const useUpdateOrganizationTagMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationTagHook>>>, TError,{orgId: string;tagName: string;data: Tag}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationTagHook>>>, TError,{orgId: string;tagName: string;data: Tag}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const updateOrganizationTag =  useUpdateOrganizationTagHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationTagHook>>>, {orgId: string;tagName: string;data: Tag}> = (props) => {
          const {orgId,tagName,data} = props ?? {};

          return  updateOrganizationTag(orgId,tagName,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateOrganizationTagMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationTagHook>>>>
    export type UpdateOrganizationTagMutationBody = Tag
    export type UpdateOrganizationTagMutationError = HTTPValidationError

    /**
 * @summary Update Organization Tag
 */
export const useUpdateOrganizationTag = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationTagHook>>>, TError,{orgId: string;tagName: string;data: Tag}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationTagHook>>>,
        TError,
        {orgId: string;tagName: string;data: Tag},
        TContext
      > => {

      const mutationOptions = useUpdateOrganizationTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get the quota status for an organization (at the moment - limiting number of projects per month)
 * @summary Get Org Quota Status
 */
export const useGetOrgQuotaStatusHook = () => {
        const getOrgQuotaStatus = useCustomAxios<unknown>();

        return useCallback((
    params: GetOrgQuotaStatusParams,
 signal?: AbortSignal
) => {
        return getOrgQuotaStatus(
          {url: `/v1/organizations/quota/status`, method: 'GET',
        params, signal
    },
          );
        }, [getOrgQuotaStatus])
      }
    

export const getGetOrgQuotaStatusQueryKey = (params: GetOrgQuotaStatusParams,) => {
    return [`/v1/organizations/quota/status`, ...(params ? [params]: [])] as const;
    }

    
export const useGetOrgQuotaStatusQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrgQuotaStatusHook>>>, TError = HTTPValidationError>(params: GetOrgQuotaStatusParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrgQuotaStatusHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrgQuotaStatusQueryKey(params);

  const getOrgQuotaStatus =  useGetOrgQuotaStatusHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrgQuotaStatusHook>>>> = ({ signal }) => getOrgQuotaStatus(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrgQuotaStatusHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrgQuotaStatusQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrgQuotaStatusHook>>>>
export type GetOrgQuotaStatusQueryError = HTTPValidationError

/**
 * @summary Get Org Quota Status
 */
export const useGetOrgQuotaStatus = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrgQuotaStatusHook>>>, TError = HTTPValidationError>(
 params: GetOrgQuotaStatusParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrgQuotaStatusHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrgQuotaStatusQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update/Create org quota
 * @summary Update Org Quota
 */
export const useUpdateOrgQuotaHook = () => {
        const updateOrgQuota = useCustomAxios<unknown>();

        return useCallback((
    quota: Quota,
    params: UpdateOrgQuotaParams,
 ) => {
        return updateOrgQuota(
          {url: `/v1/organizations/quota`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: quota,
        params
    },
          );
        }, [updateOrgQuota])
      }
    


export const useUpdateOrgQuotaMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrgQuotaHook>>>, TError,{data: Quota;params: UpdateOrgQuotaParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrgQuotaHook>>>, TError,{data: Quota;params: UpdateOrgQuotaParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const updateOrgQuota =  useUpdateOrgQuotaHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateOrgQuotaHook>>>, {data: Quota;params: UpdateOrgQuotaParams}> = (props) => {
          const {data,params} = props ?? {};

          return  updateOrgQuota(data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateOrgQuotaMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateOrgQuotaHook>>>>
    export type UpdateOrgQuotaMutationBody = Quota
    export type UpdateOrgQuotaMutationError = HTTPValidationError

    /**
 * @summary Update Org Quota
 */
export const useUpdateOrgQuota = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrgQuotaHook>>>, TError,{data: Quota;params: UpdateOrgQuotaParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateOrgQuotaHook>>>,
        TError,
        {data: Quota;params: UpdateOrgQuotaParams},
        TContext
      > => {

      const mutationOptions = useUpdateOrgQuotaMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get all checklists of an organization
 * @summary Get Organization Checklists Org Id
 */
export const useGetOrganizationChecklistsOrgIdHook = () => {
        const getOrganizationChecklistsOrgId = useCustomAxios<Checklist[]>();

        return useCallback((
    orgId: string,
 signal?: AbortSignal
) => {
        return getOrganizationChecklistsOrgId(
          {url: `/v1/organizations/${orgId}/checklists/`, method: 'GET', signal
    },
          );
        }, [getOrganizationChecklistsOrgId])
      }
    

export const getGetOrganizationChecklistsOrgIdQueryKey = (orgId: string,) => {
    return [`/v1/organizations/${orgId}/checklists/`] as const;
    }

    
export const useGetOrganizationChecklistsOrgIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationChecklistsOrgIdHook>>>, TError = HTTPValidationError>(orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationChecklistsOrgIdHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationChecklistsOrgIdQueryKey(orgId);

  const getOrganizationChecklistsOrgId =  useGetOrganizationChecklistsOrgIdHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrganizationChecklistsOrgIdHook>>>> = ({ signal }) => getOrganizationChecklistsOrgId(orgId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationChecklistsOrgIdHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationChecklistsOrgIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrganizationChecklistsOrgIdHook>>>>
export type GetOrganizationChecklistsOrgIdQueryError = HTTPValidationError

/**
 * @summary Get Organization Checklists Org Id
 */
export const useGetOrganizationChecklistsOrgId = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationChecklistsOrgIdHook>>>, TError = HTTPValidationError>(
 orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationChecklistsOrgIdHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrganizationChecklistsOrgIdQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new checklist in an organization - only if it does not exist already (unique by id)
 * @summary Create Organization Checklist
 */
export const useCreateOrganizationChecklistHook = () => {
        const createOrganizationChecklist = useCustomAxios<Checklist>();

        return useCallback((
    orgId: string,
    checklist: Checklist,
 ) => {
        return createOrganizationChecklist(
          {url: `/v1/organizations/${orgId}/checklists/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: checklist
    },
          );
        }, [createOrganizationChecklist])
      }
    


export const useCreateOrganizationChecklistMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationChecklistHook>>>, TError,{orgId: string;data: Checklist}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationChecklistHook>>>, TError,{orgId: string;data: Checklist}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const createOrganizationChecklist =  useCreateOrganizationChecklistHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationChecklistHook>>>, {orgId: string;data: Checklist}> = (props) => {
          const {orgId,data} = props ?? {};

          return  createOrganizationChecklist(orgId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateOrganizationChecklistMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationChecklistHook>>>>
    export type CreateOrganizationChecklistMutationBody = Checklist
    export type CreateOrganizationChecklistMutationError = HTTPValidationError

    /**
 * @summary Create Organization Checklist
 */
export const useCreateOrganizationChecklist = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationChecklistHook>>>, TError,{orgId: string;data: Checklist}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useCreateOrganizationChecklistHook>>>,
        TError,
        {orgId: string;data: Checklist},
        TContext
      > => {

      const mutationOptions = useCreateOrganizationChecklistMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a specific checklist of an organization by id
 * @summary Get Organization Checklists
 */
export const useGetOrganizationChecklistsHook = () => {
        const getOrganizationChecklists = useCustomAxios<Checklist>();

        return useCallback((
    orgId: string,
    checklistId: string,
 signal?: AbortSignal
) => {
        return getOrganizationChecklists(
          {url: `/v1/organizations/${orgId}/checklists/id/${checklistId}`, method: 'GET', signal
    },
          );
        }, [getOrganizationChecklists])
      }
    

export const getGetOrganizationChecklistsQueryKey = (orgId: string,
    checklistId: string,) => {
    return [`/v1/organizations/${orgId}/checklists/id/${checklistId}`] as const;
    }

    
export const useGetOrganizationChecklistsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationChecklistsHook>>>, TError = HTTPValidationError>(orgId: string,
    checklistId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationChecklistsHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationChecklistsQueryKey(orgId,checklistId);

  const getOrganizationChecklists =  useGetOrganizationChecklistsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrganizationChecklistsHook>>>> = ({ signal }) => getOrganizationChecklists(orgId,checklistId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId && checklistId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationChecklistsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationChecklistsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrganizationChecklistsHook>>>>
export type GetOrganizationChecklistsQueryError = HTTPValidationError

/**
 * @summary Get Organization Checklists
 */
export const useGetOrganizationChecklists = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationChecklistsHook>>>, TError = HTTPValidationError>(
 orgId: string,
    checklistId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationChecklistsHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrganizationChecklistsQueryOptions(orgId,checklistId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update a specific checklist of an organization by id (replace it with the new one provided in the request body)
 * @summary Update Organization Checklists
 */
export const useUpdateOrganizationChecklistsHook = () => {
        const updateOrganizationChecklists = useCustomAxios<unknown>();

        return useCallback((
    orgId: string,
    checklistId: string,
    checklist: Checklist,
 ) => {
        return updateOrganizationChecklists(
          {url: `/v1/organizations/${orgId}/checklists/id/${checklistId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: checklist
    },
          );
        }, [updateOrganizationChecklists])
      }
    


export const useUpdateOrganizationChecklistsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationChecklistsHook>>>, TError,{orgId: string;checklistId: string;data: Checklist}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationChecklistsHook>>>, TError,{orgId: string;checklistId: string;data: Checklist}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const updateOrganizationChecklists =  useUpdateOrganizationChecklistsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationChecklistsHook>>>, {orgId: string;checklistId: string;data: Checklist}> = (props) => {
          const {orgId,checklistId,data} = props ?? {};

          return  updateOrganizationChecklists(orgId,checklistId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateOrganizationChecklistsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationChecklistsHook>>>>
    export type UpdateOrganizationChecklistsMutationBody = Checklist
    export type UpdateOrganizationChecklistsMutationError = HTTPValidationError

    /**
 * @summary Update Organization Checklists
 */
export const useUpdateOrganizationChecklists = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationChecklistsHook>>>, TError,{orgId: string;checklistId: string;data: Checklist}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationChecklistsHook>>>,
        TError,
        {orgId: string;checklistId: string;data: Checklist},
        TContext
      > => {

      const mutationOptions = useUpdateOrganizationChecklistsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete a specific checklist of an organization by its id
 * @summary Delete Organization Checklists
 */
export const useDeleteOrganizationChecklistsHook = () => {
        const deleteOrganizationChecklists = useCustomAxios<unknown>();

        return useCallback((
    orgId: string,
    checklistId: string,
 ) => {
        return deleteOrganizationChecklists(
          {url: `/v1/organizations/${orgId}/checklists/id/${checklistId}`, method: 'DELETE'
    },
          );
        }, [deleteOrganizationChecklists])
      }
    


export const useDeleteOrganizationChecklistsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationChecklistsHook>>>, TError,{orgId: string;checklistId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationChecklistsHook>>>, TError,{orgId: string;checklistId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deleteOrganizationChecklists =  useDeleteOrganizationChecklistsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationChecklistsHook>>>, {orgId: string;checklistId: string}> = (props) => {
          const {orgId,checklistId} = props ?? {};

          return  deleteOrganizationChecklists(orgId,checklistId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteOrganizationChecklistsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationChecklistsHook>>>>
    
    export type DeleteOrganizationChecklistsMutationError = HTTPValidationError

    /**
 * @summary Delete Organization Checklists
 */
export const useDeleteOrganizationChecklists = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationChecklistsHook>>>, TError,{orgId: string;checklistId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationChecklistsHook>>>,
        TError,
        {orgId: string;checklistId: string},
        TContext
      > => {

      const mutationOptions = useDeleteOrganizationChecklistsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Add a question to a specific checklist of an organization by id (only if it does not exist already in the checklist, unique by question str)
 * @summary Post Organization Checklists Question
 */
export const usePostOrganizationChecklistsQuestionHook = () => {
        const postOrganizationChecklistsQuestion = useCustomAxios<unknown>();

        return useCallback((
    orgId: string,
    checklistId: string,
    queryQuestion: QueryQuestion,
 ) => {
        return postOrganizationChecklistsQuestion(
          {url: `/v1/organizations/${orgId}/checklists/id/${checklistId}/question`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: queryQuestion
    },
          );
        }, [postOrganizationChecklistsQuestion])
      }
    


export const usePostOrganizationChecklistsQuestionMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrganizationChecklistsQuestionHook>>>, TError,{orgId: string;checklistId: string;data: QueryQuestion}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrganizationChecklistsQuestionHook>>>, TError,{orgId: string;checklistId: string;data: QueryQuestion}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const postOrganizationChecklistsQuestion =  usePostOrganizationChecklistsQuestionHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOrganizationChecklistsQuestionHook>>>, {orgId: string;checklistId: string;data: QueryQuestion}> = (props) => {
          const {orgId,checklistId,data} = props ?? {};

          return  postOrganizationChecklistsQuestion(orgId,checklistId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostOrganizationChecklistsQuestionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOrganizationChecklistsQuestionHook>>>>
    export type PostOrganizationChecklistsQuestionMutationBody = QueryQuestion
    export type PostOrganizationChecklistsQuestionMutationError = HTTPValidationError

    /**
 * @summary Post Organization Checklists Question
 */
export const usePostOrganizationChecklistsQuestion = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrganizationChecklistsQuestionHook>>>, TError,{orgId: string;checklistId: string;data: QueryQuestion}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePostOrganizationChecklistsQuestionHook>>>,
        TError,
        {orgId: string;checklistId: string;data: QueryQuestion},
        TContext
      > => {

      const mutationOptions = usePostOrganizationChecklistsQuestionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Remove a question from a specific checklist of an organization - by question str
 * @summary Delete Organization Checklists By Id Org Id Checklist Id Question
 */
export const useDeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionHook = () => {
        const deleteOrganizationChecklistsByIdOrgIdChecklistIdQuestion = useCustomAxios<unknown>();

        return useCallback((
    orgId: string,
    checklistId: string,
    queryQuestion: QueryQuestion,
 ) => {
        return deleteOrganizationChecklistsByIdOrgIdChecklistIdQuestion(
          {url: `/v1/organizations/${orgId}/checklists/id/${checklistId}/question`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: queryQuestion
    },
          );
        }, [deleteOrganizationChecklistsByIdOrgIdChecklistIdQuestion])
      }
    


export const useDeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionHook>>>, TError,{orgId: string;checklistId: string;data: QueryQuestion}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionHook>>>, TError,{orgId: string;checklistId: string;data: QueryQuestion}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deleteOrganizationChecklistsByIdOrgIdChecklistIdQuestion =  useDeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionHook>>>, {orgId: string;checklistId: string;data: QueryQuestion}> = (props) => {
          const {orgId,checklistId,data} = props ?? {};

          return  deleteOrganizationChecklistsByIdOrgIdChecklistIdQuestion(orgId,checklistId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionHook>>>>
    export type DeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionMutationBody = QueryQuestion
    export type DeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionMutationError = HTTPValidationError

    /**
 * @summary Delete Organization Checklists By Id Org Id Checklist Id Question
 */
export const useDeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestion = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionHook>>>, TError,{orgId: string;checklistId: string;data: QueryQuestion}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionHook>>>,
        TError,
        {orgId: string;checklistId: string;data: QueryQuestion},
        TContext
      > => {

      const mutationOptions = useDeleteOrganizationChecklistsByIdOrgIdChecklistIdQuestionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Add a question to the default checklist of a user
 * @summary Post Organization Checklists Org Id Default Checklist
 */
export const usePostOrganizationChecklistsOrgIdDefaultChecklistHook = () => {
        const postOrganizationChecklistsOrgIdDefaultChecklist = useCustomAxios<unknown>();

        return useCallback((
    orgId: string,
    queryQuestion: QueryQuestion,
 ) => {
        return postOrganizationChecklistsOrgIdDefaultChecklist(
          {url: `/v1/organizations/${orgId}/checklists/add_to_default`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: queryQuestion
    },
          );
        }, [postOrganizationChecklistsOrgIdDefaultChecklist])
      }
    


export const usePostOrganizationChecklistsOrgIdDefaultChecklistMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrganizationChecklistsOrgIdDefaultChecklistHook>>>, TError,{orgId: string;data: QueryQuestion}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrganizationChecklistsOrgIdDefaultChecklistHook>>>, TError,{orgId: string;data: QueryQuestion}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const postOrganizationChecklistsOrgIdDefaultChecklist =  usePostOrganizationChecklistsOrgIdDefaultChecklistHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOrganizationChecklistsOrgIdDefaultChecklistHook>>>, {orgId: string;data: QueryQuestion}> = (props) => {
          const {orgId,data} = props ?? {};

          return  postOrganizationChecklistsOrgIdDefaultChecklist(orgId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostOrganizationChecklistsOrgIdDefaultChecklistMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOrganizationChecklistsOrgIdDefaultChecklistHook>>>>
    export type PostOrganizationChecklistsOrgIdDefaultChecklistMutationBody = QueryQuestion
    export type PostOrganizationChecklistsOrgIdDefaultChecklistMutationError = HTTPValidationError

    /**
 * @summary Post Organization Checklists Org Id Default Checklist
 */
export const usePostOrganizationChecklistsOrgIdDefaultChecklist = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrganizationChecklistsOrgIdDefaultChecklistHook>>>, TError,{orgId: string;data: QueryQuestion}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof usePostOrganizationChecklistsOrgIdDefaultChecklistHook>>>,
        TError,
        {orgId: string;data: QueryQuestion},
        TContext
      > => {

      const mutationOptions = usePostOrganizationChecklistsOrgIdDefaultChecklistMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Upload a checklist from a csv file
 * @summary Upload Checklist
 */
export const useUploadChecklistHook = () => {
        const uploadChecklist = useCustomAxios<unknown>();

        return useCallback((
    orgId: string,
    force: boolean,
    bodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost: BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost,
 ) => {const formData = new FormData();
formData.append('file', bodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost.file)

        return uploadChecklist(
          {url: `/v1/organizations/${orgId}/checklists/upload/${force}`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }, [uploadChecklist])
      }
    


export const useUploadChecklistMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadChecklistHook>>>, TError,{orgId: string;force: boolean;data: BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadChecklistHook>>>, TError,{orgId: string;force: boolean;data: BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const uploadChecklist =  useUploadChecklistHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUploadChecklistHook>>>, {orgId: string;force: boolean;data: BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost}> = (props) => {
          const {orgId,force,data} = props ?? {};

          return  uploadChecklist(orgId,force,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UploadChecklistMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUploadChecklistHook>>>>
    export type UploadChecklistMutationBody = BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost
    export type UploadChecklistMutationError = HTTPValidationError

    /**
 * @summary Upload Checklist
 */
export const useUploadChecklist = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadChecklistHook>>>, TError,{orgId: string;force: boolean;data: BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUploadChecklistHook>>>,
        TError,
        {orgId: string;force: boolean;data: BodyUploadChecklistV1OrganizationsOrgIdChecklistsUploadForcePost},
        TContext
      > => {

      const mutationOptions = useUploadChecklistMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get all checklists of an organization
 * @summary Get Organization Knowledge Base
 */
export const useGetOrganizationKnowledgeBaseHook = () => {
        const getOrganizationKnowledgeBase = useCustomAxios<KnowledgeBase>();

        return useCallback((
    orgId: string,
 signal?: AbortSignal
) => {
        return getOrganizationKnowledgeBase(
          {url: `/v1/organizations/${orgId}/knowledge-base/`, method: 'GET', signal
    },
          );
        }, [getOrganizationKnowledgeBase])
      }
    

export const getGetOrganizationKnowledgeBaseQueryKey = (orgId: string,) => {
    return [`/v1/organizations/${orgId}/knowledge-base/`] as const;
    }

    
export const useGetOrganizationKnowledgeBaseQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationKnowledgeBaseHook>>>, TError = HTTPValidationError>(orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationKnowledgeBaseHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationKnowledgeBaseQueryKey(orgId);

  const getOrganizationKnowledgeBase =  useGetOrganizationKnowledgeBaseHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrganizationKnowledgeBaseHook>>>> = ({ signal }) => getOrganizationKnowledgeBase(orgId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationKnowledgeBaseHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationKnowledgeBaseQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrganizationKnowledgeBaseHook>>>>
export type GetOrganizationKnowledgeBaseQueryError = HTTPValidationError

/**
 * @summary Get Organization Knowledge Base
 */
export const useGetOrganizationKnowledgeBase = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationKnowledgeBaseHook>>>, TError = HTTPValidationError>(
 orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationKnowledgeBaseHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrganizationKnowledgeBaseQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Upload a document to the organization knowledge base
 * @summary Upload Doc To Knowledge Base
 */
export const useUploadDocToKnowledgeBaseHook = () => {
        const uploadDocToKnowledgeBase = useCustomAxios<DocumentMetadata>();

        return useCallback((
    orgId: string,
    bodyUploadDocToKnowledgeBaseV1OrganizationsOrgIdKnowledgeBaseDocumentsUploadPost: BodyUploadDocToKnowledgeBaseV1OrganizationsOrgIdKnowledgeBaseDocumentsUploadPost,
 ) => {const formData = new FormData();
formData.append('file', bodyUploadDocToKnowledgeBaseV1OrganizationsOrgIdKnowledgeBaseDocumentsUploadPost.file)

        return uploadDocToKnowledgeBase(
          {url: `/v1/organizations/${orgId}/knowledge-base/documents/upload`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
          );
        }, [uploadDocToKnowledgeBase])
      }
    


export const useUploadDocToKnowledgeBaseMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadDocToKnowledgeBaseHook>>>, TError,{orgId: string;data: BodyUploadDocToKnowledgeBaseV1OrganizationsOrgIdKnowledgeBaseDocumentsUploadPost}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadDocToKnowledgeBaseHook>>>, TError,{orgId: string;data: BodyUploadDocToKnowledgeBaseV1OrganizationsOrgIdKnowledgeBaseDocumentsUploadPost}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const uploadDocToKnowledgeBase =  useUploadDocToKnowledgeBaseHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUploadDocToKnowledgeBaseHook>>>, {orgId: string;data: BodyUploadDocToKnowledgeBaseV1OrganizationsOrgIdKnowledgeBaseDocumentsUploadPost}> = (props) => {
          const {orgId,data} = props ?? {};

          return  uploadDocToKnowledgeBase(orgId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UploadDocToKnowledgeBaseMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUploadDocToKnowledgeBaseHook>>>>
    export type UploadDocToKnowledgeBaseMutationBody = BodyUploadDocToKnowledgeBaseV1OrganizationsOrgIdKnowledgeBaseDocumentsUploadPost
    export type UploadDocToKnowledgeBaseMutationError = HTTPValidationError

    /**
 * @summary Upload Doc To Knowledge Base
 */
export const useUploadDocToKnowledgeBase = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadDocToKnowledgeBaseHook>>>, TError,{orgId: string;data: BodyUploadDocToKnowledgeBaseV1OrganizationsOrgIdKnowledgeBaseDocumentsUploadPost}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUploadDocToKnowledgeBaseHook>>>,
        TError,
        {orgId: string;data: BodyUploadDocToKnowledgeBaseV1OrganizationsOrgIdKnowledgeBaseDocumentsUploadPost},
        TContext
      > => {

      const mutationOptions = useUploadDocToKnowledgeBaseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a document from the organization knowledge base
 * @summary Get Document From Knowledge Base
 */
export const useGetDocumentFromKnowledgeBaseHook = () => {
        const getDocumentFromKnowledgeBase = useCustomAxios<DocumentMetadata>();

        return useCallback((
    orgId: string,
    params: GetDocumentFromKnowledgeBaseParams,
 signal?: AbortSignal
) => {
        return getDocumentFromKnowledgeBase(
          {url: `/v1/organizations/${orgId}/knowledge-base/documents`, method: 'GET',
        params, signal
    },
          );
        }, [getDocumentFromKnowledgeBase])
      }
    

export const getGetDocumentFromKnowledgeBaseQueryKey = (orgId: string,
    params: GetDocumentFromKnowledgeBaseParams,) => {
    return [`/v1/organizations/${orgId}/knowledge-base/documents`, ...(params ? [params]: [])] as const;
    }

    
export const useGetDocumentFromKnowledgeBaseQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentFromKnowledgeBaseHook>>>, TError = HTTPValidationError>(orgId: string,
    params: GetDocumentFromKnowledgeBaseParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentFromKnowledgeBaseHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDocumentFromKnowledgeBaseQueryKey(orgId,params);

  const getDocumentFromKnowledgeBase =  useGetDocumentFromKnowledgeBaseHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDocumentFromKnowledgeBaseHook>>>> = ({ signal }) => getDocumentFromKnowledgeBase(orgId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentFromKnowledgeBaseHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDocumentFromKnowledgeBaseQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDocumentFromKnowledgeBaseHook>>>>
export type GetDocumentFromKnowledgeBaseQueryError = HTTPValidationError

/**
 * @summary Get Document From Knowledge Base
 */
export const useGetDocumentFromKnowledgeBase = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentFromKnowledgeBaseHook>>>, TError = HTTPValidationError>(
 orgId: string,
    params: GetDocumentFromKnowledgeBaseParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentFromKnowledgeBaseHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetDocumentFromKnowledgeBaseQueryOptions(orgId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Delete Document From Knowledge Base
 */
export const useDeleteDocumentFromKnowledgeBaseHook = () => {
        const deleteDocumentFromKnowledgeBase = useCustomAxios<unknown>();

        return useCallback((
    orgId: string,
    documentId: unknown,
 ) => {
        return deleteDocumentFromKnowledgeBase(
          {url: `/v1/organizations/${orgId}/knowledge-base/documents/${documentId}`, method: 'DELETE'
    },
          );
        }, [deleteDocumentFromKnowledgeBase])
      }
    


export const useDeleteDocumentFromKnowledgeBaseMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFromKnowledgeBaseHook>>>, TError,{orgId: string;documentId: unknown}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFromKnowledgeBaseHook>>>, TError,{orgId: string;documentId: unknown}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deleteDocumentFromKnowledgeBase =  useDeleteDocumentFromKnowledgeBaseHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFromKnowledgeBaseHook>>>, {orgId: string;documentId: unknown}> = (props) => {
          const {orgId,documentId} = props ?? {};

          return  deleteDocumentFromKnowledgeBase(orgId,documentId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDocumentFromKnowledgeBaseMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFromKnowledgeBaseHook>>>>
    
    export type DeleteDocumentFromKnowledgeBaseMutationError = HTTPValidationError

    /**
 * @summary Delete Document From Knowledge Base
 */
export const useDeleteDocumentFromKnowledgeBase = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFromKnowledgeBaseHook>>>, TError,{orgId: string;documentId: unknown}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFromKnowledgeBaseHook>>>,
        TError,
        {orgId: string;documentId: unknown},
        TContext
      > => {

      const mutationOptions = useDeleteDocumentFromKnowledgeBaseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Check if all documents in the organization knowledge base are vectorized and return the list of documents that are in progress
 * @summary Check If All Org Documents Are Vecorized
 */
export const useCheckIfAllOrgDocumentsAreVecorizedHook = () => {
        const checkIfAllOrgDocumentsAreVecorized = useCustomAxios<ReadyForQueries>();

        return useCallback((
    orgId: string,
 signal?: AbortSignal
) => {
        return checkIfAllOrgDocumentsAreVecorized(
          {url: `/v1/organizations/${orgId}/knowledge-base/documents/ready`, method: 'GET', signal
    },
          );
        }, [checkIfAllOrgDocumentsAreVecorized])
      }
    

export const getCheckIfAllOrgDocumentsAreVecorizedQueryKey = (orgId: string,) => {
    return [`/v1/organizations/${orgId}/knowledge-base/documents/ready`] as const;
    }

    
export const useCheckIfAllOrgDocumentsAreVecorizedQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useCheckIfAllOrgDocumentsAreVecorizedHook>>>, TError = HTTPValidationError>(orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckIfAllOrgDocumentsAreVecorizedHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCheckIfAllOrgDocumentsAreVecorizedQueryKey(orgId);

  const checkIfAllOrgDocumentsAreVecorized =  useCheckIfAllOrgDocumentsAreVecorizedHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCheckIfAllOrgDocumentsAreVecorizedHook>>>> = ({ signal }) => checkIfAllOrgDocumentsAreVecorized(orgId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckIfAllOrgDocumentsAreVecorizedHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type CheckIfAllOrgDocumentsAreVecorizedQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCheckIfAllOrgDocumentsAreVecorizedHook>>>>
export type CheckIfAllOrgDocumentsAreVecorizedQueryError = HTTPValidationError

/**
 * @summary Check If All Org Documents Are Vecorized
 */
export const useCheckIfAllOrgDocumentsAreVecorized = <TData = Awaited<ReturnType<ReturnType<typeof useCheckIfAllOrgDocumentsAreVecorizedHook>>>, TError = HTTPValidationError>(
 orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckIfAllOrgDocumentsAreVecorizedHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useCheckIfAllOrgDocumentsAreVecorizedQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Rename a page in a document
 * @summary Rename Page In Orgkb Document
 */
export const useRenamePageInOrgkbDocumentHook = () => {
        const renamePageInOrgkbDocument = useCustomAxios<unknown>();

        return useCallback((
    orgId: string,
    documentId: string,
    params: RenamePageInOrgkbDocumentParams,
 ) => {
        return renamePageInOrgkbDocument(
          {url: `/v1/organizations/${orgId}/knowledge-basedocuments/${documentId}/page/rename`, method: 'POST',
        params
    },
          );
        }, [renamePageInOrgkbDocument])
      }
    


export const useRenamePageInOrgkbDocumentMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRenamePageInOrgkbDocumentHook>>>, TError,{orgId: string;documentId: string;params: RenamePageInOrgkbDocumentParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRenamePageInOrgkbDocumentHook>>>, TError,{orgId: string;documentId: string;params: RenamePageInOrgkbDocumentParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const renamePageInOrgkbDocument =  useRenamePageInOrgkbDocumentHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRenamePageInOrgkbDocumentHook>>>, {orgId: string;documentId: string;params: RenamePageInOrgkbDocumentParams}> = (props) => {
          const {orgId,documentId,params} = props ?? {};

          return  renamePageInOrgkbDocument(orgId,documentId,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RenamePageInOrgkbDocumentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRenamePageInOrgkbDocumentHook>>>>
    
    export type RenamePageInOrgkbDocumentMutationError = HTTPValidationError

    /**
 * @summary Rename Page In Orgkb Document
 */
export const useRenamePageInOrgkbDocument = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRenamePageInOrgkbDocumentHook>>>, TError,{orgId: string;documentId: string;params: RenamePageInOrgkbDocumentParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useRenamePageInOrgkbDocumentHook>>>,
        TError,
        {orgId: string;documentId: string;params: RenamePageInOrgkbDocumentParams},
        TContext
      > => {

      const mutationOptions = useRenamePageInOrgkbDocumentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get all memos of an organization
 * @summary Get Organization Memos
 */
export const useGetOrganizationMemosHook = () => {
        const getOrganizationMemos = useCustomAxios<MemoMeta[]>();

        return useCallback((
    orgId: string,
 signal?: AbortSignal
) => {
        return getOrganizationMemos(
          {url: `/v1/organizations/${orgId}/knowledge-base/memos/`, method: 'GET', signal
    },
          );
        }, [getOrganizationMemos])
      }
    

export const getGetOrganizationMemosQueryKey = (orgId: string,) => {
    return [`/v1/organizations/${orgId}/knowledge-base/memos/`] as const;
    }

    
export const useGetOrganizationMemosQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationMemosHook>>>, TError = HTTPValidationError>(orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationMemosHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationMemosQueryKey(orgId);

  const getOrganizationMemos =  useGetOrganizationMemosHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrganizationMemosHook>>>> = ({ signal }) => getOrganizationMemos(orgId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationMemosHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationMemosQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrganizationMemosHook>>>>
export type GetOrganizationMemosQueryError = HTTPValidationError

/**
 * @summary Get Organization Memos
 */
export const useGetOrganizationMemos = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationMemosHook>>>, TError = HTTPValidationError>(
 orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationMemosHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrganizationMemosQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a memo for the organization
 * @summary Create Organization Memo
 */
export const useCreateOrganizationMemoHook = () => {
        const createOrganizationMemo = useCustomAxios<Memo>();

        return useCallback((
    orgId: string,
    memoCreate: MemoCreate,
 ) => {
        return createOrganizationMemo(
          {url: `/v1/organizations/${orgId}/knowledge-base/memos/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: memoCreate
    },
          );
        }, [createOrganizationMemo])
      }
    


export const useCreateOrganizationMemoMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationMemoHook>>>, TError,{orgId: string;data: MemoCreate}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationMemoHook>>>, TError,{orgId: string;data: MemoCreate}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const createOrganizationMemo =  useCreateOrganizationMemoHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationMemoHook>>>, {orgId: string;data: MemoCreate}> = (props) => {
          const {orgId,data} = props ?? {};

          return  createOrganizationMemo(orgId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateOrganizationMemoMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationMemoHook>>>>
    export type CreateOrganizationMemoMutationBody = MemoCreate
    export type CreateOrganizationMemoMutationError = HTTPValidationError

    /**
 * @summary Create Organization Memo
 */
export const useCreateOrganizationMemo = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationMemoHook>>>, TError,{orgId: string;data: MemoCreate}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useCreateOrganizationMemoHook>>>,
        TError,
        {orgId: string;data: MemoCreate},
        TContext
      > => {

      const mutationOptions = useCreateOrganizationMemoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a memo of an organization
 * @summary Get Organization Memo
 */
export const useGetOrganizationMemoHook = () => {
        const getOrganizationMemo = useCustomAxios<Memo>();

        return useCallback((
    orgId: string,
    memoId: string,
 signal?: AbortSignal
) => {
        return getOrganizationMemo(
          {url: `/v1/organizations/${orgId}/knowledge-base/memos/${memoId}`, method: 'GET', signal
    },
          );
        }, [getOrganizationMemo])
      }
    

export const getGetOrganizationMemoQueryKey = (orgId: string,
    memoId: string,) => {
    return [`/v1/organizations/${orgId}/knowledge-base/memos/${memoId}`] as const;
    }

    
export const useGetOrganizationMemoQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationMemoHook>>>, TError = HTTPValidationError>(orgId: string,
    memoId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationMemoHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationMemoQueryKey(orgId,memoId);

  const getOrganizationMemo =  useGetOrganizationMemoHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrganizationMemoHook>>>> = ({ signal }) => getOrganizationMemo(orgId,memoId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId && memoId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationMemoHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationMemoQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrganizationMemoHook>>>>
export type GetOrganizationMemoQueryError = HTTPValidationError

/**
 * @summary Get Organization Memo
 */
export const useGetOrganizationMemo = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationMemoHook>>>, TError = HTTPValidationError>(
 orgId: string,
    memoId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationMemoHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrganizationMemoQueryOptions(orgId,memoId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Update a memo for the organization
 * @summary Update Organization Memo
 */
export const useUpdateOrganizationMemoHook = () => {
        const updateOrganizationMemo = useCustomAxios<Memo>();

        return useCallback((
    orgId: string,
    memoId: string,
    memoUpdate: MemoUpdate,
 ) => {
        return updateOrganizationMemo(
          {url: `/v1/organizations/${orgId}/knowledge-base/memos/${memoId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: memoUpdate
    },
          );
        }, [updateOrganizationMemo])
      }
    


export const useUpdateOrganizationMemoMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationMemoHook>>>, TError,{orgId: string;memoId: string;data: MemoUpdate}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationMemoHook>>>, TError,{orgId: string;memoId: string;data: MemoUpdate}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const updateOrganizationMemo =  useUpdateOrganizationMemoHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationMemoHook>>>, {orgId: string;memoId: string;data: MemoUpdate}> = (props) => {
          const {orgId,memoId,data} = props ?? {};

          return  updateOrganizationMemo(orgId,memoId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateOrganizationMemoMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationMemoHook>>>>
    export type UpdateOrganizationMemoMutationBody = MemoUpdate
    export type UpdateOrganizationMemoMutationError = HTTPValidationError

    /**
 * @summary Update Organization Memo
 */
export const useUpdateOrganizationMemo = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationMemoHook>>>, TError,{orgId: string;memoId: string;data: MemoUpdate}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateOrganizationMemoHook>>>,
        TError,
        {orgId: string;memoId: string;data: MemoUpdate},
        TContext
      > => {

      const mutationOptions = useUpdateOrganizationMemoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete a memo from the organization
 * @summary Delete Organization Memo
 */
export const useDeleteOrganizationMemoHook = () => {
        const deleteOrganizationMemo = useCustomAxios<MemoMeta[]>();

        return useCallback((
    orgId: string,
    memoId: string,
 ) => {
        return deleteOrganizationMemo(
          {url: `/v1/organizations/${orgId}/knowledge-base/memos/${memoId}`, method: 'DELETE'
    },
          );
        }, [deleteOrganizationMemo])
      }
    


export const useDeleteOrganizationMemoMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationMemoHook>>>, TError,{orgId: string;memoId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationMemoHook>>>, TError,{orgId: string;memoId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deleteOrganizationMemo =  useDeleteOrganizationMemoHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationMemoHook>>>, {orgId: string;memoId: string}> = (props) => {
          const {orgId,memoId} = props ?? {};

          return  deleteOrganizationMemo(orgId,memoId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteOrganizationMemoMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationMemoHook>>>>
    
    export type DeleteOrganizationMemoMutationError = HTTPValidationError

    /**
 * @summary Delete Organization Memo
 */
export const useDeleteOrganizationMemo = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationMemoHook>>>, TError,{orgId: string;memoId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteOrganizationMemoHook>>>,
        TError,
        {orgId: string;memoId: string},
        TContext
      > => {

      const mutationOptions = useDeleteOrganizationMemoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Chat With Knowledge Base New Question
 */
export const useChatWithKnowledgeBaseNewQuestionHook = () => {
        const chatWithKnowledgeBaseNewQuestion = useCustomAxios<Thread>();

        return useCallback((
    orgId: string,
    params: ChatWithKnowledgeBaseNewQuestionParams,
 ) => {
        return chatWithKnowledgeBaseNewQuestion(
          {url: `/v1/organizations/${orgId}/knowledge-base/chat/new_question`, method: 'POST',
        params
    },
          );
        }, [chatWithKnowledgeBaseNewQuestion])
      }
    


export const useChatWithKnowledgeBaseNewQuestionMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChatWithKnowledgeBaseNewQuestionHook>>>, TError,{orgId: string;params: ChatWithKnowledgeBaseNewQuestionParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChatWithKnowledgeBaseNewQuestionHook>>>, TError,{orgId: string;params: ChatWithKnowledgeBaseNewQuestionParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const chatWithKnowledgeBaseNewQuestion =  useChatWithKnowledgeBaseNewQuestionHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useChatWithKnowledgeBaseNewQuestionHook>>>, {orgId: string;params: ChatWithKnowledgeBaseNewQuestionParams}> = (props) => {
          const {orgId,params} = props ?? {};

          return  chatWithKnowledgeBaseNewQuestion(orgId,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChatWithKnowledgeBaseNewQuestionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChatWithKnowledgeBaseNewQuestionHook>>>>
    
    export type ChatWithKnowledgeBaseNewQuestionMutationError = HTTPValidationError

    /**
 * @summary Chat With Knowledge Base New Question
 */
export const useChatWithKnowledgeBaseNewQuestion = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChatWithKnowledgeBaseNewQuestionHook>>>, TError,{orgId: string;params: ChatWithKnowledgeBaseNewQuestionParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useChatWithKnowledgeBaseNewQuestionHook>>>,
        TError,
        {orgId: string;params: ChatWithKnowledgeBaseNewQuestionParams},
        TContext
      > => {

      const mutationOptions = useChatWithKnowledgeBaseNewQuestionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Thread By Id
 */
export const useGetThreadByIdHook = () => {
        const getThreadById = useCustomAxios<Thread>();

        return useCallback((
    orgId: string,
    threadId: string,
 signal?: AbortSignal
) => {
        return getThreadById(
          {url: `/v1/organizations/${orgId}/knowledge-base/chat/threads/${threadId}`, method: 'GET', signal
    },
          );
        }, [getThreadById])
      }
    

export const getGetThreadByIdQueryKey = (orgId: string,
    threadId: string,) => {
    return [`/v1/organizations/${orgId}/knowledge-base/chat/threads/${threadId}`] as const;
    }

    
export const useGetThreadByIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetThreadByIdHook>>>, TError = HTTPValidationError>(orgId: string,
    threadId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetThreadByIdHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetThreadByIdQueryKey(orgId,threadId);

  const getThreadById =  useGetThreadByIdHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetThreadByIdHook>>>> = ({ signal }) => getThreadById(orgId,threadId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId && threadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetThreadByIdHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetThreadByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetThreadByIdHook>>>>
export type GetThreadByIdQueryError = HTTPValidationError

/**
 * @summary Get Thread By Id
 */
export const useGetThreadById = <TData = Awaited<ReturnType<ReturnType<typeof useGetThreadByIdHook>>>, TError = HTTPValidationError>(
 orgId: string,
    threadId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetThreadByIdHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetThreadByIdQueryOptions(orgId,threadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Delete Thread
 */
export const useDeleteThreadHook = () => {
        const deleteThread = useCustomAxios<ThreadMetadata[]>();

        return useCallback((
    orgId: string,
    threadId: string,
 ) => {
        return deleteThread(
          {url: `/v1/organizations/${orgId}/knowledge-base/chat/threads/${threadId}`, method: 'DELETE'
    },
          );
        }, [deleteThread])
      }
    


export const useDeleteThreadMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteThreadHook>>>, TError,{orgId: string;threadId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteThreadHook>>>, TError,{orgId: string;threadId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deleteThread =  useDeleteThreadHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteThreadHook>>>, {orgId: string;threadId: string}> = (props) => {
          const {orgId,threadId} = props ?? {};

          return  deleteThread(orgId,threadId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteThreadMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteThreadHook>>>>
    
    export type DeleteThreadMutationError = HTTPValidationError

    /**
 * @summary Delete Thread
 */
export const useDeleteThread = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteThreadHook>>>, TError,{orgId: string;threadId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteThreadHook>>>,
        TError,
        {orgId: string;threadId: string},
        TContext
      > => {

      const mutationOptions = useDeleteThreadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get All Threads
 */
export const useGetAllThreadsHook = () => {
        const getAllThreads = useCustomAxios<ThreadMetadata[]>();

        return useCallback((
    orgId: string,
 signal?: AbortSignal
) => {
        return getAllThreads(
          {url: `/v1/organizations/${orgId}/knowledge-base/chat/threads`, method: 'GET', signal
    },
          );
        }, [getAllThreads])
      }
    

export const getGetAllThreadsQueryKey = (orgId: string,) => {
    return [`/v1/organizations/${orgId}/knowledge-base/chat/threads`] as const;
    }

    
export const useGetAllThreadsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllThreadsHook>>>, TError = HTTPValidationError>(orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllThreadsHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllThreadsQueryKey(orgId);

  const getAllThreads =  useGetAllThreadsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllThreadsHook>>>> = ({ signal }) => getAllThreads(orgId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllThreadsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllThreadsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllThreadsHook>>>>
export type GetAllThreadsQueryError = HTTPValidationError

/**
 * @summary Get All Threads
 */
export const useGetAllThreads = <TData = Awaited<ReturnType<ReturnType<typeof useGetAllThreadsHook>>>, TError = HTTPValidationError>(
 orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllThreadsHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetAllThreadsQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Latest Thread
 */
export const useGetLatestThreadHook = () => {
        const getLatestThread = useCustomAxios<Thread>();

        return useCallback((
    orgId: string,
 signal?: AbortSignal
) => {
        return getLatestThread(
          {url: `/v1/organizations/${orgId}/knowledge-base/chat/latest_thread`, method: 'GET', signal
    },
          );
        }, [getLatestThread])
      }
    

export const getGetLatestThreadQueryKey = (orgId: string,) => {
    return [`/v1/organizations/${orgId}/knowledge-base/chat/latest_thread`] as const;
    }

    
export const useGetLatestThreadQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetLatestThreadHook>>>, TError = HTTPValidationError>(orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLatestThreadHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLatestThreadQueryKey(orgId);

  const getLatestThread =  useGetLatestThreadHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLatestThreadHook>>>> = ({ signal }) => getLatestThread(orgId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLatestThreadHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLatestThreadQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetLatestThreadHook>>>>
export type GetLatestThreadQueryError = HTTPValidationError

/**
 * @summary Get Latest Thread
 */
export const useGetLatestThread = <TData = Awaited<ReturnType<ReturnType<typeof useGetLatestThreadHook>>>, TError = HTTPValidationError>(
 orgId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLatestThreadHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetLatestThreadQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Delete Project
 */
export const useDeleteProjectHook = () => {
        const deleteProject = useCustomAxios<unknown>();

        return useCallback((
    params: DeleteProjectParams,
 ) => {
        return deleteProject(
          {url: `/v1/danger_zone/delete_project`, method: 'DELETE',
        params
    },
          );
        }, [deleteProject])
      }
    


export const useDeleteProjectMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteProjectHook>>>, TError,{params: DeleteProjectParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteProjectHook>>>, TError,{params: DeleteProjectParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deleteProject =  useDeleteProjectHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteProjectHook>>>, {params: DeleteProjectParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteProject(params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteProjectHook>>>>
    
    export type DeleteProjectMutationError = HTTPValidationError

    /**
 * @summary Delete Project
 */
export const useDeleteProject = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteProjectHook>>>, TError,{params: DeleteProjectParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteProjectHook>>>,
        TError,
        {params: DeleteProjectParams},
        TContext
      > => {

      const mutationOptions = useDeleteProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete All Projects From User
 */
export const useDeleteAllProjectsFromUserHook = () => {
        const deleteAllProjectsFromUser = useCustomAxios<unknown>();

        return useCallback((
    params: DeleteAllProjectsFromUserParams,
 ) => {
        return deleteAllProjectsFromUser(
          {url: `/v1/danger_zone/delete_all_projects_from_user`, method: 'DELETE',
        params
    },
          );
        }, [deleteAllProjectsFromUser])
      }
    


export const useDeleteAllProjectsFromUserMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteAllProjectsFromUserHook>>>, TError,{params: DeleteAllProjectsFromUserParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteAllProjectsFromUserHook>>>, TError,{params: DeleteAllProjectsFromUserParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deleteAllProjectsFromUser =  useDeleteAllProjectsFromUserHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteAllProjectsFromUserHook>>>, {params: DeleteAllProjectsFromUserParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteAllProjectsFromUser(params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAllProjectsFromUserMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteAllProjectsFromUserHook>>>>
    
    export type DeleteAllProjectsFromUserMutationError = HTTPValidationError

    /**
 * @summary Delete All Projects From User
 */
export const useDeleteAllProjectsFromUser = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteAllProjectsFromUserHook>>>, TError,{params: DeleteAllProjectsFromUserParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteAllProjectsFromUserHook>>>,
        TError,
        {params: DeleteAllProjectsFromUserParams},
        TContext
      > => {

      const mutationOptions = useDeleteAllProjectsFromUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Document
 */
export const useDeleteDocumentHook = () => {
        const deleteDocument = useCustomAxios<unknown>();

        return useCallback((
    params: DeleteDocumentParams,
 ) => {
        return deleteDocument(
          {url: `/v1/danger_zone/delete_document`, method: 'DELETE',
        params
    },
          );
        }, [deleteDocument])
      }
    


export const useDeleteDocumentMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentHook>>>, TError,{params: DeleteDocumentParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentHook>>>, TError,{params: DeleteDocumentParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deleteDocument =  useDeleteDocumentHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentHook>>>, {params: DeleteDocumentParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteDocument(params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDocumentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentHook>>>>
    
    export type DeleteDocumentMutationError = HTTPValidationError

    /**
 * @summary Delete Document
 */
export const useDeleteDocument = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentHook>>>, TError,{params: DeleteDocumentParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteDocumentHook>>>,
        TError,
        {params: DeleteDocumentParams},
        TContext
      > => {

      const mutationOptions = useDeleteDocumentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Document From Vectorstore
 */
export const useDeleteDocumentFromVectorstoreHook = () => {
        const deleteDocumentFromVectorstore = useCustomAxios<unknown>();

        return useCallback((
    params: DeleteDocumentFromVectorstoreParams,
 ) => {
        return deleteDocumentFromVectorstore(
          {url: `/v1/danger_zone/delete_document_from_vectorstore`, method: 'DELETE',
        params
    },
          );
        }, [deleteDocumentFromVectorstore])
      }
    


export const useDeleteDocumentFromVectorstoreMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFromVectorstoreHook>>>, TError,{params: DeleteDocumentFromVectorstoreParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFromVectorstoreHook>>>, TError,{params: DeleteDocumentFromVectorstoreParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deleteDocumentFromVectorstore =  useDeleteDocumentFromVectorstoreHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFromVectorstoreHook>>>, {params: DeleteDocumentFromVectorstoreParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteDocumentFromVectorstore(params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDocumentFromVectorstoreMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFromVectorstoreHook>>>>
    
    export type DeleteDocumentFromVectorstoreMutationError = HTTPValidationError

    /**
 * @summary Delete Document From Vectorstore
 */
export const useDeleteDocumentFromVectorstore = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFromVectorstoreHook>>>, TError,{params: DeleteDocumentFromVectorstoreParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteDocumentFromVectorstoreHook>>>,
        TError,
        {params: DeleteDocumentFromVectorstoreParams},
        TContext
      > => {

      const mutationOptions = useDeleteDocumentFromVectorstoreMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Clean Deleted Documents From Project
 */
export const useCleanDeletedDocumentsFromProjectHook = () => {
        const cleanDeletedDocumentsFromProject = useCustomAxios<unknown>();

        return useCallback((
    params: CleanDeletedDocumentsFromProjectParams,
 ) => {
        return cleanDeletedDocumentsFromProject(
          {url: `/v1/danger_zone/clean_deleted_documents_from_project`, method: 'DELETE',
        params
    },
          );
        }, [cleanDeletedDocumentsFromProject])
      }
    


export const useCleanDeletedDocumentsFromProjectMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCleanDeletedDocumentsFromProjectHook>>>, TError,{params: CleanDeletedDocumentsFromProjectParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCleanDeletedDocumentsFromProjectHook>>>, TError,{params: CleanDeletedDocumentsFromProjectParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const cleanDeletedDocumentsFromProject =  useCleanDeletedDocumentsFromProjectHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCleanDeletedDocumentsFromProjectHook>>>, {params: CleanDeletedDocumentsFromProjectParams}> = (props) => {
          const {params} = props ?? {};

          return  cleanDeletedDocumentsFromProject(params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CleanDeletedDocumentsFromProjectMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCleanDeletedDocumentsFromProjectHook>>>>
    
    export type CleanDeletedDocumentsFromProjectMutationError = HTTPValidationError

    /**
 * @summary Clean Deleted Documents From Project
 */
export const useCleanDeletedDocumentsFromProject = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCleanDeletedDocumentsFromProjectHook>>>, TError,{params: CleanDeletedDocumentsFromProjectParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useCleanDeletedDocumentsFromProjectHook>>>,
        TError,
        {params: CleanDeletedDocumentsFromProjectParams},
        TContext
      > => {

      const mutationOptions = useCleanDeletedDocumentsFromProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Rect To Round
 */
export const useRectToRoundHook = () => {
        const rectToRound = useCustomAxios<number>();

        return useCallback((
    params: RectToRoundParams,
 ) => {
        return rectToRound(
          {url: `/v1/calculator/hvac/ductulator/rect_to_round`, method: 'POST',
        params
    },
          );
        }, [rectToRound])
      }
    


export const useRectToRoundMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRectToRoundHook>>>, TError,{params: RectToRoundParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRectToRoundHook>>>, TError,{params: RectToRoundParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const rectToRound =  useRectToRoundHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRectToRoundHook>>>, {params: RectToRoundParams}> = (props) => {
          const {params} = props ?? {};

          return  rectToRound(params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RectToRoundMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRectToRoundHook>>>>
    
    export type RectToRoundMutationError = HTTPValidationError

    /**
 * @summary Rect To Round
 */
export const useRectToRound = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRectToRoundHook>>>, TError,{params: RectToRoundParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useRectToRoundHook>>>,
        TError,
        {params: RectToRoundParams},
        TContext
      > => {

      const mutationOptions = useRectToRoundMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Round To Rect
 */
export const useRoundToRectHook = () => {
        const roundToRect = useCustomAxios<unknown[][]>();

        return useCallback((
    params: RoundToRectParams,
 ) => {
        return roundToRect(
          {url: `/v1/calculator/hvac/ductulator/round_to_rect`, method: 'POST',
        params
    },
          );
        }, [roundToRect])
      }
    


export const useRoundToRectMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRoundToRectHook>>>, TError,{params: RoundToRectParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRoundToRectHook>>>, TError,{params: RoundToRectParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const roundToRect =  useRoundToRectHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRoundToRectHook>>>, {params: RoundToRectParams}> = (props) => {
          const {params} = props ?? {};

          return  roundToRect(params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RoundToRectMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRoundToRectHook>>>>
    
    export type RoundToRectMutationError = HTTPValidationError

    /**
 * @summary Round To Rect
 */
export const useRoundToRect = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRoundToRectHook>>>, TError,{params: RoundToRectParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useRoundToRectHook>>>,
        TError,
        {params: RoundToRectParams},
        TContext
      > => {

      const mutationOptions = useRoundToRectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get follow up questions for a query according to the project (optional) and source of original query
 * @summary Get Follow Up Questions
 */
export const useGetFollowUpQuestionsHook = () => {
        const getFollowUpQuestions = useCustomAxios<Question[]>();

        return useCallback((
    state: State,
 ) => {
        return getFollowUpQuestions(
          {url: `/v1/suggestions/questions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: state
    },
          );
        }, [getFollowUpQuestions])
      }
    


export const useGetFollowUpQuestionsMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetFollowUpQuestionsHook>>>, TError,{data: State}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetFollowUpQuestionsHook>>>, TError,{data: State}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const getFollowUpQuestions =  useGetFollowUpQuestionsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetFollowUpQuestionsHook>>>, {data: State}> = (props) => {
          const {data} = props ?? {};

          return  getFollowUpQuestions(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetFollowUpQuestionsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFollowUpQuestionsHook>>>>
    export type GetFollowUpQuestionsMutationBody = State
    export type GetFollowUpQuestionsMutationError = HTTPValidationError

    /**
 * @summary Get Follow Up Questions
 */
export const useGetFollowUpQuestions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetFollowUpQuestionsHook>>>, TError,{data: State}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useGetFollowUpQuestionsHook>>>,
        TError,
        {data: State},
        TContext
      > => {

      const mutationOptions = useGetFollowUpQuestionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get suggested actions for the new homepage (e.g. "Start a new page", "continue takeoff", etc.)
 * @summary Get Homepage Actions
 */
export const useGetHomepageActionsHook = () => {
        const getHomepageActions = useCustomAxios<SuggestedActionResponse[]>();

        return useCallback((
    params: GetHomepageActionsParams,
 signal?: AbortSignal
) => {
        return getHomepageActions(
          {url: `/v1/suggestions/homepage/actions`, method: 'GET',
        params, signal
    },
          );
        }, [getHomepageActions])
      }
    

export const getGetHomepageActionsQueryKey = (params: GetHomepageActionsParams,) => {
    return [`/v1/suggestions/homepage/actions`, ...(params ? [params]: [])] as const;
    }

    
export const useGetHomepageActionsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetHomepageActionsHook>>>, TError = HTTPValidationError>(params: GetHomepageActionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHomepageActionsHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHomepageActionsQueryKey(params);

  const getHomepageActions =  useGetHomepageActionsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetHomepageActionsHook>>>> = ({ signal }) => getHomepageActions(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHomepageActionsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHomepageActionsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetHomepageActionsHook>>>>
export type GetHomepageActionsQueryError = HTTPValidationError

/**
 * @summary Get Homepage Actions
 */
export const useGetHomepageActions = <TData = Awaited<ReturnType<ReturnType<typeof useGetHomepageActionsHook>>>, TError = HTTPValidationError>(
 params: GetHomepageActionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHomepageActionsHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetHomepageActionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get user's favorite suggested actions
 * @summary Get Suggested Actions Favorites
 */
export const useGetSuggestedActionsFavoritesHook = () => {
        const getSuggestedActionsFavorites = useCustomAxios<SuggestedActionFavorite[]>();

        return useCallback((
    params?: GetSuggestedActionsFavoritesParams,
 signal?: AbortSignal
) => {
        return getSuggestedActionsFavorites(
          {url: `/v1/suggestions/homepage/favorites`, method: 'GET',
        params, signal
    },
          );
        }, [getSuggestedActionsFavorites])
      }
    

export const getGetSuggestedActionsFavoritesQueryKey = (params?: GetSuggestedActionsFavoritesParams,) => {
    return [`/v1/suggestions/homepage/favorites`, ...(params ? [params]: [])] as const;
    }

    
export const useGetSuggestedActionsFavoritesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetSuggestedActionsFavoritesHook>>>, TError = HTTPValidationError>(params?: GetSuggestedActionsFavoritesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSuggestedActionsFavoritesHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSuggestedActionsFavoritesQueryKey(params);

  const getSuggestedActionsFavorites =  useGetSuggestedActionsFavoritesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSuggestedActionsFavoritesHook>>>> = ({ signal }) => getSuggestedActionsFavorites(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSuggestedActionsFavoritesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSuggestedActionsFavoritesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSuggestedActionsFavoritesHook>>>>
export type GetSuggestedActionsFavoritesQueryError = HTTPValidationError

/**
 * @summary Get Suggested Actions Favorites
 */
export const useGetSuggestedActionsFavorites = <TData = Awaited<ReturnType<ReturnType<typeof useGetSuggestedActionsFavoritesHook>>>, TError = HTTPValidationError>(
 params?: GetSuggestedActionsFavoritesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSuggestedActionsFavoritesHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetSuggestedActionsFavoritesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Add a suggested action to user favorites
 * @summary Add Suggested Action To Favorites
 */
export const useAddSuggestedActionToFavoritesHook = () => {
        const addSuggestedActionToFavorites = useCustomAxios<SuggestedActionFavorite>();

        return useCallback((
    suggestedActionFavorite: SuggestedActionFavorite,
    params?: AddSuggestedActionToFavoritesParams,
 ) => {
        return addSuggestedActionToFavorites(
          {url: `/v1/suggestions/homepage/add_to_favorites`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: suggestedActionFavorite,
        params
    },
          );
        }, [addSuggestedActionToFavorites])
      }
    


export const useAddSuggestedActionToFavoritesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddSuggestedActionToFavoritesHook>>>, TError,{data: SuggestedActionFavorite;params?: AddSuggestedActionToFavoritesParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddSuggestedActionToFavoritesHook>>>, TError,{data: SuggestedActionFavorite;params?: AddSuggestedActionToFavoritesParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const addSuggestedActionToFavorites =  useAddSuggestedActionToFavoritesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAddSuggestedActionToFavoritesHook>>>, {data: SuggestedActionFavorite;params?: AddSuggestedActionToFavoritesParams}> = (props) => {
          const {data,params} = props ?? {};

          return  addSuggestedActionToFavorites(data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddSuggestedActionToFavoritesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddSuggestedActionToFavoritesHook>>>>
    export type AddSuggestedActionToFavoritesMutationBody = SuggestedActionFavorite
    export type AddSuggestedActionToFavoritesMutationError = HTTPValidationError

    /**
 * @summary Add Suggested Action To Favorites
 */
export const useAddSuggestedActionToFavorites = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddSuggestedActionToFavoritesHook>>>, TError,{data: SuggestedActionFavorite;params?: AddSuggestedActionToFavoritesParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useAddSuggestedActionToFavoritesHook>>>,
        TError,
        {data: SuggestedActionFavorite;params?: AddSuggestedActionToFavoritesParams},
        TContext
      > => {

      const mutationOptions = useAddSuggestedActionToFavoritesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Edit a suggested action in user favorites
 * @summary Edit Suggested Action Favorite
 */
export const useEditSuggestedActionFavoriteHook = () => {
        const editSuggestedActionFavorite = useCustomAxios<SuggestedActionFavorite>();

        return useCallback((
    suggestedActionFavorite: SuggestedActionFavorite,
    params?: EditSuggestedActionFavoriteParams,
 ) => {
        return editSuggestedActionFavorite(
          {url: `/v1/suggestions/homepage/edit_favorite`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: suggestedActionFavorite,
        params
    },
          );
        }, [editSuggestedActionFavorite])
      }
    


export const useEditSuggestedActionFavoriteMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useEditSuggestedActionFavoriteHook>>>, TError,{data: SuggestedActionFavorite;params?: EditSuggestedActionFavoriteParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useEditSuggestedActionFavoriteHook>>>, TError,{data: SuggestedActionFavorite;params?: EditSuggestedActionFavoriteParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const editSuggestedActionFavorite =  useEditSuggestedActionFavoriteHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useEditSuggestedActionFavoriteHook>>>, {data: SuggestedActionFavorite;params?: EditSuggestedActionFavoriteParams}> = (props) => {
          const {data,params} = props ?? {};

          return  editSuggestedActionFavorite(data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EditSuggestedActionFavoriteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useEditSuggestedActionFavoriteHook>>>>
    export type EditSuggestedActionFavoriteMutationBody = SuggestedActionFavorite
    export type EditSuggestedActionFavoriteMutationError = HTTPValidationError

    /**
 * @summary Edit Suggested Action Favorite
 */
export const useEditSuggestedActionFavorite = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useEditSuggestedActionFavoriteHook>>>, TError,{data: SuggestedActionFavorite;params?: EditSuggestedActionFavoriteParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useEditSuggestedActionFavoriteHook>>>,
        TError,
        {data: SuggestedActionFavorite;params?: EditSuggestedActionFavoriteParams},
        TContext
      > => {

      const mutationOptions = useEditSuggestedActionFavoriteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Set user's favorite suggested actions
 * @summary Set Suggested Actions Favorites
 */
export const useSetSuggestedActionsFavoritesHook = () => {
        const setSuggestedActionsFavorites = useCustomAxios<SuggestedActionFavorite[]>();

        return useCallback((
    suggestedActionFavorite: SuggestedActionFavorite[],
    params?: SetSuggestedActionsFavoritesParams,
 ) => {
        return setSuggestedActionsFavorites(
          {url: `/v1/suggestions/homepage/set_favorites`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: suggestedActionFavorite,
        params
    },
          );
        }, [setSuggestedActionsFavorites])
      }
    


export const useSetSuggestedActionsFavoritesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetSuggestedActionsFavoritesHook>>>, TError,{data: SuggestedActionFavorite[];params?: SetSuggestedActionsFavoritesParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetSuggestedActionsFavoritesHook>>>, TError,{data: SuggestedActionFavorite[];params?: SetSuggestedActionsFavoritesParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const setSuggestedActionsFavorites =  useSetSuggestedActionsFavoritesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSetSuggestedActionsFavoritesHook>>>, {data: SuggestedActionFavorite[];params?: SetSuggestedActionsFavoritesParams}> = (props) => {
          const {data,params} = props ?? {};

          return  setSuggestedActionsFavorites(data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SetSuggestedActionsFavoritesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetSuggestedActionsFavoritesHook>>>>
    export type SetSuggestedActionsFavoritesMutationBody = SuggestedActionFavorite[]
    export type SetSuggestedActionsFavoritesMutationError = HTTPValidationError

    /**
 * @summary Set Suggested Actions Favorites
 */
export const useSetSuggestedActionsFavorites = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetSuggestedActionsFavoritesHook>>>, TError,{data: SuggestedActionFavorite[];params?: SetSuggestedActionsFavoritesParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useSetSuggestedActionsFavoritesHook>>>,
        TError,
        {data: SuggestedActionFavorite[];params?: SetSuggestedActionsFavoritesParams},
        TContext
      > => {

      const mutationOptions = useSetSuggestedActionsFavoritesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Remove a suggested action from user favorites
 * @summary Remove Suggested Action From Favorites
 */
export const useRemoveSuggestedActionFromFavoritesHook = () => {
        const removeSuggestedActionFromFavorites = useCustomAxios<unknown>();

        return useCallback((
    params: RemoveSuggestedActionFromFavoritesParams,
 ) => {
        return removeSuggestedActionFromFavorites(
          {url: `/v1/suggestions/homepage/remove_from_favorites`, method: 'DELETE',
        params
    },
          );
        }, [removeSuggestedActionFromFavorites])
      }
    


export const useRemoveSuggestedActionFromFavoritesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveSuggestedActionFromFavoritesHook>>>, TError,{params: RemoveSuggestedActionFromFavoritesParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveSuggestedActionFromFavoritesHook>>>, TError,{params: RemoveSuggestedActionFromFavoritesParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const removeSuggestedActionFromFavorites =  useRemoveSuggestedActionFromFavoritesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRemoveSuggestedActionFromFavoritesHook>>>, {params: RemoveSuggestedActionFromFavoritesParams}> = (props) => {
          const {params} = props ?? {};

          return  removeSuggestedActionFromFavorites(params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RemoveSuggestedActionFromFavoritesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRemoveSuggestedActionFromFavoritesHook>>>>
    
    export type RemoveSuggestedActionFromFavoritesMutationError = HTTPValidationError

    /**
 * @summary Remove Suggested Action From Favorites
 */
export const useRemoveSuggestedActionFromFavorites = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveSuggestedActionFromFavoritesHook>>>, TError,{params: RemoveSuggestedActionFromFavoritesParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useRemoveSuggestedActionFromFavoritesHook>>>,
        TError,
        {params: RemoveSuggestedActionFromFavoritesParams},
        TContext
      > => {

      const mutationOptions = useRemoveSuggestedActionFromFavoritesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * get all events in project withing the specified date range
 * @summary Get Project Events
 */
export const useGetProjectEventsHook = () => {
        const getProjectEvents = useCustomAxios<EventRecord[]>();

        return useCallback((
    params: GetProjectEventsParams,
 signal?: AbortSignal
) => {
        return getProjectEvents(
          {url: `/v1/activity_log/get_project_events`, method: 'GET',
        params, signal
    },
          );
        }, [getProjectEvents])
      }
    

export const getGetProjectEventsQueryKey = (params: GetProjectEventsParams,) => {
    return [`/v1/activity_log/get_project_events`, ...(params ? [params]: [])] as const;
    }

    
export const useGetProjectEventsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectEventsHook>>>, TError = HTTPValidationError>(params: GetProjectEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectEventsHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectEventsQueryKey(params);

  const getProjectEvents =  useGetProjectEventsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectEventsHook>>>> = ({ signal }) => getProjectEvents(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectEventsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProjectEventsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectEventsHook>>>>
export type GetProjectEventsQueryError = HTTPValidationError

/**
 * @summary Get Project Events
 */
export const useGetProjectEvents = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectEventsHook>>>, TError = HTTPValidationError>(
 params: GetProjectEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectEventsHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetProjectEventsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * get all events for user within the specified date range
 * @summary Get User Events
 */
export const useGetUserEventsHook = () => {
        const getUserEvents = useCustomAxios<EventRecord[]>();

        return useCallback((
    params: GetUserEventsParams,
 signal?: AbortSignal
) => {
        return getUserEvents(
          {url: `/v1/activity_log/get_user_events`, method: 'GET',
        params, signal
    },
          );
        }, [getUserEvents])
      }
    

export const getGetUserEventsQueryKey = (params: GetUserEventsParams,) => {
    return [`/v1/activity_log/get_user_events`, ...(params ? [params]: [])] as const;
    }

    
export const useGetUserEventsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetUserEventsHook>>>, TError = HTTPValidationError>(params: GetUserEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserEventsHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserEventsQueryKey(params);

  const getUserEvents =  useGetUserEventsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserEventsHook>>>> = ({ signal }) => getUserEvents(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserEventsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserEventsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUserEventsHook>>>>
export type GetUserEventsQueryError = HTTPValidationError

/**
 * @summary Get User Events
 */
export const useGetUserEvents = <TData = Awaited<ReturnType<ReturnType<typeof useGetUserEventsHook>>>, TError = HTTPValidationError>(
 params: GetUserEventsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserEventsHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetUserEventsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get equipment items from organization catalog by equipment_id
 * @summary Get Organization Equipment
 */
export const useGetOrganizationEquipmentHook = () => {
        const getOrganizationEquipment = useCustomAxios<EquipmentPiecePrice[]>();

        return useCallback((
    orgId: string,
    equipmentId: string,
 signal?: AbortSignal
) => {
        return getOrganizationEquipment(
          {url: `/v1/organizations/${orgId}/catalog/equipment/${equipmentId}`, method: 'GET', signal
    },
          );
        }, [getOrganizationEquipment])
      }
    

export const getGetOrganizationEquipmentQueryKey = (orgId: string,
    equipmentId: string,) => {
    return [`/v1/organizations/${orgId}/catalog/equipment/${equipmentId}`] as const;
    }

    
export const useGetOrganizationEquipmentQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationEquipmentHook>>>, TError = HTTPValidationError>(orgId: string,
    equipmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationEquipmentHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationEquipmentQueryKey(orgId,equipmentId);

  const getOrganizationEquipment =  useGetOrganizationEquipmentHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrganizationEquipmentHook>>>> = ({ signal }) => getOrganizationEquipment(orgId,equipmentId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId && equipmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationEquipmentHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationEquipmentQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrganizationEquipmentHook>>>>
export type GetOrganizationEquipmentQueryError = HTTPValidationError

/**
 * @summary Get Organization Equipment
 */
export const useGetOrganizationEquipment = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationEquipmentHook>>>, TError = HTTPValidationError>(
 orgId: string,
    equipmentId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationEquipmentHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrganizationEquipmentQueryOptions(orgId,equipmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get equipment item description from organization catalog by unit type and description
 * @summary Get Organization Equipment Description
 */
export const useGetOrganizationEquipmentDescriptionHook = () => {
        const getOrganizationEquipmentDescription = useCustomAxios<EquipmentDescriptionSearch[]>();

        return useCallback((
    orgId: string,
    params: GetOrganizationEquipmentDescriptionParams,
 signal?: AbortSignal
) => {
        return getOrganizationEquipmentDescription(
          {url: `/v1/organizations/${orgId}/catalog/equipment-description/`, method: 'GET',
        params, signal
    },
          );
        }, [getOrganizationEquipmentDescription])
      }
    

export const getGetOrganizationEquipmentDescriptionQueryKey = (orgId: string,
    params: GetOrganizationEquipmentDescriptionParams,) => {
    return [`/v1/organizations/${orgId}/catalog/equipment-description/`, ...(params ? [params]: [])] as const;
    }

    
export const useGetOrganizationEquipmentDescriptionQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationEquipmentDescriptionHook>>>, TError = HTTPValidationError>(orgId: string,
    params: GetOrganizationEquipmentDescriptionParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationEquipmentDescriptionHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationEquipmentDescriptionQueryKey(orgId,params);

  const getOrganizationEquipmentDescription =  useGetOrganizationEquipmentDescriptionHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrganizationEquipmentDescriptionHook>>>> = ({ signal }) => getOrganizationEquipmentDescription(orgId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationEquipmentDescriptionHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationEquipmentDescriptionQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrganizationEquipmentDescriptionHook>>>>
export type GetOrganizationEquipmentDescriptionQueryError = HTTPValidationError

/**
 * @summary Get Organization Equipment Description
 */
export const useGetOrganizationEquipmentDescription = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationEquipmentDescriptionHook>>>, TError = HTTPValidationError>(
 orgId: string,
    params: GetOrganizationEquipmentDescriptionParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationEquipmentDescriptionHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrganizationEquipmentDescriptionQueryOptions(orgId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new equipment item in an organization - only if it does not exist already (unique by equipment_id and description)
 * @summary Create Organization Equipment
 */
export const useCreateOrganizationEquipmentHook = () => {
        const createOrganizationEquipment = useCustomAxios<CatalogItemResponse>();

        return useCallback((
    orgId: string,
    equipmentPiecePriceCreate: EquipmentPiecePriceCreate,
 ) => {
        return createOrganizationEquipment(
          {url: `/v1/organizations/${orgId}/catalog/equipment`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: equipmentPiecePriceCreate
    },
          );
        }, [createOrganizationEquipment])
      }
    


export const useCreateOrganizationEquipmentMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationEquipmentHook>>>, TError,{orgId: string;data: EquipmentPiecePriceCreate}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationEquipmentHook>>>, TError,{orgId: string;data: EquipmentPiecePriceCreate}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const createOrganizationEquipment =  useCreateOrganizationEquipmentHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationEquipmentHook>>>, {orgId: string;data: EquipmentPiecePriceCreate}> = (props) => {
          const {orgId,data} = props ?? {};

          return  createOrganizationEquipment(orgId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateOrganizationEquipmentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationEquipmentHook>>>>
    export type CreateOrganizationEquipmentMutationBody = EquipmentPiecePriceCreate
    export type CreateOrganizationEquipmentMutationError = HTTPValidationError

    /**
 * @summary Create Organization Equipment
 */
export const useCreateOrganizationEquipment = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateOrganizationEquipmentHook>>>, TError,{orgId: string;data: EquipmentPiecePriceCreate}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useCreateOrganizationEquipmentHook>>>,
        TError,
        {orgId: string;data: EquipmentPiecePriceCreate},
        TContext
      > => {

      const mutationOptions = useCreateOrganizationEquipmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get a project filesystem structure
 * @summary Get Filesystem
 */
export const useGetFilesystemHook = () => {
        const getFilesystem = useCustomAxios<FileSystem>();

        return useCallback((
    slug: string,
 signal?: AbortSignal
) => {
        return getFilesystem(
          {url: `/v1/projects/${slug}/filesystem/`, method: 'GET', signal
    },
          );
        }, [getFilesystem])
      }
    

export const getGetFilesystemQueryKey = (slug: string,) => {
    return [`/v1/projects/${slug}/filesystem/`] as const;
    }

    
export const useGetFilesystemQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetFilesystemHook>>>, TError = HTTPValidationError>(slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFilesystemHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFilesystemQueryKey(slug);

  const getFilesystem =  useGetFilesystemHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFilesystemHook>>>> = ({ signal }) => getFilesystem(slug, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFilesystemHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFilesystemQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFilesystemHook>>>>
export type GetFilesystemQueryError = HTTPValidationError

/**
 * @summary Get Filesystem
 */
export const useGetFilesystem = <TData = Awaited<ReturnType<ReturnType<typeof useGetFilesystemHook>>>, TError = HTTPValidationError>(
 slug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFilesystemHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetFilesystemQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create a new folder
 * @summary Create Folder
 */
export const useCreateFolderHook = () => {
        const createFolder = useCustomAxios<Folder>();

        return useCallback((
    slug: string,
    folder: Folder,
 ) => {
        return createFolder(
          {url: `/v1/projects/${slug}/filesystem/create_folder`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: folder
    },
          );
        }, [createFolder])
      }
    


export const useCreateFolderMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateFolderHook>>>, TError,{slug: string;data: Folder}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateFolderHook>>>, TError,{slug: string;data: Folder}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const createFolder =  useCreateFolderHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateFolderHook>>>, {slug: string;data: Folder}> = (props) => {
          const {slug,data} = props ?? {};

          return  createFolder(slug,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateFolderMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateFolderHook>>>>
    export type CreateFolderMutationBody = Folder
    export type CreateFolderMutationError = HTTPValidationError

    /**
 * @summary Create Folder
 */
export const useCreateFolder = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateFolderHook>>>, TError,{slug: string;data: Folder}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useCreateFolderHook>>>,
        TError,
        {slug: string;data: Folder},
        TContext
      > => {

      const mutationOptions = useCreateFolderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete a folder
 * @summary Delete Folder
 */
export const useDeleteFolderHook = () => {
        const deleteFolder = useCustomAxios<FileSystem>();

        return useCallback((
    slug: string,
    params: DeleteFolderParams,
 ) => {
        return deleteFolder(
          {url: `/v1/projects/${slug}/filesystem/delete_folder`, method: 'DELETE',
        params
    },
          );
        }, [deleteFolder])
      }
    


export const useDeleteFolderMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteFolderHook>>>, TError,{slug: string;params: DeleteFolderParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteFolderHook>>>, TError,{slug: string;params: DeleteFolderParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deleteFolder =  useDeleteFolderHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteFolderHook>>>, {slug: string;params: DeleteFolderParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  deleteFolder(slug,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFolderMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteFolderHook>>>>
    
    export type DeleteFolderMutationError = HTTPValidationError

    /**
 * @summary Delete Folder
 */
export const useDeleteFolder = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteFolderHook>>>, TError,{slug: string;params: DeleteFolderParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteFolderHook>>>,
        TError,
        {slug: string;params: DeleteFolderParams},
        TContext
      > => {

      const mutationOptions = useDeleteFolderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update multiple folders or files
 * @summary Update Filesystem Batch
 */
export const useUpdateFilesystemBatchHook = () => {
        const updateFilesystemBatch = useCustomAxios<FileSystem>();

        return useCallback((
    slug: string,
    updateFilesystemBatchBodyItem: UpdateFilesystemBatchBodyItem[],
 ) => {
        return updateFilesystemBatch(
          {url: `/v1/projects/${slug}/filesystem/batch`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateFilesystemBatchBodyItem
    },
          );
        }, [updateFilesystemBatch])
      }
    


export const useUpdateFilesystemBatchMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateFilesystemBatchHook>>>, TError,{slug: string;data: UpdateFilesystemBatchBodyItem[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateFilesystemBatchHook>>>, TError,{slug: string;data: UpdateFilesystemBatchBodyItem[]}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const updateFilesystemBatch =  useUpdateFilesystemBatchHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateFilesystemBatchHook>>>, {slug: string;data: UpdateFilesystemBatchBodyItem[]}> = (props) => {
          const {slug,data} = props ?? {};

          return  updateFilesystemBatch(slug,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateFilesystemBatchMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateFilesystemBatchHook>>>>
    export type UpdateFilesystemBatchMutationBody = UpdateFilesystemBatchBodyItem[]
    export type UpdateFilesystemBatchMutationError = HTTPValidationError

    /**
 * @summary Update Filesystem Batch
 */
export const useUpdateFilesystemBatch = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateFilesystemBatchHook>>>, TError,{slug: string;data: UpdateFilesystemBatchBodyItem[]}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateFilesystemBatchHook>>>,
        TError,
        {slug: string;data: UpdateFilesystemBatchBodyItem[]},
        TContext
      > => {

      const mutationOptions = useUpdateFilesystemBatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update a folder or file
 * @summary Update Filesystem Object
 */
export const useUpdateFilesystemObjectHook = () => {
        const updateFilesystemObject = useCustomAxios<FileSystem>();

        return useCallback((
    slug: string,
    objId: string,
    updateFilesystemObjectBody: UpdateFilesystemObjectBody,
 ) => {
        return updateFilesystemObject(
          {url: `/v1/projects/${slug}/filesystem/${objId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateFilesystemObjectBody
    },
          );
        }, [updateFilesystemObject])
      }
    


export const useUpdateFilesystemObjectMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateFilesystemObjectHook>>>, TError,{slug: string;objId: string;data: UpdateFilesystemObjectBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateFilesystemObjectHook>>>, TError,{slug: string;objId: string;data: UpdateFilesystemObjectBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const updateFilesystemObject =  useUpdateFilesystemObjectHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateFilesystemObjectHook>>>, {slug: string;objId: string;data: UpdateFilesystemObjectBody}> = (props) => {
          const {slug,objId,data} = props ?? {};

          return  updateFilesystemObject(slug,objId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateFilesystemObjectMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateFilesystemObjectHook>>>>
    export type UpdateFilesystemObjectMutationBody = UpdateFilesystemObjectBody
    export type UpdateFilesystemObjectMutationError = HTTPValidationError

    /**
 * @summary Update Filesystem Object
 */
export const useUpdateFilesystemObject = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateFilesystemObjectHook>>>, TError,{slug: string;objId: string;data: UpdateFilesystemObjectBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateFilesystemObjectHook>>>,
        TError,
        {slug: string;objId: string;data: UpdateFilesystemObjectBody},
        TContext
      > => {

      const mutationOptions = useUpdateFilesystemObjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Add a file to a folder
 * @summary Add File
 */
export const useAddFileHook = () => {
        const addFile = useCustomAxios<File>();

        return useCallback((
    slug: string,
    file: File,
 ) => {
        return addFile(
          {url: `/v1/projects/${slug}/filesystem/add_file`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: file
    },
          );
        }, [addFile])
      }
    


export const useAddFileMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddFileHook>>>, TError,{slug: string;data: File}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddFileHook>>>, TError,{slug: string;data: File}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const addFile =  useAddFileHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAddFileHook>>>, {slug: string;data: File}> = (props) => {
          const {slug,data} = props ?? {};

          return  addFile(slug,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddFileMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddFileHook>>>>
    export type AddFileMutationBody = File
    export type AddFileMutationError = HTTPValidationError

    /**
 * @summary Add File
 */
export const useAddFile = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddFileHook>>>, TError,{slug: string;data: File}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useAddFileHook>>>,
        TError,
        {slug: string;data: File},
        TContext
      > => {

      const mutationOptions = useAddFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Delete a file
 * @summary Delete File
 */
export const useDeleteFileHook = () => {
        const deleteFile = useCustomAxios<FileSystem>();

        return useCallback((
    slug: string,
    params: DeleteFileParams,
 ) => {
        return deleteFile(
          {url: `/v1/projects/${slug}/filesystem/delete_file`, method: 'DELETE',
        params
    },
          );
        }, [deleteFile])
      }
    


export const useDeleteFileMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteFileHook>>>, TError,{slug: string;params: DeleteFileParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteFileHook>>>, TError,{slug: string;params: DeleteFileParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const deleteFile =  useDeleteFileHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteFileHook>>>, {slug: string;params: DeleteFileParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  deleteFile(slug,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFileMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteFileHook>>>>
    
    export type DeleteFileMutationError = HTTPValidationError

    /**
 * @summary Delete File
 */
export const useDeleteFile = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteFileHook>>>, TError,{slug: string;params: DeleteFileParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useDeleteFileHook>>>,
        TError,
        {slug: string;params: DeleteFileParams},
        TContext
      > => {

      const mutationOptions = useDeleteFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Upload File With Vdb Research
 */
export const useUploadFileWithVdbResearchHook = () => {
        const uploadFileWithVdbResearch = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    bodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost: BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost,
    params: UploadFileWithVdbResearchParams,
 ) => {const formData = new FormData();
formData.append('file', bodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost.file)

        return uploadFileWithVdbResearch(
          {url: `/v1/projects/${slug}/research/upload`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData,
        params
    },
          );
        }, [uploadFileWithVdbResearch])
      }
    


export const useUploadFileWithVdbResearchMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadFileWithVdbResearchHook>>>, TError,{slug: string;data: BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost;params: UploadFileWithVdbResearchParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadFileWithVdbResearchHook>>>, TError,{slug: string;data: BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost;params: UploadFileWithVdbResearchParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const uploadFileWithVdbResearch =  useUploadFileWithVdbResearchHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUploadFileWithVdbResearchHook>>>, {slug: string;data: BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost;params: UploadFileWithVdbResearchParams}> = (props) => {
          const {slug,data,params} = props ?? {};

          return  uploadFileWithVdbResearch(slug,data,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UploadFileWithVdbResearchMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUploadFileWithVdbResearchHook>>>>
    export type UploadFileWithVdbResearchMutationBody = BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost
    export type UploadFileWithVdbResearchMutationError = HTTPValidationError

    /**
 * @summary Upload File With Vdb Research
 */
export const useUploadFileWithVdbResearch = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUploadFileWithVdbResearchHook>>>, TError,{slug: string;data: BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost;params: UploadFileWithVdbResearchParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUploadFileWithVdbResearchHook>>>,
        TError,
        {slug: string;data: BodyUploadFileWithVdbResearchV1ProjectsSlugResearchUploadPost;params: UploadFileWithVdbResearchParams},
        TContext
      > => {

      const mutationOptions = useUploadFileWithVdbResearchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Chat With Image
 */
export const useChatWithImageHook = () => {
        const chatWithImage = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    params: ChatWithImageParams,
 ) => {
        return chatWithImage(
          {url: `/v1/projects/${slug}/research/chat_with_image`, method: 'POST',
        params
    },
          );
        }, [chatWithImage])
      }
    


export const useChatWithImageMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChatWithImageHook>>>, TError,{slug: string;params: ChatWithImageParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChatWithImageHook>>>, TError,{slug: string;params: ChatWithImageParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const chatWithImage =  useChatWithImageHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useChatWithImageHook>>>, {slug: string;params: ChatWithImageParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  chatWithImage(slug,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChatWithImageMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChatWithImageHook>>>>
    
    export type ChatWithImageMutationError = HTTPValidationError

    /**
 * @summary Chat With Image
 */
export const useChatWithImage = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChatWithImageHook>>>, TError,{slug: string;params: ChatWithImageParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useChatWithImageHook>>>,
        TError,
        {slug: string;params: ChatWithImageParams},
        TContext
      > => {

      const mutationOptions = useChatWithImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Document Pages Names
 */
export const useUpdateDocumentPagesNamesHook = () => {
        const updateDocumentPagesNames = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    params: UpdateDocumentPagesNamesParams,
 ) => {
        return updateDocumentPagesNames(
          {url: `/v1/projects/${slug}/research/update_document_pages_names`, method: 'POST',
        params
    },
          );
        }, [updateDocumentPagesNames])
      }
    


export const useUpdateDocumentPagesNamesMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentPagesNamesHook>>>, TError,{slug: string;params: UpdateDocumentPagesNamesParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentPagesNamesHook>>>, TError,{slug: string;params: UpdateDocumentPagesNamesParams}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const updateDocumentPagesNames =  useUpdateDocumentPagesNamesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentPagesNamesHook>>>, {slug: string;params: UpdateDocumentPagesNamesParams}> = (props) => {
          const {slug,params} = props ?? {};

          return  updateDocumentPagesNames(slug,params,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateDocumentPagesNamesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentPagesNamesHook>>>>
    
    export type UpdateDocumentPagesNamesMutationError = HTTPValidationError

    /**
 * @summary Update Document Pages Names
 */
export const useUpdateDocumentPagesNames = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateDocumentPagesNamesHook>>>, TError,{slug: string;params: UpdateDocumentPagesNamesParams}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useUpdateDocumentPagesNamesHook>>>,
        TError,
        {slug: string;params: UpdateDocumentPagesNamesParams},
        TContext
      > => {

      const mutationOptions = useUpdateDocumentPagesNamesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Files From Blob
 */
export const useDeleteFilesFromBlobHook = () => {
        const deleteFilesFromBlob = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    params: DeleteFilesFromBlobParams,
 signal?: AbortSignal
) => {
        return deleteFilesFromBlob(
          {url: `/v1/projects/${slug}/research/delete_files_from_blob`, method: 'GET',
        params, signal
    },
          );
        }, [deleteFilesFromBlob])
      }
    

export const getDeleteFilesFromBlobQueryKey = (slug: string,
    params: DeleteFilesFromBlobParams,) => {
    return [`/v1/projects/${slug}/research/delete_files_from_blob`, ...(params ? [params]: [])] as const;
    }

    
export const useDeleteFilesFromBlobQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useDeleteFilesFromBlobHook>>>, TError = HTTPValidationError>(slug: string,
    params: DeleteFilesFromBlobParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useDeleteFilesFromBlobHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDeleteFilesFromBlobQueryKey(slug,params);

  const deleteFilesFromBlob =  useDeleteFilesFromBlobHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useDeleteFilesFromBlobHook>>>> = ({ signal }) => deleteFilesFromBlob(slug,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useDeleteFilesFromBlobHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type DeleteFilesFromBlobQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteFilesFromBlobHook>>>>
export type DeleteFilesFromBlobQueryError = HTTPValidationError

/**
 * @summary Delete Files From Blob
 */
export const useDeleteFilesFromBlob = <TData = Awaited<ReturnType<ReturnType<typeof useDeleteFilesFromBlobHook>>>, TError = HTTPValidationError>(
 slug: string,
    params: DeleteFilesFromBlobParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useDeleteFilesFromBlobHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useDeleteFilesFromBlobQueryOptions(slug,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Upload Vector Db To Blob
 */
export const useUploadVectorDbToBlobHook = () => {
        const uploadVectorDbToBlob = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    params: UploadVectorDbToBlobParams,
 signal?: AbortSignal
) => {
        return uploadVectorDbToBlob(
          {url: `/v1/projects/${slug}/research/upload_vector_db_to_blob`, method: 'GET',
        params, signal
    },
          );
        }, [uploadVectorDbToBlob])
      }
    

export const getUploadVectorDbToBlobQueryKey = (slug: string,
    params: UploadVectorDbToBlobParams,) => {
    return [`/v1/projects/${slug}/research/upload_vector_db_to_blob`, ...(params ? [params]: [])] as const;
    }

    
export const useUploadVectorDbToBlobQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useUploadVectorDbToBlobHook>>>, TError = HTTPValidationError>(slug: string,
    params: UploadVectorDbToBlobParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUploadVectorDbToBlobHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUploadVectorDbToBlobQueryKey(slug,params);

  const uploadVectorDbToBlob =  useUploadVectorDbToBlobHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useUploadVectorDbToBlobHook>>>> = ({ signal }) => uploadVectorDbToBlob(slug,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUploadVectorDbToBlobHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type UploadVectorDbToBlobQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUploadVectorDbToBlobHook>>>>
export type UploadVectorDbToBlobQueryError = HTTPValidationError

/**
 * @summary Upload Vector Db To Blob
 */
export const useUploadVectorDbToBlob = <TData = Awaited<ReturnType<ReturnType<typeof useUploadVectorDbToBlobHook>>>, TError = HTTPValidationError>(
 slug: string,
    params: UploadVectorDbToBlobParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUploadVectorDbToBlobHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useUploadVectorDbToBlobQueryOptions(slug,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Projects Id Documents Document Id Annotation Research
 */
export const useGetProjectsIdDocumentsDocumentIdAnnotationResearchHook = () => {
        const getProjectsIdDocumentsDocumentIdAnnotationResearch = useCustomAxios<unknown>();

        return useCallback((
    slug: string,
    documentId: string,
 ) => {
        return getProjectsIdDocumentsDocumentIdAnnotationResearch(
          {url: `/v1/projects/${slug}/research/${documentId}/annotation`, method: 'POST'
    },
          );
        }, [getProjectsIdDocumentsDocumentIdAnnotationResearch])
      }
    


export const useGetProjectsIdDocumentsDocumentIdAnnotationResearchMutationOptions = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationResearchHook>>>, TError,{slug: string;documentId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationResearchHook>>>, TError,{slug: string;documentId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      const getProjectsIdDocumentsDocumentIdAnnotationResearch =  useGetProjectsIdDocumentsDocumentIdAnnotationResearchHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationResearchHook>>>, {slug: string;documentId: string}> = (props) => {
          const {slug,documentId} = props ?? {};

          return  getProjectsIdDocumentsDocumentIdAnnotationResearch(slug,documentId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetProjectsIdDocumentsDocumentIdAnnotationResearchMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationResearchHook>>>>
    
    export type GetProjectsIdDocumentsDocumentIdAnnotationResearchMutationError = HTTPValidationError

    /**
 * @summary Get Projects Id Documents Document Id Annotation Research
 */
export const useGetProjectsIdDocumentsDocumentIdAnnotationResearch = <TError = HTTPValidationError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationResearchHook>>>, TError,{slug: string;documentId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<ReturnType<typeof useGetProjectsIdDocumentsDocumentIdAnnotationResearchHook>>>,
        TError,
        {slug: string;documentId: string},
        TContext
      > => {

      const mutationOptions = useGetProjectsIdDocumentsDocumentIdAnnotationResearchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Root
 */
export const useRootHook = () => {
        const root = useCustomAxios<unknown>();

        return useCallback((
    
 signal?: AbortSignal
) => {
        return root(
          {url: `/`, method: 'GET', signal
    },
          );
        }, [root])
      }
    

export const getRootQueryKey = () => {
    return [`/`] as const;
    }

    
export const useRootQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useRootHook>>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useRootHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRootQueryKey();

  const root =  useRootHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useRootHook>>>> = ({ signal }) => root(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useRootHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type RootQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRootHook>>>>
export type RootQueryError = unknown

/**
 * @summary Root
 */
export const useRoot = <TData = Awaited<ReturnType<ReturnType<typeof useRootHook>>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useRootHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useRootQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Login endpoint
 * @summary Login
 */
export const useLoginHook = () => {
        const login = useCustomAxios<Login200>();

        return useCallback((
    params: LoginParams,
 signal?: AbortSignal
) => {
        return login(
          {url: `/login`, method: 'GET',
        params, signal
    },
          );
        }, [login])
      }
    

export const getLoginQueryKey = (params: LoginParams,) => {
    return [`/login`, ...(params ? [params]: [])] as const;
    }

    
export const useLoginQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useLoginHook>>>, TError = HTTPValidationError>(params: LoginParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useLoginHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLoginQueryKey(params);

  const login =  useLoginHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useLoginHook>>>> = ({ signal }) => login(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useLoginHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type LoginQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useLoginHook>>>>
export type LoginQueryError = HTTPValidationError

/**
 * @summary Login
 */
export const useLogin = <TData = Awaited<ReturnType<ReturnType<typeof useLoginHook>>>, TError = HTTPValidationError>(
 params: LoginParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useLoginHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useLoginQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Health Check
 */
export const useHealthCheckHook = () => {
        const healthCheck = useCustomAxios<string>();

        return useCallback((
    
 signal?: AbortSignal
) => {
        return healthCheck(
          {url: `/health`, method: 'GET', signal
    },
          );
        }, [healthCheck])
      }
    

export const getHealthCheckQueryKey = () => {
    return [`/health`] as const;
    }

    
export const useHealthCheckQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useHealthCheckHook>>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useHealthCheckHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getHealthCheckQueryKey();

  const healthCheck =  useHealthCheckHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useHealthCheckHook>>>> = ({ signal }) => healthCheck(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useHealthCheckHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type HealthCheckQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useHealthCheckHook>>>>
export type HealthCheckQueryError = unknown

/**
 * @summary Health Check
 */
export const useHealthCheck = <TData = Awaited<ReturnType<ReturnType<typeof useHealthCheckHook>>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useHealthCheckHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useHealthCheckQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Returns the current API version
 * @summary Version
 */
export const useVersionHook = () => {
        const version = useCustomAxios<string>();

        return useCallback((
    
 signal?: AbortSignal
) => {
        return version(
          {url: `/version`, method: 'GET', signal
    },
          );
        }, [version])
      }
    

export const getVersionQueryKey = () => {
    return [`/version`] as const;
    }

    
export const useVersionQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useVersionHook>>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useVersionHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVersionQueryKey();

  const version =  useVersionHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useVersionHook>>>> = ({ signal }) => version(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useVersionHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type VersionQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useVersionHook>>>>
export type VersionQueryError = unknown

/**
 * @summary Version
 */
export const useVersion = <TData = Awaited<ReturnType<ReturnType<typeof useVersionHook>>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useVersionHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useVersionQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Returns the current environment
 * @summary Env
 */
export const useEnvHook = () => {
        const env = useCustomAxios<string>();

        return useCallback((
    
 signal?: AbortSignal
) => {
        return env(
          {url: `/env`, method: 'GET', signal
    },
          );
        }, [env])
      }
    

export const getEnvQueryKey = () => {
    return [`/env`] as const;
    }

    
export const useEnvQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useEnvHook>>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useEnvHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getEnvQueryKey();

  const env =  useEnvHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useEnvHook>>>> = ({ signal }) => env(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useEnvHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type EnvQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useEnvHook>>>>
export type EnvQueryError = unknown

/**
 * @summary Env
 */
export const useEnv = <TData = Awaited<ReturnType<ReturnType<typeof useEnvHook>>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useEnvHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useEnvQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Returns the current database
 * @summary Database
 */
export const useDatabaseHook = () => {
        const database = useCustomAxios<string>();

        return useCallback((
    
 signal?: AbortSignal
) => {
        return database(
          {url: `/database`, method: 'GET', signal
    },
          );
        }, [database])
      }
    

export const getDatabaseQueryKey = () => {
    return [`/database`] as const;
    }

    
export const useDatabaseQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useDatabaseHook>>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useDatabaseHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDatabaseQueryKey();

  const database =  useDatabaseHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useDatabaseHook>>>> = ({ signal }) => database(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useDatabaseHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type DatabaseQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDatabaseHook>>>>
export type DatabaseQueryError = unknown

/**
 * @summary Database
 */
export const useDatabase = <TData = Awaited<ReturnType<ReturnType<typeof useDatabaseHook>>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useDatabaseHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useDatabaseQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update Settings
 */
export const useUpdateSettingsUpdateSettingsGetHook = () => {
        const updateSettingsUpdateSettingsGet = useCustomAxios<unknown>();

        return useCallback((
    
 signal?: AbortSignal
) => {
        return updateSettingsUpdateSettingsGet(
          {url: `/update-settings`, method: 'GET', signal
    },
          );
        }, [updateSettingsUpdateSettingsGet])
      }
    

export const getUpdateSettingsUpdateSettingsGetQueryKey = () => {
    return [`/update-settings`] as const;
    }

    
export const useUpdateSettingsUpdateSettingsGetQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useUpdateSettingsUpdateSettingsGetHook>>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUpdateSettingsUpdateSettingsGetHook>>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUpdateSettingsUpdateSettingsGetQueryKey();

  const updateSettingsUpdateSettingsGet =  useUpdateSettingsUpdateSettingsGetHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useUpdateSettingsUpdateSettingsGetHook>>>> = ({ signal }) => updateSettingsUpdateSettingsGet(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUpdateSettingsUpdateSettingsGetHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type UpdateSettingsUpdateSettingsGetQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateSettingsUpdateSettingsGetHook>>>>
export type UpdateSettingsUpdateSettingsGetQueryError = unknown

/**
 * @summary Update Settings
 */
export const useUpdateSettingsUpdateSettingsGet = <TData = Awaited<ReturnType<ReturnType<typeof useUpdateSettingsUpdateSettingsGetHook>>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUpdateSettingsUpdateSettingsGetHook>>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useUpdateSettingsUpdateSettingsGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}





import { useCallback, useRef, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { useQueryClient } from '@tanstack/react-query';
import { CreateHandler, DeleteHandler, MoveHandler, RenameHandler } from 'react-arborist';
import { ProjectFormValue } from '@/views/Projects/components/ProjectFormDialog/types';
import { STATUS } from '@/utils/enums';
import { updateProjectCache } from '@/utils/updateProjectCache';
import {
  DocumentMetadata,
  FileSystem,
  getGetProjectsIdQueryKey,
  getGetProjectsQueryKey,
  ProjectFull,
  useCreateFolderHook,
  useDeleteFileHook,
  useDeleteFolderHook,
  useGetProjectsIdHook,
  usePostProjectsHook,
  useUpdateFilesystemBatchHook,
  useUploadFileWithVdbHook,
} from '@/api/generated';
import { useDeleteDocuments } from '@/hooks/useDeleteDocuments';
import { useRenameDocument } from '@/hooks/useRenameDocument';
import { constructFileSystemTree } from '@/utils/constructFileSystemTree';
import { convertFilSystemTreeToServerFileSystem } from '@/views/Projects/components/ProjectFormDialog/utils/convertFilSystemTreeToServerFileSystem';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { removePdfExtension } from '@/views/Projects/components/ProjectFormDialog/utils/removePdfExtension';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';
import { useEmptyDocumentProject } from '@/views/Project/hooks/useEmptyDocumentProject';
import { toProjectDetails } from '@/services/linker';
import { useNavigate } from 'react-router-dom';
import { TreeFileSystemNode } from '@/types';

type Params = {
  initialProject?: ProjectFull;
  getFormValues: <T extends keyof ProjectFormValue = keyof ProjectFormValue>(field: T) => ProjectFormValue[T];
  onProjectCreate: (createdProject: ProjectFull) => void;
};

type RenameParams = {
  id: string;
  name: string;
};

export const useFileSystemTree = ({ initialProject, getFormValues, onProjectCreate }: Params) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation('projectUpdate');
  const { showConfirmDialog } = useConfirmDialog();
  const { setForceDropZoneView } = useEmptyDocumentProject();

  const projectPromiseRef = useRef<Promise<ProjectFull>>();
  const treeNodesByIdRef = useRef<Record<string, TreeFileSystemNode>>({});
  const [fileSystemRootNodes, setFileSystemRootNodes] = useState<TreeFileSystemNode[]>([]);
  const [documents, setDocuments] = useState<DocumentMetadata[]>(initialProject?.documents ?? []);

  const getProject = useGetProjectsIdHook();
  const createProject = usePostProjectsHook();
  const createFolder = useCreateFolderHook();
  const deleteFolder = useDeleteFolderHook();
  const deleteFile = useDeleteFileHook();
  const uploadDocument = useUploadFileWithVdbHook();
  const updateFileSystem = useUpdateFilesystemBatchHook();
  const deleteDocuments = useDeleteDocuments(queryClient);
  const renameDocument = useRenameDocument(queryClient);

  const loadFileSystem = async (project?: ProjectFull) => {
    projectPromiseRef.current = undefined;
    setDocuments(project?.documents ?? []);

    if (!project?.filesystem) return;
    updateFileSystemTree(project.filesystem);
  };

  const getRelatedFiles = (nodesById: Record<string, TreeFileSystemNode>, folderIds: string[]) => {
    const fileIds: string[] = [];

    let iterationFolderIds = [...folderIds];
    while (iterationFolderIds.length) {
      const nextFolderIds: string[] = [];
      Object.entries(nodesById).forEach(([, node]) => {
        if (!node.parentId || !iterationFolderIds.includes(node.parentId)) return;

        if (node.type === 'file') {
          fileIds.push(node.id);
        } else {
          nextFolderIds.push(node.id);
        }
      });
      iterationFolderIds = nextFolderIds;
    }

    return fileIds;
  };

  const setTreeNodeByIdState = (nodesById: Record<string, TreeFileSystemNode>) => {
    // Select only root nodes the rest should be inside in nodes children prop.
    const rootNodes = Object.entries(nodesById).reduce<TreeFileSystemNode[]>(
      (acc, [, node]) => (node.parentId ? acc : [...acc, node]),
      [],
    );

    Object.entries(nodesById).forEach(([, node]) => {
      if (!node.children) return;
      node.children = orderBy(node.children, ['type', 'order'], ['desc', 'asc']);
    });

    setFileSystemRootNodes(orderBy(rootNodes, ['type', 'order'], ['desc', 'asc']));
  };

  const updateFileSystemTree = (fileSystem: FileSystem) => {
    const documentsById = documents.reduce<Record<string, DocumentMetadata>>((acc, document) => {
      acc[document._id!] = document;
      return acc;
    }, {});

    treeNodesByIdRef.current = constructFileSystemTree(fileSystem, documentsById);
    setTreeNodeByIdState(treeNodesByIdRef.current);
  };

  const uploadFiles = async (files: File[], parentId?: string) => {
    const projectName = getFormValues('name') || 'New Project';

    if (!initialProject && !projectPromiseRef.current) {
      projectPromiseRef.current = createProject({ name: projectName });
    }

    const currentProject = initialProject ?? (await projectPromiseRef.current!);
    if (!initialProject) {
      onProjectCreate(currentProject);
    }

    if (!initialProject) {
      queryClient.invalidateQueries({ queryKey: [getGetProjectsQueryKey()] });
      await queryClient.prefetchQuery({
        queryKey: getGetProjectsIdQueryKey(currentProject.slug),
        queryFn: () => getProject(currentProject.slug),
      });
    }

    const newDocumentNodes = files.map(file => ({
      id: crypto.randomUUID(),
      type: 'file' as const,
      name: file.name,
      order: 0,
      parentId,
      file,
      status: STATUS.LOADING,
    }));

    newDocumentNodes.forEach(node => (treeNodesByIdRef.current[node.id] = node));
    if (parentId && treeNodesByIdRef.current[parentId]?.children) {
      treeNodesByIdRef.current[parentId].children.push(...newDocumentNodes);
    }
    setTreeNodeByIdState(treeNodesByIdRef.current);

    newDocumentNodes.map(async ({ id, file, order }) => {
      try {
        const document = await uploadDocument(currentProject.slug!, { file }, { parentId, order });

        updateProjectCache({ queryClient, projectSlug: currentProject.slug }, prevProject => ({
          ...prevProject!,
          documents: [...(prevProject!.documents || []), document],
        }));
        setDocuments(prevDocuments => [...prevDocuments, document]);

        delete treeNodesByIdRef.current[id];
        treeNodesByIdRef.current[document._id!] = {
          type: 'file' as const,
          id: document._id!,
          name: removePdfExtension(document.filename),
          order: 0,
          document,
          parentId,
          status: STATUS.LOADED,
        };
        if (parentId && treeNodesByIdRef.current[parentId]?.children) {
          treeNodesByIdRef.current[parentId].children = treeNodesByIdRef.current[parentId].children.filter(
            child => child.id !== id,
          );
          treeNodesByIdRef.current[parentId].children.push(treeNodesByIdRef.current[document._id!]);
        }
        setTreeNodeByIdState(treeNodesByIdRef.current);
      } catch (error) {
        treeNodesByIdRef.current[id].status = STATUS.ERROR;
        setTreeNodeByIdState(treeNodesByIdRef.current);
      }
    });
  };

  const onFolderCreate: CreateHandler<TreeFileSystemNode> = async ({ parentId }) => {
    const slug = getFormValues('slug');
    if (!slug) return null;

    const tempId = crypto.randomUUID();
    const name = t('uploadFiles.newFolder');
    const folderNode = {
      type: 'folder' as const,
      id: tempId,
      name,
      order: 0,
      parentId: parentId ?? undefined,
      status: STATUS.LOADING,
      children: [],
    };

    treeNodesByIdRef.current![tempId] = folderNode;
    if (parentId && treeNodesByIdRef.current![parentId].children) {
      treeNodesByIdRef.current[parentId].children.push(folderNode);
    }
    setTreeNodeByIdState(treeNodesByIdRef.current);

    const newFolder = await createFolder(slug, {
      name,
      order: 0,
      parentId: parentId ?? 'root',
    });

    if (!newFolder.id) return null;

    delete treeNodesByIdRef.current![tempId];
    treeNodesByIdRef.current[newFolder.id] = {
      type: 'folder' as const,
      id: newFolder.id,
      name: newFolder.name,
      order: newFolder.order ?? 0,
      parentId: parentId ?? undefined,
      status: STATUS.LOADED,
      children: [],
    };
    if (parentId && treeNodesByIdRef.current[parentId].children) {
      treeNodesByIdRef.current[parentId].children.push(treeNodesByIdRef.current[newFolder.id]);
      treeNodesByIdRef.current[parentId].children = treeNodesByIdRef.current[parentId].children.filter(({ id }) => id !== tempId);
    }

    setTreeNodeByIdState(treeNodesByIdRef.current);

    return { id: newFolder.id! };
  };

  const onDelete: DeleteHandler<TreeFileSystemNode> = async ({ nodes }) => {
    const slug = getFormValues('slug');
    const result = await showConfirmDialog({
      title: t('uploadFiles.confirmDelete.title'),
      confirm: t('uploadFiles.confirmDelete.confirm'),
      cancel: t('uploadFiles.confirmDelete.cancel'),
    });
    if (!result || !slug) return;

    const node = nodes[0];
    const fileIds = node.data.type === 'folder' ? getRelatedFiles(treeNodesByIdRef.current, [node.id]) : [node.id];

    [node.id, ...fileIds].forEach(id => {
      if (!treeNodesByIdRef.current[id]) return;
      treeNodesByIdRef.current[id].status = STATUS.DELETING;
    });
    setTreeNodeByIdState(treeNodesByIdRef.current);

    await deleteDocuments({ documentIds: fileIds, projectSlug: slug, updateUrl: true });
    const fileSystem =
      node.data.type === 'folder'
        ? await deleteFolder(slug, { folder_id: node.id })
        : await deleteFile(slug, { file_id: node.id });

    updateProjectCache({ queryClient, projectSlug: slug }, prevProject => ({
      ...prevProject!,
      documents: (prevProject!.documents || []).filter(document => !fileIds.includes(document._id!)),
    }));
    setDocuments(prevDocuments => prevDocuments.filter(doc => !fileIds.includes(doc._id!)));
    updateFileSystemTree(fileSystem);
  };

  const onMove: MoveHandler<TreeFileSystemNode> = ({ dragIds, parentId, parentNode, index }) => {
    const slug = getFormValues('slug');
    if (!slug) return;

    let nextFileSystemRootNodes = [...fileSystemRootNodes];
    let nextParentChildren = parentNode?.data.children ?? nextFileSystemRootNodes;
    const insertPosition = index === 0 ? 'first' : index === nextParentChildren.length ? 'last' : 'after';
    const elementIdToInsertAfter = insertPosition === 'after' ? nextParentChildren[index]?.id : undefined;

    dragIds
      .reverse()
      .map(dragId => treeNodesByIdRef.current[dragId])
      .forEach(node => {
        const prevParentId = node.parentId;
        const isPutInSameFolder = prevParentId === (parentId ?? undefined);
        node.parentId = parentId ?? undefined;

        if (prevParentId && treeNodesByIdRef.current[prevParentId].children) {
          treeNodesByIdRef.current[prevParentId].children = treeNodesByIdRef.current[prevParentId]!.children!.filter(
            child => child.id !== node.id,
          );
          treeNodesByIdRef.current[prevParentId].children.forEach((child, i) => (child.order = i));
          if (isPutInSameFolder) nextParentChildren = treeNodesByIdRef.current[prevParentId].children;
        } else {
          nextFileSystemRootNodes = nextFileSystemRootNodes.filter(child => child.id !== node.id);
          if (isPutInSameFolder) nextParentChildren = nextFileSystemRootNodes;
        }

        const indexToInsert =
          insertPosition === 'first'
            ? 0
            : insertPosition === 'last'
              ? nextParentChildren.length
              : nextParentChildren.findIndex(child => child.id === elementIdToInsertAfter);
        nextParentChildren.splice(indexToInsert, 0, node);
      });

    nextFileSystemRootNodes.forEach((node, i) => (node.order = i));
    nextParentChildren.forEach((node, i) => (node.order = i));
    setTreeNodeByIdState(treeNodesByIdRef.current);

    const nextFileSystem = convertFilSystemTreeToServerFileSystem(treeNodesByIdRef.current);
    updateFileSystem(slug, nextFileSystem);
  };

  const renameFile = async ({ id, name }: RenameParams) => {
    const slug = getFormValues('slug');
    if (!slug) return;

    try {
      treeNodesByIdRef.current[id] = {
        ...treeNodesByIdRef.current[id],
        name,
        status: STATUS.LOADING,
      };
      setTreeNodeByIdState(treeNodesByIdRef.current);

      const updatedDocument = await renameDocument({ projectSlug: slug, documentId: id, name: `${name}.pdf` });

      setDocuments(prevDocuments =>
        prevDocuments.map(prevDocument =>
          id === prevDocument._id ? { ...prevDocument, document: updatedDocument } : prevDocument,
        ),
      );
      treeNodesByIdRef.current[id] = {
        ...treeNodesByIdRef.current[id],
        name: removePdfExtension(updatedDocument.filename),
        document: updatedDocument,
        status: STATUS.LOADED,
      };
      setTreeNodeByIdState(treeNodesByIdRef.current);
    } catch (error) {
      enqueueSnackbar(t('uploadFiles.updateNameToasts.failed'), { variant: 'error' });
      console.error('Error while renaming document', error);
    }
  };

  const renameFolder = async ({ id, name }: RenameParams) => {
    const slug = getFormValues('slug');
    if (!slug) return;

    const folder = treeNodesByIdRef.current[id];
    folder.name = name;
    setTreeNodeByIdState(treeNodesByIdRef.current);

    updateFileSystem(slug, convertFilSystemTreeToServerFileSystem({ [id]: folder }));
  };

  const onRename: RenameHandler<TreeFileSystemNode> = ({ id, name, node }) => {
    if (node.data.name === name) return;

    if (node.data.type === 'folder') {
      renameFolder({ id, name });
    } else {
      renameFile({ id, name });
    }
  };

  const onFileOpen = useCallback((documentId: string) => {
    const slug = getFormValues('slug');
    if (!slug) return;

    setForceDropZoneView(false);
    navigate(toProjectDetails({ projectSlug: slug, documentId }));
  }, []);

  return {
    loadFileSystem,
    setDocuments,
    fileSystemNodes: fileSystemRootNodes,
    uploadFiles,
    onRename,
    onMove,
    onFolderCreate,
    onDelete,
    onFileOpen,
  };
};

import { PaletteColorOptions, PaletteOptions, SimplePaletteColorOptions } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

interface DefaultPaletteOptions extends PaletteOptions {
  primary: SimplePaletteColorOptions;
  secondary: SimplePaletteColorOptions;
  accent: SimplePaletteColorOptions;
  warning: SimplePaletteColorOptions;
  darkPurple: PaletteColorOptions;
  darkerPurple: PaletteColorOptions;
  background: PaletteOptions['background'] & { selection: string };
  controls: { text: string; background: string; border: string };
  low: PaletteColorOptions;
  mid: PaletteColorOptions;
  high: PaletteColorOptions;
  chat: PaletteColorOptions;
  likes: PaletteColorOptions;
  feedback: PaletteColorOptions;
  feedbackLike: PaletteColorOptions;
  pink: PaletteColorOptions;
  grey: ColorPartial;
  tags: string[];
}

const palette: DefaultPaletteOptions = {
  tags: [
    '#49758F',
    '#AC6F81',
    '#D57BB6',
    '#FDC2CD',
    '#FF00AA',
    '#ED0404',
    '#570BB7',
    '#BCABC6',
    '#808000',
    '#A4C578',
    '#0ACB10',
    '#B8F331',
    '#DA6E0F',
    '#FF9E34',
    '#FFEA00',
    '#FFE4B5',
    '#17DEEE',
    '#1EE8B6',
    '#0088FF',
    '#26A1D5',
  ],
  primary: {
    main: '#EEE6FC',
    dark: '#5209E6',
    light: '#D0D0D0',
  },
  secondary: {
    main: '#F5F5F5',
    light: '#8B8986',
    dark: '#64615E',
    contrastText: '#000',
  },
  accent: {
    main: '#fea622',
    light: '#FFDBB3',
    dark: '#EB9130',
    contrastText: '#290473',
  },
  error: {
    light: '#ef5350',
    main: '#d32f2f',
    dark: '#c62828',
  },
  text: {
    primary: '#3D3A36',
    secondary: '#000000',
  },
  background: {
    default: '#FFFFFF',
    paper: '#FCFAFE',
    selection: 'rgba(35, 131, 226, 0.28)',
  },
  grey: {
    50: '#F4F4F4',
    100: '#F5F5F5',
    200: '#E1E1E1',
    300: '#ECEBEB',
    400: '#B8BCCA',
    500: '#D8D8D7',
    600: '#D9D9D9',
    700: '#777572',
    800: '#64615E',
    900: '#586472',
  },
  warning: {
    main: '#E1E1E1',
    light: '#FFD8AE',
    dark: '#FFBCB3',
  },
  action: {
    hover: '#EEE6FC',
    selected: '#5209E6',
  },
  controls: {
    text: '#6F7482',
    background: '#F8FAFC',
    border: 'rgba(0, 0, 0, 0.23)',
  },
  darkPurple: {
    main: '#DCCEFA',
    dark: '#8653ED',
    light: '#DCCEFA',
  },
  darkerPurple: {
    main: '#A884F3',
    light: '#CBB5F7',
  },
  mid: {
    main: '#FFD8AE',
  },
  low: {
    main: '#E1E1E1',
  },
  high: {
    main: '#FFBCB3',
  },
  chat: {
    main: '#3D3D3D',
    light: '#666668',
  },
  likes: {
    main: '#27B376',
    dark: '#E53030',
  },
  feedback: {
    main: '#FF9E34',
    dark: '#290473',
    light: '#61C423',
  },
  feedbackLike: {
    main: '#409e3d',
    dark: '#b80e0e',
  },
  pink: {
    main: '#F6F3FE',
  },
};
export default palette;

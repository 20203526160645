import { User } from '@/api/generated';
import { createContext } from 'react';

export type AuthContextValue = {
  logout: () => void;
  currentUser: User | undefined;
  isLoggedIn: boolean;
  isLoading: boolean;
};

export const AuthContext = createContext<AuthContextValue | null>(null);

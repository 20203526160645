import { Typography, Stack, CircularProgress, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { ListItem } from '@/components/Select/Select';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuth } from '@/hooks/useAuth';
import { User, useSearchUsers, useShareProjectHook, useUnshareProjectHook, ProjectFull } from '@/api/generated';
import { useDebounceValue } from 'usehooks-ts';
import SubmitButton from '@/components/SubmitButton/SubmitButton';
import { UserItem } from '@/views/ShareProjectDialog/UserItem';
import DialogCloseButton from '@/components/Dialog/DialogCloseButton';
import DialogHeader from '@/components/Dialog/DialogHeader';
import Dialog from '@/components/Dialog/Dialog';

const validationSchema = z.object({
  user: z.object({
    search: z.string(),
    inputValue: z.string(),
  }),
});
export type FormShareProject = z.infer<typeof validationSchema>;
interface ShareProjectProps {
  project: ProjectFull;
  onShare?: (user: User) => void;
  onUnshare?: (user: User) => void;
  toggleModal: () => void;
  isOpen: boolean;
}

const QUERY_KEY = 'search-user';

const ShareProjectDialog: FC<ShareProjectProps> = ({ project, isOpen, toggleModal, onShare, onUnshare }) => {
  const { palette } = useTheme();
  const { currentUser } = useAuth();
  const { t } = useTranslation('share');
  const shareProject = useShareProjectHook();
  const unShareProject = useUnshareProjectHook();

  const { handleSubmit, control, watch, reset, setValue } = useForm<FormShareProject>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: { user: { inputValue: '', search: '' } },
  });
  const [search] = useDebounceValue<string>(watch('user.search'), 200);

  const { data: usersToShareWith = [], isFetching } = useSearchUsers(
    search,
    {},
    { query: { queryKey: [QUERY_KEY, search], enabled: !!search, staleTime: 1000 * 60 } },
  );

  const options = useMemo(
    () =>
      usersToShareWith
        .filter(({ _id }) => !project.collaborate_with?.find(user => user._id === _id) && _id !== currentUser?._id)
        .map(user => {
          const { firstName, lastName, email } = user;
          return { title: t('searchOptionLabel', { firstName, lastName, email }), inputValue: email } as ListItem;
        }),
    [usersToShareWith],
  );

  const onSubmit = (data: FormShareProject) => {
    const [selectedUser] = usersToShareWith.filter(({ email }) => data.user.inputValue === email);

    if (!selectedUser) {
      return;
    }

    if (!(project?.collaborate_with || []).includes(selectedUser)) {
      shareProject(project.slug, selectedUser);
      onShare?.(selectedUser);
      setValue('user', { inputValue: '', search: '' });
    }
  };

  const onClose = () => {
    toggleModal();
    reset();
  };

  const onChange = (data?: ListItem) => {
    if (!data) return reset();

    const { title, inputValue = '' } = data;
    setValue('user', { inputValue, search: title });

    onSubmit({ user: { inputValue, search: title } });
  };

  const handleUnshare = (user: User) => {
    unShareProject(project.slug, { user_id: user._id! });
    onUnshare?.(user);
  };

  const collaborationUsers = project.collaborate_with?.filter(user => user._id !== currentUser?._id);

  return (
    <Dialog width={435} open={isOpen} onClose={onClose}>
      <DialogHeader title={t('title', { projectName: project.name })} />
      <DialogCloseButton onClick={toggleModal} />
      <Stack component="form" onSubmit={handleSubmit(onSubmit)} sx={{ height: '100%' }} gap={2}>
        <Select
          onSelect={handleSubmit(onSubmit)}
          label={t('searchInputLabel')}
          options={options}
          control={control}
          value={search}
          clearOnBlur
          id="user"
          name="user.search"
          freeSolo
          sx={{
            '& .MuiAutocomplete-clearIndicator': {
              visibility: search ? 'visible' : 'hidden !important',
            },
          }}
          endAdornment={isFetching ? <CircularProgress color="inherit" size={20} /> : null}
          onChange={(_, data) => onChange(data as ListItem)}
        />

        {!!collaborationUsers?.length && (
          <>
            <Typography variant="body1" component="h2" sx={{ color: palette.grey[900] }}>
              {t('sharedWith')}
            </Typography>
            <Stack sx={{ overflowY: 'auto', height: '230px' }}>
              {collaborationUsers.map(user => (
                <UserItem key={user._id} user={user} onUnshare={() => handleUnshare(user)} />
              ))}
            </Stack>
          </>
        )}

        <SubmitButton type="button" sx={{ mt: 2, px: 4, alignSelf: 'center' }} onClick={onClose}>
          {t('submit')}
        </SubmitButton>
      </Stack>
    </Dialog>
  );
};

export default ShareProjectDialog;

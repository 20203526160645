import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { FC, useCallback, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  CreateHandler,
  DeleteHandler,
  MoveHandler,
  NodeApi,
  NodeRendererProps,
  RenameHandler,
  Tree,
  TreeApi,
} from 'react-arborist';
import { useResizeObserver } from 'usehooks-ts';
import UploadIcon from '@/assets/upload-icon.svg?react';
import UploadFiles from '@/components/UploadFiles/UploadFiles';
import { acceptedFileTypes } from '@/components/UploadFiles/consts';
import TreeDocumentNode from '@/views/Projects/components/ProjectFormDialog/components/TreeDocumentNode';
import Icon from '@/components/Icon/Icon';
import { TreeFileSystemNode } from '@/types';

interface DocumentUploadProps {
  fileSystemNodes: TreeFileSystemNode[];
  uploadFiles: (files: File[], folderId?: string) => void;
  onRename: RenameHandler<TreeFileSystemNode>;
  onMove: MoveHandler<TreeFileSystemNode>;
  onFolderCreate: CreateHandler<TreeFileSystemNode>;
  onDelete: DeleteHandler<TreeFileSystemNode>;
  onFileOpen: (documentId: string) => void;
}

const DocumentUpload: FC<DocumentUploadProps> = ({
  fileSystemNodes,
  uploadFiles,
  onRename,
  onMove,
  onFolderCreate,
  onDelete,
  onFileOpen,
}) => {
  const { t } = useTranslation('projectUpdate');
  const { palette } = useTheme();

  const treeRef = useRef<TreeApi<TreeFileSystemNode> | null>(null);
  const folderIdRef = useRef<string>();
  const treeWrapRef = useRef<HTMLDivElement | null>(null);
  const { width = 0, height = 0 } = useResizeObserver({
    ref: treeWrapRef,
    box: 'border-box',
  });

  const beforeFilesUpload = (files: File[], _: FileRejection[], event: DropEvent | null) => {
    if (!event) {
      return uploadFiles(files, folderIdRef.current);
    }

    const dropTarget = event.target as HTMLElement;
    const closestFolder = dropTarget.closest<HTMLElement>('[data-folderid]');
    const folderIdAttr = closestFolder?.dataset?.['folderid'];
    const folderId = folderIdAttr && folderIdAttr !== 'root' ? folderIdAttr : undefined;

    uploadFiles(files, folderId);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: beforeFilesUpload,
    accept: acceptedFileTypes,
    onFileDialogCancel: () => (folderIdRef.current = undefined),
  });
  const { onClick, ...rootProps } = getRootProps();

  const onAddFile = useCallback((node: NodeApi<TreeFileSystemNode>) => {
    folderIdRef.current = node.id;
    open();
  }, []);

  const handleFileOpen = useCallback((node: NodeApi<TreeFileSystemNode>) => onFileOpen(node.id), []);

  const TreeDocumentNodeWrap = useCallback(
    (nodeProps: NodeRendererProps<TreeFileSystemNode>) => (
      <TreeDocumentNode {...nodeProps} onAddFile={onAddFile} onFileOpen={handleFileOpen} />
    ),
    [onAddFile, onFileOpen],
  );

  return (
    <Stack flex={1} {...rootProps} data-folderid="root" sx={{ height: '100%' }}>
      <Stack sx={{ minHeight: 0, pb: 5.75 }}>
        <Typography variant="h3" fontWeight="fontWeightMedium" sx={{ color: palette.grey[800], textAlign: 'center' }}>
          {t('uploadFiles.title')}
        </Typography>
      </Stack>
      <UploadFiles
        sx={{ pt: 7.25, pb: 10, minHeight: 0, boxShadow: `0 24px 24px 0 ${palette.text.secondary}0c`, mb: 0, borderRadius: 4 }}
        onClick={onClick}
        getInputProps={getInputProps}
        isDragActive={isDragActive}
      >
        <Stack gap={1.5} alignItems="center">
          <Box component={UploadIcon} />
          <Typography sx={{ color: palette.grey[800], fontWeight: 'fontWeightMedium' }}>
            <Trans
              components={{
                span: <span style={{ color: palette.primary.dark, textDecoration: 'underline' }} />,
              }}
            >
              {t('uploadFiles.inputLabel')}
            </Trans>
          </Typography>
        </Stack>
      </UploadFiles>
      <Stack
        sx={{
          height: '100%',
          pt: 3.75,
          pb: 8,
          fontWeight: 'fontWeightMedium',
          overflow: 'hidden',
          color: palette.grey[800],
        }}
      >
        <Stack sx={{ height: '100%', px: 1 }}>
          <Stack direction="row" alignItems="center" sx={{ pb: 3 }}>
            <Typography
              fontWeight="fontWeightMedium"
              sx={{
                color: palette.grey[800],
                position: 'sticky',
                top: 0,
                zIndex: '100',
                width: '100%',
                background: `linear-gradient(to bottom, ${palette.background.default} 70%, transparent)`,
              }}
            >
              {t('uploadFiles.documents')}
            </Typography>

            <IconButton size="small" title={t('uploadFiles.addFolder')} onClick={() => treeRef.current?.create()}>
              <Icon name="newFolder" fontSize="small" />
            </IconButton>
          </Stack>

          <Stack ref={treeWrapRef} sx={{ minHeight: 0, height: '100%', mx: -1 }}>
            <Tree
              ref={treeRef}
              width={width}
              height={height}
              openByDefault={false}
              disableMultiSelection
              rowHeight={38}
              idAccessor={node => node.id}
              disableDrop={({ parentNode }) => !parentNode.isRoot && parentNode.data.type !== 'folder'}
              childrenAccessor="children"
              onMove={onMove}
              onCreate={onFolderCreate}
              onRename={onRename}
              onDelete={onDelete}
              data={fileSystemNodes}
            >
              {TreeDocumentNodeWrap}
            </Tree>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DocumentUpload;

import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import Project from '@/views/Project/Project';
import { Global } from '@emotion/react';
import { ROUTER_IDS, toProjectDetails } from '@/services/linker';
import useQueryString from '@/hooks/useQueryString';
import { combineProviders } from '@/utils/combineComponents';
import useRouteId from '@/hooks/useRouteId';
import AnnotationsProvider from '@/views/Project/providers/AnnotationsProvider';
import DocumentsProvider from '@/views/Project/providers/DocumentsProvider';
import LayoutStateProvider from '@/views/Project/providers/LayoutStateProvider';
import ProjectProvider from '@/views/Project/providers/ProjectProvider';
import QueriesProvider from '@/views/Project/providers/QueriesProvider';

const Providers = combineProviders([
  ProjectProvider,
  DocumentsProvider,
  QueriesProvider,
  AnnotationsProvider,
  LayoutStateProvider,
]);

const ProjectContainer: FC = () => {
  const { projectSlug } = useParams();
  const { documentId } = useQueryString();
  const routeId = useRouteId();

  if (!projectSlug) throw 'Missing projectSlug';

  if (routeId === ROUTER_IDS.PROJECT) {
    return <Navigate replace to={toProjectDetails({ projectSlug, documentId })} />;
  }

  return (
    <Providers>
      <Global styles={{ body: { overflow: 'hidden' } }} />
      <Project />
    </Providers>
  );
};
export default ProjectContainer;

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { lazy } from 'react';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { AuthProvider as DescopeAuthProvider } from '@descope/react-sdk';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import theme from '@/theme';
import routes from '@/containers/App/routes';
import { AxiosError } from 'axios';
import { combineProviders } from '@/utils/combineComponents';
import Toast from '@/components/Toast/Toast';
import customCss from '@/theme/globalStyles/customCss';
import { ConfirmDialogProvider } from '@/providers/ConfirmDialogProvider';
import LogrocketAnalytics from '@/components/LogrocketAnalytics';
import AuthProvider from '@/providers/AuthProvider';
import CurrencyProvider from '@/providers/CurrencyProvider';
import LoaderProvider from '@/providers/LoaderProvider';
import TitleProvider from '@/providers/TitleProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error: unknown) => {
        const status = (error as AxiosError).response?.status;
        if (status === 401 || status === 403) return false;
        return failureCount < 2;
      },
    },
  },
});

const router = createBrowserRouter(routes);

const SnackbarComponents = {
  default: Toast,
  error: Toast,
  success: Toast,
  warning: Toast,
  info: Toast,
};

const Providers = combineProviders([
  [DescopeAuthProvider, { projectId: import.meta.env.VITE_DESCOPE_PROJECT_ID }],
  [QueryClientProvider, { client: queryClient }],
  [ThemeProvider, { theme }],
  [
    SnackbarProvider,
    {
      maxSnack: 2,
      Components: SnackbarComponents,
      dense: true,
      autoHideDuration: 2000,
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    },
  ],
  LoaderProvider,
  AuthProvider,
  CurrencyProvider,
  TitleProvider,
  ConfirmDialogProvider,
]);

const searchParams = new URLSearchParams(window.location.search);
const isDebug = searchParams.get('debug') === '1';

const ReactQueryDevtools = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(d => ({
    default: d.ReactQueryDevtools,
  })),
);

const App = () => (
  <Providers>
    <CssBaseline />
    <GlobalStyles styles={customCss(theme)} />
    {isDebug && !import.meta.env.PROD && <ReactQueryDevtools initialIsOpen={false} />}
    {!import.meta.env.DEV && <LogrocketAnalytics />}
    <RouterProvider router={router} />
  </Providers>
);

export default App;

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import emptyStateArrow from './empty-state-arrow.svg?url';
import { Status } from '@/api/generated';

interface EmptyStateProps {
  status: Status;
  searchFilter?: string;
}

const EmptyState: FC<EmptyStateProps> = ({ status, searchFilter }) => {
  const { t } = useTranslation('projects');
  const { palette, shape, typography } = useTheme();

  const isStatusActive = Status.active === status;

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        boxShadow: 2,
        borderRadius: shape.borderRadius,
        flex: '1 1 auto',
        height: '66vh',
        fontSize: typography.h2.fontSize,
        textAlign: 'center',
        lineHeight: '60px',
        ...(isStatusActive && {
          backgroundImage: `url("${emptyStateArrow}")`,
          backgroundPosition: `right 200px top 5px`,
          backgroundRepeat: 'no-repeat',
        }),
        color: palette.text.secondary,
      }}
    >
      {searchFilter ? t('emptyByFilter') : t(`emptyByStatus.${status}`)}
    </Stack>
  );
};

export default EmptyState;

import { FC, KeyboardEvent, useCallback } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';
import { useFormContext } from 'react-hook-form';
import TextInput from '@/components/TextInput/TextInput';
import GoogleAutoComplete from '@/components/GoogleAutoComplete/GoogleAutoComplete';
import Select from '@/components/Select/Select';
import Stakeholders from '@/views/Projects/components/ProjectFormDialog/components/Stakeholders';
import { ProjectFormValue } from '@/views/Projects/components/ProjectFormDialog/types';

interface ProjectFormProps {}

const fieldNames: Array<keyof ProjectFormValue> = ['name', 'address', 'type', 'delivery_method', 'project_size', 'owner'];
const fieldIds = fieldNames.map(name => `projectUpdate-${name}`);

const ProjectForm: FC<ProjectFormProps> = () => {
  const { t } = useTranslation('projectUpdate');
  const { control, setValue, setFocus } = useFormContext<ProjectFormValue>();

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code !== Key.Enter) return;
    event.preventDefault();

    const currentFieldIndex = fieldIds.findIndex(id => (event.target as HTMLInputElement).id === id);
    if (currentFieldIndex >= 0 && fieldIds[currentFieldIndex + 1]) {
      setFocus(fieldNames[currentFieldIndex + 1]);
    }
  };

  const onPlaceSelected = useCallback((place: google.maps.places.PlaceResult) => {
    const getComponentByType = (targetType: string) =>
      place.address_components?.find(({ types }) => types.find(type => type === targetType))?.long_name;

    setValue('location', {
      number: parseInt(getComponentByType('street_number') || '', 10) || null,
      street: getComponentByType('route'),
      state: getComponentByType('administrative_area_level_1'),
      city: getComponentByType('locality'),
      country: getComponentByType('country'),
      zip: getComponentByType('postal_code'),
      lat: place.geometry?.location?.lat(),
      lng: place.geometry?.location?.lng(),
    });
  }, []);

  return (
    <Stack gap={1.25}>
      <TextInput
        label={t('details.name')}
        placeholder={t('details.namePlaceholder')}
        control={control}
        id={fieldIds[0]}
        name={fieldNames[0]}
        isRequired
        labelSx={{ fontWeight: 'fontWeightMedium' }}
        translate="projectUpdate"
        onKeyDown={handleKeyDown}
      />
      <GoogleAutoComplete
        label={t('details.address')}
        placeholder={t('details.addressPlaceholder')}
        id={fieldIds[1]}
        name={fieldNames[1]}
        control={control}
        options={{ types: ['address'] }}
        onPlaceSelected={onPlaceSelected}
        onKeyDown={handleKeyDown}
      />
      <Select
        label={t('details.type')}
        options={t('projectTypes', { returnObjects: true })}
        placeholder={t('details.typePlaceholder')}
        control={control}
        id={fieldIds[2]}
        name={fieldNames[2]}
        freeSolo
        onKeyDown={handleKeyDown}
      />
      <Select
        label={t('details.deliveryMethod')}
        options={t('deliveryMethods', { returnObjects: true })}
        placeholder={t('details.deliveryMethodPlaceholder')}
        control={control}
        id={fieldIds[3]}
        name={fieldNames[3]}
        freeSolo
        onKeyDown={handleKeyDown}
      />
      <TextInput
        label={t('details.projectSize')}
        placeholder={t('details.projectSizePlaceholder')}
        control={control}
        id={fieldIds[4]}
        name={fieldNames[4]}
        labelSx={{ fontWeight: 'fontWeightMedium' }}
        translate="projectUpdate"
        onKeyDown={handleKeyDown}
      />
      <TextInput
        label={t('details.generalContractor')}
        placeholder={t('details.generalContractorPlaceholder')}
        control={control}
        id={fieldIds[5]}
        name={fieldNames[5]}
        labelSx={{ fontWeight: 'fontWeightMedium' }}
        translate="projectUpdate"
        onKeyDown={handleKeyDown}
      />
      <Stakeholders control={control} />
    </Stack>
  );
};

export default ProjectForm;
